<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 69.58 69.58"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><circle cx="34.79" cy="34.79" r="34.79" transform="translate(-6.71 61.22) rotate(-77.49)"/><path class="cls-1" d="M40.34 34.79 51 24.14a3.92 3.92.0 0 0-5.54-5.55L34.79 29.24 24.14 18.59a3.92 3.92.0 0 0-5.55 5.55L29.24 34.79 18.59 45.45A3.92 3.92.0 0 0 24.14 51L34.79 40.34 45.45 51A3.92 3.92.0 0 0 51 45.45z"/></g></g></svg>
</template>

<script>
export default {
  name: 'removeMachine',
}
</script>

<style scoped lang="scss">
  .cls-1 {fill:#fff;}
</style>