<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.73 23.19"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M24.51 9.05a.79.79.0 0 0 0-1.09l-3.09-3.1a.78.78.0 0 0-1.1.0L18.94 6.25l4.18 4.18 1.39-1.38zM17.84 7.34 22 11.53l-9 9L8.89 16.3l9-9zm-10 10.08a1 1 0 0 0-.07.18l-1.5 4.5a.83.83.0 0 0-.06.32.77.77.0 0 0 .77.77.82.82.0 0 0 .31-.07l4.57-1.52.11-.05L7.82 17.42zm-.28-2 2.32-2.32H2.32V2.32H13.14V9.86l2.32-2.32v-6A1.56 1.56.0 0 0 13.91.0H1.55A1.55 1.55.0 0 0 0 1.55V13.91a1.56 1.56.0 0 0 1.55 1.55z"/></g></g></svg>
</template>

<script>
export default {
  name: 'machineInfoManage',
}
</script>

<style lang="scss" scoped>
  .cls-1{
    fill: #8198be;
    fill-rule: evenodd;
  }
</style>