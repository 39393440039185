<template>
  <svg id="레이어_1" data-name="레이어 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 10.01"><defs></defs><path class="cls-1" d="M.5.5l10 9.01L20.5.5"/></svg>
</template>

<script>
export default {
  name: 'arrowDown',
}
</script>

<style scoped>
  .cls-1{
    fill:none;
    stroke:#3f70ca;
    stroke-linecap:round;
    stroke-linejoin:round;
  }
</style>