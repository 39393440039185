<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.2 7.33002L14.63 2.93002C14.7815 2.78029 14.9017 2.60197 14.9838 2.40543C15.0658 2.20888 15.1082 1.998 15.1082 1.785C15.1082 1.57201 15.0658 1.36116 14.9838 1.16461C14.9017 0.968068 14.7815 0.789751 14.63 0.640015C14.3233 0.338319 13.9102 0.16925 13.48 0.16925C13.0497 0.16925 12.6367 0.338319 12.33 0.640015L7.90002 5.04001L3.46997 0.640015C3.16323 0.338319 2.75019 0.16925 2.31995 0.16925C1.8897 0.16925 1.47679 0.338319 1.17004 0.640015C1.01857 0.789751 0.898239 0.968068 0.816162 1.16461C0.734085 1.36116 0.691895 1.57201 0.691895 1.785C0.691895 1.998 0.734085 2.20888 0.816162 2.40543C0.898239 2.60197 1.01857 2.78029 1.17004 2.93002L5.58997 7.33002L1.17004 11.73C1.01857 11.8797 0.898239 12.0581 0.816162 12.2546C0.734085 12.4512 0.691895 12.662 0.691895 12.875C0.691895 13.088 0.734085 13.2989 0.816162 13.4954C0.898239 13.692 1.01857 13.8703 1.17004 14.02C1.4763 14.3224 1.8896 14.4913 2.31995 14.49C2.75008 14.4902 3.16311 14.3214 3.46997 14.02L7.90002 9.62003L12.33 14.02C12.6362 14.3224 13.0496 14.4913 13.48 14.49C13.9101 14.4902 14.3231 14.3214 14.63 14.02C14.7815 13.8703 14.9017 13.692 14.9838 13.4954C15.0658 13.2989 15.1082 13.088 15.1082 12.875C15.1082 12.662 15.0658 12.4512 14.9838 12.2546C14.9017 12.0581 14.7815 11.8797 14.63 11.73L10.2 7.33002Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: "miniClose",
}
</script>

<style lang="scss" scoped>

</style>