<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.59 26.59"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M13.3,26.07a12.77,12.77,0,0,1-9-21.8A12.77,12.77,0,0,1,22.33,22.33,12.71,12.71,0,0,1,13.3,26.07Z"/><path d="M13.3,1.06A12.24,12.24,0,1,1,4.64,4.64,12.19,12.19,0,0,1,13.3,1.06M13.3,0a13.3,13.3,0,1,0,0,26.59,13.3,13.3,0,0,0,9.4-22.7A13.21,13.21,0,0,0,13.3,0Z"/><path d="M14.68,11.92V6.61a1.38,1.38,0,1,0-2.76,0v5.31H6.61a1.38,1.38,0,0,0,0,2.76h5.31V20a1.38,1.38,0,0,0,2.76,0v-5.3H20a1.38,1.38,0,0,0,0-2.76Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'addMonitor',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#fff;}
</style>