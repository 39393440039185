<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.09 25.09"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M17.06 11.36l-5.7 5.7a5.88 5.88.0 0 1-5.48 8A5.14 5.14.0 0 1 5.11 25a5 5 0 0 1-.71-.14A.75.75.0 0 1 4 24.69a.78.78.0 0 1 0-1.11l1.84-1.85a.78.78.0 0 0 .24-.57.81.81.0 0 0-.24-.57L4.5 19.24A.78.78.0 0 0 3.93 19a.8.8.0 0 0-.57.24L1.51 21.09h0a.81.81.0 0 1-.35.2.79.79.0 0 1-1-.55C.14 20.5.09 20.25.05 20a6.78 6.78.0 0 1 0-.77 5.88 5.88.0 0 1 5.88-5.88A5.68 5.68.0 0 1 8 13.74L13.73 8a5.88 5.88.0 0 1 5.48-8A6.78 6.78.0 0 1 20 .05a6.87 6.87.0 0 1 .71.14.82.82.0 0 1 .4.21.8.8.0 0 1 0 1.11L19.24 3.36a.8.8.0 0 0-.24.57.78.78.0 0 0 .24.57l1.35 1.36a.85.85.0 0 0 .57.23.81.81.0 0 0 .57-.23L23.58 4h0a.69.69.0 0 1 .35-.2.77.77.0 0 1 1 .55 4.53 4.53.0 0 1 .15.75 6.93 6.93.0 0 1 .05.77 5.9 5.9.0 0 1-8 5.48z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'productManage',
  data() {
    return {
      width: 24,
      height: 24,
    };
  }
});
</script>

<style scoped>
  .cls-1{
    fill:#839cc5;
  }
</style>