<template>
  <div 
    class="dashboard-machine" 
    :class="[
      data.execution, 
      data.execution != 'POWER_OFF' && data.partCountChange || data.changeTablePause ? 'COMPLETE' : '',
    ]"
  >
    <div class="machine__top">
      <p class="machine__no">{{ data.machineNo }}</p>
      <p class="machine__mid">{{ data.mid }}</p>
    </div>
    <div class="machine__content">
      <div class="cont__info">
        <div class="machine__status">                                                           
          <p
            v-if="data.execution == 'POWER_OFF'" 
            class="status__value machine__power-off"
          >전원꺼짐</p>
          <p 
            v-else-if="data.execution == 'TRIGGERED'" 
            class="status__value machine__triggered"
          >비상정지</p>
          <p 
            v-else-if="data.partCountChange || data.changeTablePause" 
            class="status__value machine__complete"
          >가공완료</p>
          <p 
            v-else-if="data.execution == 'STOPPED'" 
            class="status__value machine__stopped"
          >일시정지</p>
          <p 
            v-else-if="data.execution == 'READY'" 
            class="status__value machine__ready"
          >READY</p>
          <p 
            v-else-if="data.execution == 'ACTIVE'" 
            class="status__value machine__remain-time"
          >
            {{ calcFinish( data.estLotFinishTime, 'time')}}
          </p>
        </div>
        <p
          v-show="data.execution != 'POWER_OFF'" 
          class="machine__program"
        >{{ programFormatter(data.process) }}</p>
      </div>
      <div class="cont__count">
        <div class="count">
          <v-progress-linear
            :value="data.execution != 'POWER_OFF' && data.plan_count != 0 ? ( data.part_count / data.plan_count ) * 100 : 0"
            height="5"
            class="count-process-bar"
            color="#6EBD33"
          ></v-progress-linear>
        </div>
        <div 
          class="count__value"
          :class="{ 
            'off-text': data.execution == 'POWER_OFF', 
          }"
        >
          <p class="value part-count">{{ data.part_count }}</p>
          <p class="value plan-count">{{ data.plan_count }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dachboardMachine',
  props: {
    data: Object,
  },
  methods: {
    calcFinish(seconds, mode){
      seconds = seconds / 1000;
      const hour = parseInt(seconds/3600);
      const min = parseInt((seconds%3600)/60);
      // var sec = parseInt(seconds%60);
      // return hour+"시간 "+min+"분 " + sec + '초' ;

      if ( mode == 'time' ) return hour+"시간 "+min+"분";
      else if ( mode == 'under30' ) return hour < 1 && min <= 30;
    },
    programFormatter(value) {
      if( value == null ) return;
      if( value.length != 5 ) {
        value = value.substr(6, value.length);
        if(value.indexOf('(') == -1){
          value = value.replace(')','');
        }
      }
      return value;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/global.scss';

  * { font-family: $scd !important; }

  .ACTIVE { background-color: $traffic_green !important; }
  .COMPLETE,
  .STOPPED { background-color: $traffic_yellow !important; }
  .TRIGGERED { background-color: $traffic_red !important; }
  .POWER_OFF { background-color: $gray !important; }
  .off-text { 

    p, div {
      color: white !important; 
    }
  }

  .dashboard-machine {
    @extend .flex_column;

    min-height: vconv(1920, 166);
    justify-content: stretch;
    position: relative;
    background-color: $traffic_green;

    &.POWER_OFF {

      .cont__info {
        padding: 0 !important;
        position: relative;

        .machine__status {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);

          .status__value {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    div { width: 100%; }

    .machine__top {
      @extend .flex_row;

      justify-content: space-between;
      padding: map-get($vw, 3) map-get($vw, 8) 0;
      flex: 0 0 auto;

      p {
        font-size: map-get($vw, 20);
        line-height: 1;
      }
      .machine__no {
        font-weight: 600;
        color: white;
      }
      .machine__mid {
        font-weight: 500 !important;
        text-align: center;
        padding: map-get($vw, 12) 0;
        color: white;
      }
    }
    .machine__content {
      @extend .flex_column;

      flex: 1 1 0;
      background-color: white;

      .cont__info {
        flex: 1 1 auto;
        padding: map-get($vw, 16) 0 map-get($vw, 8);
        text-align: center;
        position: relative;

        .machine__status {

          .status__value {
            font-size: map-get($vw, 24);
            font-weight: 600;
            margin-bottom: map-get($vw, 2);
          }
        }
        .machine__program {
          height: map-get($vw, 32);
          padding: 0 map-get($vw, 8);
          font-size: map-get($vw, 14);
          line-height: map-get($vw, 16);
        }
      }
      .cont__count {
        flex: 0 0 auto;
        padding: 0 map-get($vw, 8) map-get($vw, 2);

        ::v-deep .count-process-bar {

          .v-progress-linear__background {
            height: 2.5px !important;
            margin-top: 1.2px;
            background-color: $main_gray_pale !important;
          }
        }
        .count__value {
          @extend .flex_row;

          justify-content: space-between;
          margin-top: map-get($vw, 3);

          .value {
            font-size: map-get($vw, 16);
            color: $main_gray_pale;
            font-weight: 500;
          }
        }
      }
    }
  }
</style>