<template>
  <v-app class="app"
    :class="{
      'header-active': $route.meta.showHeader,
      'menu-active': $route.meta.menuActive && !$route.meta.showHeader,
    }"
  >
    <dashboard 
      v-if="$route.meta.menuActive" 
      :showHeader="$route.meta.showHeader"
    ></dashboard>
    <v-main class="home_main">
      <div class="content">
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import dashboard from "./components/sideMenu";
export default {
  name: 'App',
  components: {
    dashboard,
  },
  created(){
    document.title="Digital Twin";
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = "./favicon.png";
  },
  data() {
    return {};
  },
  mounted() {
    const is_mac = navigator.userAgent.indexOf('Mac');
    if( is_mac != -1 ) document.body.classList.add('mac');
  }
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/global.scss';

  .app {
    display: block;
    min-height: 100vh;

    &.header-active {

      ::v-deep .v-application--wrap {
        padding-top: $header_height;
        padding-left: 0;
      }
    }
    &.menu-active {

      ::v-deep .v-application--wrap {
        padding-left: $menu_width;
        padding-top: 0;;
      }
    }
  }
  ::v-deep .v-application--wrap {
    height: 100%;
    background-color: $pale;
  }
  .home_main {
    height: 100%;

    .content {
      height: 100%;
    }
  }
</style>
