<template>
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.46736 16.2957C5.6412 16.2957 2.53948 13.194 2.53948 9.36786C2.54071 9.266 2.52172 9.1649 2.48359 9.07043C2.44546 8.97597 2.38896 8.89 2.31737 8.81753C2.24577 8.74506 2.1605 8.68753 2.0665 8.64826C1.9725 8.60899 1.87164 8.58876 1.76977 8.58876C1.6679 8.58876 1.56704 8.60899 1.47304 8.64826C1.37904 8.68753 1.29377 8.74506 1.22217 8.81753C1.15058 8.89 1.09408 8.97597 1.05595 9.07043C1.01782 9.1649 0.998826 9.266 1.00006 9.36786C0.982269 14.0622 4.7734 17.8823 9.46781 17.8999C14.1622 17.9176 17.9823 14.1266 17.9999 9.43224C18.0176 4.73786 14.2266 0.917848 9.53219 0.900055C8.36694 0.895633 7.21322 1.13084 6.1427 1.59108C5.07219 2.05131 4.10773 2.72673 3.30924 3.5754V1.67072C3.30924 1.46657 3.22814 1.27078 3.08378 1.12642C2.93943 0.982061 2.74363 0.900962 2.53948 0.900962C2.33532 0.900962 2.13953 0.982061 1.99517 1.12642C1.85081 1.27078 1.76971 1.46657 1.76971 1.67072V5.51941C1.76971 5.72356 1.85081 5.91935 1.99517 6.06371C2.13953 6.20807 2.33532 6.28917 2.53948 6.28917H6.3883C6.49017 6.2904 6.59126 6.27141 6.68573 6.23328C6.7802 6.19515 6.86616 6.13865 6.93863 6.06706C7.01111 5.99546 7.06864 5.91019 7.10791 5.81619C7.14718 5.72219 7.16741 5.62133 7.16741 5.51946C7.16741 5.41759 7.14718 5.31673 7.10791 5.22273C7.06864 5.12873 7.01111 5.04346 6.93863 4.97187C6.86616 4.90027 6.7802 4.84377 6.68573 4.80565C6.59126 4.76752 6.49017 4.74852 6.3883 4.74976H4.31429C6.86498 1.89796 11.2447 1.65383 14.0965 4.20463C16.9483 6.75542 17.1924 11.135 14.6416 13.9868C13.9906 14.7145 13.193 15.2965 12.3013 15.6944C11.4096 16.0923 10.4438 16.2972 9.46736 16.2957Z" fill="white" stroke="white"/>
</svg>

</template>

<script>
export default {
  name: "miniRefresh",
}
</script>

<style scoped lang="scss">

</style>