<template>
  <div class="monitor">

    <!-- 상단 시계 -->
    <div class="top_clock" @click="clickRefresh">
      <span class="time" v-text="dataformat(timer)"></span>

      <!-- 로그아웃 -->
     
    </div>

    <div ref="monitorList" class="monitor_list" :class="'monitor' + processList.length">
      <!-- 모니터 하나 -->
      <div 
        v-for="(machine, index) in processList" :key="index" 
        class="monitor_item" 
        :class="[
          machine.execution,
        ]"
      >
        <div class="inner">
          <!-- 상태에 따른 blind, dim -->
          <div class="code_layout POWER_OFF"
            v-if="machine.execution == 'POWER_OFF'"
          >
            <p class="code code_off">
              <span class="text">전원꺼짐</span>
            </p>
          </div>
          <div 
            class="code_layout code_return"  
            v-else-if="machine.msg != null"
          >
            <div class="code return">
              <div class="text">
                <v-icon class="alert_icon">$alertTri</v-icon>
                <p class="message">
                  {{machine.msg}}
                </p>
              </div> 
            </div>
          </div>
          <template v-else-if="mid_status[machine.mkey] != undefined">
            <div class="code_layout" 
              v-if="mid_status[machine.mkey].code != 0"
            >
              <p class="code status" 
                @click="clickMid(machine.mkey)" 
              >
                <span class="text">
                  {{ mid_status[machine.mkey].name }}
                </span>
              </p>
            </div>
            <!-- <div class="code_layout AUTOMATIC"
              v-else-if="machine.mode != 'AUTOMATIC'"
            >
              <p class="code code_auto">
                <span class="text">수정중</span>
              </p>
            </div> -->
          </template>

          <div class="content">

            <div class="top">

              <div class="left" 
                :class="getCircleColor(machine)"
              >
                <div class="bg_circle"></div>
                <div class="portrait_mid" @click="clickMid(machine.mkey)">{{ machine.mid }}</div>
                <p class="machine_number">{{ machine.machineNo }}</p>
              </div>

              <div class="right">
                <div class="quantity">
                  <!-- 수량 -->
                  <p class="part_count off text" v-if="machine.plan_count == 0 && machine.execution != 'POWER_OFF'">
                    {{ machine.part_count }}
                  </p>
                  <p class="plan_count on text" v-else-if="machine.execution != 'POWER_OFF'">
                    {{ machine.part_count }}
                    <span> / </span>
                    {{ machine.plan_count }}
                  </p>
                </div>

                <div class="top_for_landscape">
                  <p class="name">{{ machine.mid }}</p>
                  <p class="process" v-if="machine.execution != 'POWER_OFF'">
                    {{ rename(machine.process) }}
                  </p>
                </div>

                <!-- 타이머 -->
                <div class="timer">
                  <!-- 전원꺼짐 -->
                  <p class="item power_off" v-if="machine.execution == 'POWER_OFF'">
                  </p>
                  <p class="item emergency_stop" v-else-if="machine.execution == 'TRIGGERED'">
                    비상정지
                  </p>
                  <p class="item processing_complete" v-else-if="machine.partCountChange || machine.changeTablePause">
                    가공완료
                  </p>
                  <p class="item pause color_yellow" v-else-if="machine.pause">
                    일시정지
                  </p>
                  <div class="remain_time item"
                    v-else-if="machine.execution == 'ACTIVE' || machine.partCountChange == false"
                  >
                    <p class="lot_end_time" >
                      {{ machine.lot_endtime }}
                    </p>
                    <p class="text">
                      {{ printHour(machine.remainTime) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom">
              <v-progress-linear 
                class="progress_bar"
                v-if="machine.execution != 'POWER_OFF'"
                :value="machine.part_count / machine.plan_count * 100"
                :color="proColor(machine)"
              >

                <div class="landscape"> <!-- 왼쪽 landscape -->
                  <p 
                    class="part_count off text" 
                    v-if="machine.plan_count == 0 && machine.execution != 'POWER_OFF'"
                  >
                    {{ machine.part_count }}
                  </p>
                  <p 
                    class="plan_count on text" 
                    v-else-if="machine.execution != 'POWER_OFF'"
                  >
                    {{ machine.part_count }}/{{ machine.plan_count }}  
                  </p>
                </div>
                <div class="portrait"> <!-- 오른쪽 portrait -->
                  <p class="process" v-if="machine.execution != 'POWER_OFF'">{{ rename(machine.process) }}</p>
                </div>

                <p 
                  class="finish_time text" 
                  v-if="checkPortraitFinishTime(machine)"
                >
                  {{ calcFinish( machine.estLotFinishTime, true) }}
                </p>

              </v-progress-linear>
            </div>
          </div>
        </div>
        
        <!-- 세팅중 정비중 모달 -->
        <v-dialog 
          v-model="dialog_mid"
          content-class="dialog_mid" 
          :retain-focus="false"
          persistent
        >
          <v-card>
            <div class="content">
              <v-radio-group 
                v-model="code"
                @change="getStatus()"
                hide-details 
              >
              <!-- 가공중 -->
              <v-radio class="default" :value="0" label="가공중"></v-radio>
                <v-radio v-for="item in sid" :key='item.id'
                :value="item.id"
                :label="item.name"
                :disabled="test(item.id)"
                ></v-radio>
              </v-radio-group>
            </div>
            <v-card-actions class="actions">
              <v-spacer></v-spacer>
              <v-btn class="btn cancle" @click="midCancle" text >취소</v-btn>
              <v-btn class="btn complete" @click="midComplete(machine.lot)" text>확인</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </div>
    </div>

    <div class="notice_wrap">
      <div 
        ref="noticeWrap" 
        id="notice_wrap"
        class="notice"
        :class="{
          'flow-active' : notice.flow,
        }"
      >
          <div 
            ref="noticeFlow" 
            id='notice_txt' 
            class="notice_inner"
            :style="{ animationDuration: notice.duration + 's'}"
          >
            <span 
              class="value one" 
              :style="{ 'padding-left': notice.padding + 'px' 
            }">{{ notice.value }}</span>
          </div>
      </div>
      <v-btn class="modify_btn" fab outlined small @click="clickNotice">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>

      <v-dialog v-model="notice.open">
        <v-card class="dialog_notice textarea_wrap">
          <v-textarea
            name="notice_textarea"      
            v-model="notice.value"
            value="공지사항 내용을 입력해 주세요"
            hint="공지사항은 연필 아이콘 옆에 표시됩니다."
            class="notice_textarea"
            background-color="white"
            persistent
          >
          </v-textarea>
          <v-card-actions content-class="actions"> 
            <v-spacer></v-spacer>
            <v-btn class="notice_btn cancle_btn" text right @click="noticeCancle">취소</v-btn>
            <v-btn class="notice_btn complete_btn" text right @click="noticeComplete">완료</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>
<script>
import moment from 'moment';
export default {
	name: 'monitor',
	components:{
	},
	data: () => ({
    code:1,
    mkey:Number,
    processList:[],
    filter:[],
    timer:null,
    interval: {},
    setid:Number,
    count_pgTime:0,
    orientation: String,
    notice: {
      open: false,
      flow: false,
      value: '',
      save: '',
      padding: 0,
      insert: true,
      duration: 10,
    },
    id:Number,
    mon:Number,
    ent:Number,
    monitor:String,
    dialog_mid: false,
    mid_status: {},
    sid:[],
    colorchip: {
      green: '#5BB569',
      yellow: '#ffc56d',
      orange: '#ff9c39',
      orange_base: '#ffcfa7',
      red: '#ed4646',
      navy: '#3c3f52',
      navy_base: '#a9abb4',
      gray: '#8c8c8c',
      traffic_red: '#F56713',
      traffic_yellow: '#F5B117',
      traffic_green: '#6BB236',
      progress_gray: '#B2B2B2',
    },
  }),
  computed: {
  },
  head: {
    meta: [
      {
        'http-equiv': 'Pragma',
        content: 'no-cache'
      },
      {
        'http-equiv': 'Expires',
        content: '0'
      },
      {
        'http-equiv': 'Cache-Control',
        content: 'no-cache'
      },
      {
        'http-equiv': 'refresh',
        content: 600,
        url: "/",
      }
    ]
  },
  mounted() {
    this.mon=this.$route.params.mon_id;
    this.ent=this.$store.state.enterprise_id;
    this.cntPgtime();
    this.monitor=this.$route.params.mon;
    this.getsId();
    this.loadMonitorList().then(result =>{
      this.filter = result.data.data.M;
      this.interval = setInterval(() => {
        this.timer = new Date();
        this.$cnc.setCurrentTime(this.timer,this);
      }, 1000);
      this.$store.state.overlay=false;
       this.$cnc.start(this.$store.state.enterprise);
      this.$connect( this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&monitor='+this.monitor+'&view=noti'); 
      this.$cnc.setFilter(this.filter);
      this.$cnc.setCncList(this.processList);
      this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
     
    })
    // 공지사항 관련
    this.orientationChange();
    window.addEventListener('orientationchange', () => {
      this.orientationChange();
    });
    window.addEventListener('resize', this.animationFrame(this.checkNoticeFlow));
    this.$cnc.notiChangeValue((data)=>{
      if(this.monitor == data.monitor){
        this.notice.value=data.noti;
      } 
    });
    this.$cnc.midStatusChange(async (value)=>{
        this.mid_status[value] = await this.getStatus(value);
    });
	},
	destroyed:function(){
  	this.$disconnect();
		this.$cnc.stop();
    clearInterval(this.interval);
	},
	methods : {
    dataformat:function(format){
      if(format === null) return '';
       var year = format.getFullYear();
      var month = format.getMonth() + 1;
      if(month<10) month = '0' + month;
      var date = format.getDate();
      if(date<10) date = '0' + date;
      var hour = format.getHours();
      if(hour<10) hour = '0' + hour;
      var min = format.getMinutes();
      if(min<10) min = '0' + min;
      var sec = format.getSeconds();
      if(sec < 10) sec = '0' + sec;
      return year + "-" + month + "-" + date + " " + hour + ":" + min + ":" + sec;
    },
    rename:function(value){
      if(value){ 
        var found = value.match(/\((.*?)\)/);
        if (found) {
          value = found[1];
        }
        return value;
      }
    },
    printHour: function(seconds){
      if(seconds<0){
        return "00:00"
      }
      if(Number.isNaN(seconds)){
        return "00:00";
      }
      seconds = seconds / 1000;
      var hour = parseInt(seconds/3600);
      var min = parseInt((seconds%3600)/60);
      var sec = parseInt(seconds%60);
      if(hour > 0){
        return  ( hour < 10 ? '0'+hour : hour) +":"+ ( min < 10 ? '0'+min : min ) +":" + (sec < 10 ? '0'+sec : sec )
      }else{
        return   ( min < 10 ? '0'+min : min ) +":" + (sec < 10 ? '0'+sec : sec )
      }
    },
    calcFinish(seconds, hourActive = false){
      seconds = seconds / 1000;
      var hour = parseInt(seconds/3600);
      var min = parseInt((seconds%3600)/60);
      // var sec = seconds % 60;

      if( hourActive ) { 
        let value =  hour == 0 ? min + '분' : hour + "시간 " + min + "분" 
        return value;
      }
      return hour + "시간 " + min + "분" ;
    },
    loadMonitorList:function(){
      let monitor = this.$http.APIS.post('/api/cloud/monitorList',{monitor:this.monitor});
      // get setting value(code)
      monitor.then(result =>{
        let data = result.data.data.M;
        let status = {}
        data.forEach(async (item) => {
          this.mid_status[item] = await this.getStatus(item);
        });
      });
      return monitor;
    },
    cntPgtime:function(){
    	this.$http.APIS.get('/api/process/getCountPgtime').then(result =>{
    		result.data.forEach(element => {
    			this.count_pgTime = element.count_pgTime;
    		});
    		this.count_pgTime = this.count_pgTime*60000;
    	});
    },
    test(val){
      if(this.code == 0){
        return false;
      }else{
        if(val==this.code || val == 0){
          return false;
        }else{
          return true;
        }
      }      
      
    },
    getCircleColor(machine) {
      let value = 'traffic_';
      let type = String;
      if( machine.execution == 'TRIGGERED') {
        type = 'red';
      } else if ( machine.pause ) {
        type = 'yellow';
      } else if ( machine.partCountChange || machine.changeTablePause ) {
        type = 'done';
      } else if ( machine.execution == 'POWER_OFF' ) {
        type = "off";
      }
      return value + type;
    },
    proColor: function (machine) { 
    	// let count = machine.plan_count - machine.part_count;
      // let getTime = this.calcFinish(machine.estLotFinishTime).split('시간');
      // let hour = getTime[0];
      // let min = getTime[1].replace('분', '');

      return this.colorchip.progress_gray
    },
    clickRefresh(event) {
      event.stopPropagation();
      let topClock = event.currentTarget.classList.contains('top_clock');
      let logout = event.target.closest('.logout_btn') != null;
      if( topClock && !logout ) {
        this.$router.go(0);
      }
    },
    orientationChange() {
      const type = window.screen.orientation.type;
      this.orientation = type;
    },
    clickNotice() {
      let notice = this.notice
      notice.save = this.notice.value;
      notice.open = true;
      notice.padding = 0;
      notice.flow = false;
    },
    noticeCancle() {
      if( this.notice.save !== this.notice.value ) {
        this.notice.value = this.notice.save;
      }
      this.notice.open = false;
      this.checkNoticeFlow();
    },
    async noticeComplete() {
      await this.$http.DT.post('/mon/',{noti:this.notice.value,monitor_id:this.mon});

      this.$http.EDGE.post('/api/edge/broadcast',
        {enterprise:'YHS',data:{type:'notice',noti:this.notice.value,monitor:this.monitor}}).then(() =>{                 
      });

      this.checkNoticeFlow();
      this.notice.save = this.notice.value;
      this.notice.open = false;
    },
    checkNoticePadding() {
      this.notice.padding = document.getElementById('notice_wrap').offsetWidth;
    },
    async checkNoticeFlow() {
      let data = await this.notice.value;
      let wrapWidth = document.getElementById('notice_wrap').offsetWidth;
      let innerWidth = document.getElementById('notice_txt').offsetWidth;
      if( innerWidth > wrapWidth ) {
        this.checkNoticePadding();
        this.notice.flow = true; 
        
        let duration = parseInt(innerWidth / wrapWidth) * 6;
        this.notice.duration = duration < 15 ? 15 : duration ;
      } else {
        this.notice.flow = false;
        this.notice.padding = 0;
      }
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if(rAftimeout) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() {
          cb();
        });
      }
    },
    getsId(){
      this.$http.APIS.get('/api/noti/').then(r=>{
        this.sid=r.data;
      })
    },
    async getStatus(value){
      let result = await this.$http.APIS.get('/api/noti/set/' + value);
      return await result.data.length!=0 ? result.data[0] : {code:0};
    },
    clickMid(mkey) {
      this.mkey = mkey;
      this.code = this.mid_status[this.mkey].code;
      this.id = this.mid_status[this.mkey].code == 0 ? 0 : this.mid_status[this.mkey].id;
      this.dialog_mid = true;
    },
    midCancle() {
      this.dialog_mid = false;
    },
    async midComplete(lot) {
      if(this.code == 0 && this.id){
        await this.$http.APIS.patch('/api/noti/',{id:this.id});
        this.code = 0;
      }else if(this.code != 0 && this.mid_status[this.mkey].code != 0){
        return alert('가공중 이외의 상태로 변경할 수 없습니다.');
      }
      else{
        await this.$http.APIS.post('/api/noti/insertSet',{mid:this.mkey,code:this.code,lot:lot});
      }

      await this.$http.EDGE.post('/api/edge/broadcast',
        {enterprise:'YHS',data:{type:'midStatus',mkey:this.mkey}}).then(() =>{                 
      });

      this.mid_status[this.mkey] = await this.getStatus(this.mkey);
      this.dialog_mid = false;
    },
    checkPortraitFinishTime(machine) {
      if( machine.execution == 'POWER_OFF' || machine.plan_count <= machine.part_count) {
        return false;
      }
      if( machine.plan_count != 0 ) {
        return true;
      }
    },
  },
  watch: {
    mon:function(){
      this.$http.DT.get('/mon/'+this.mon).then(result =>{
        if(result.data.length!=0){
          this.id=result.data[0].id;
          this.notice.value = result.data[0].noti;
          this.checkNoticeFlow();
        }
      })
    },
	
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/css/global.scss";

  * { font-family: $scd; }

  .monitor {
    @extend .flex_column;

    width: 100%;
    height: 100%;
    justify-content: stretch;
    padding: 0 !important;
    font-family: $scd;
    background: #F4F4F4;

    @include relative-portrait {
      max-height: 100vh;
    }

    // 상단 시계
    .top_clock {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      padding: 1vmax 0 0;
      position: relative;
      cursor: pointer;
      @include relative-landscape {
        padding: 1vw 0 0.4vw;
        background: #333333;
      }
      @include relative-portrait {
        padding: 0.3vh;
        border-bottom: 1px solid #333333;
      }
      .time {
        font-size: 2.5vh;
        font-weight: 500;
        color: #333333;
        cursor: default;
        text-align: center;

        @include relative-landscape {
          font-size: 4vw;
          color: white;
        }
        @include relative-portrait {
          font-family: $scd;
          font-size: 3.8vh;
          padding-top: 0.5vh;
        }
      }
      .logout_btn {
        padding: 8px;
        position: absolute;
        top: 50%;
        right: 2vw;
        transform: translateY(-50%); 
        @include relative-portrait {
          padding: 0.6vh;
          margin-right: 0;
        }
        .v-icon {
          font-size: 2vw;
          @include relative-portrait {
            font-size: 2.4vh;
          }
        }
      }
    }
    // 모니터 리스트
    .monitor_list {
      @extend .flex_row;
      width: 100%;
      flex: 1 1 auto;
      flex-flow: row wrap;
      padding: map-get($vw, 8) 0;
      overflow: hidden;
      
      @include relative-landscape {
        padding: 0 map-get($vw, 6) map-get($vw, 6);
      }
      @include relative-portrait {
        flex-flow: column nowrap;
      }

      &.monitor4 {
        .monitor_item {
          flex: 1 1 25%;
        }

        @include relative-portrait {

          .content {

            .bottom {
              height: 4vh;
            }
          }
          .timer {

            .item {
              font-size: 6.6vh !important;
            }
            .remain_time {
              margin-top: 0 !important;

              .text {
                line-height: 5.6vh;
                padding-top: 1vh;
              }
            }
          }
          ::v-deep .v-progress-linear__content {

            .process {
              font-size: 3vh !important;
            }
            .finish_time {
              font-size: 3vh !important;
            }
          }
          & + .notice_wrap {
            padding: 0.6vh;
          }
        }
      }
      &.monitor3 {
        .monitor_item {
          
          @include relative-portrait {
            flex: 1 1 33.333%;
          }
        }
        .left {
          
          @include relative-landscape {
            flex: 0 0 14vh;
          }
          @include relative-portrait {
            padding: 1.6vh;
            padding-right: 1.8vh;
          }
  
          .machine_number { 
            
            @include relative-portrait {
              font-size: 9vh;
              line-height: 6.5vh;
              padding-top: 1.5vh;
            }
          }
        }
        .right {
          
          @include relative-portrait {
            padding: 1.6vh;
            padding-left: 0;
          }
          .plan_count {
            @include relative-portrait {
              padding-right: 0.4vw;
            }
          }
        }
  
        .bottom {
  
          @include relative-landscape {
            font-size: 2.5vw;
          }
        }
      }
      .monitor_item {
        width: 100%;
        flex: 1 1 25%;
        position: relative;
        padding: map-get($vw, 8) map-get($vw, 16);
        font-weight: 500;
        overflow: hidden;
        
        &.POWER_OFF {
          .bottom {
            background: $gray !important;
          }
        }
        @include relative-landscape {
          flex: 1 1 33.333%;
          height: 60%;
          padding: map-get($vw, 6);
        }
        @include relative-portrait {
          
          &:nth-child(n+5) {
            display: none;
          }
        }
        .inner {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          background: white;
          @include relative-landscape {
            border-radius: map-get($vw, 16);
            border: 1px solid rgba(0, 0, 0, 0.2);
          }
        }
      }
      // data 들어가는 곳
      .content {
        @extend .flex_column;
        height: 100%;
        justify-content: stretch;
        > div {
          width: 100%;
        }
      }
      // top
      .top {
        @extend .flex_row;
        justify-content: stretch;
        flex: 1 1 auto;
        position: relative;
        @include relative-landscape {
          flex-flow: column nowrap;
          align-items: flex-start;
        }
      }
      .left {
        @extend .flex_column;

        justify-content: space-between;
        align-items: flex-start;
        height: 100%;
        font-size: 8vh;
        font-family: $scd;
        flex: 0 0 41%;
        padding: 1vh;
        padding-right: 1.8vh;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include relative-landscape {
          display: none;
          width: 100%;
          flex: 0 0 5.5vw;
          justify-content: flex-start;
          padding: 0 0.8vw;
          font-size: 5.5vw;

          .bg_circle { background: none;}
        }
        .bg_circle {
          width: 50vh;
          height: 50vh;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          z-index: 1;
          overflow: hidden;
          background: $traffic_green;

          @include relative-landscape {
            display: none;
          }
        }
        .portrait_mid {
          display: none;
          @include relative-portrait {
            display: block;
            width: 100%;
            font-size: 3.6vh;
            color: white;
            letter-spacing: -1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: relative;
            z-index: 2;
          }
        }
        .machine_number {
          padding-top: 7vh;
          position: relative;
          z-index: 2;
          color: white;
          @include relative-landscape {
            padding-top: 0.8vw;
          }
          @include relative-portrait {
            padding-top: 0.9vh;
            font-size: 7.2vh;
            line-height: 6vh;
          }
        }
        @include relative-portrait {
          // 신호등 색 설정
          &.traffic_done,
          &.traffic_yellow {
  
            .bg_circle { background: $traffic_yellow  !important;}
          }
  
          &.traffic_red {
  
            .bg_circle { background: $traffic_red; }
          }
          &.traffic_off {
  
            .bg_circle { background: $gray; }
          }
        }
      }
      .right {
        @extend .flex_column;
        height: 100%;
        padding: 1vh;
        flex: 1 1 auto;
        position: relative;
        z-index: 2;
        @include relative-landscape {
          height: auto;
          width: 100%;
          padding: 0;
        }
        @include relative-portrait {
          justify-content: space-between;
          align-items: flex-end;
          padding: 1vh;
        }
      }
        .quantity {
          @extend .cf;
          font-size: 3.6vh;
          font-family: $scd;
          @include relative-landscape {
            display: none;
            // font-size: 3vw;
          }
          @include relative-portrait {
            width: 100%;
            text-align: right;
          }
          p,
          .text {
            color: #666666;
          }    
        }
        .plan_count {
          span {
            font-size: 2.8vh;
            position: relative;
            top: -0.3vh;
            // letter-spacing: -8px;
          }
        }
        .top_for_landscape {
          display: none;
          font-family: $scd;
          @include relative-landscape {
            display: block;
            width: 100%;
            flex: 0 0 auto;
            // padding: 1vw;
          }
          .name {
            font-size: 2.4vw;
            font-family: $scd;
            font-weight: 900;
            margin-bottom: 0.6vw;
            padding: 1vw 1vw 0.6vw;
            background-color: #efeeee;
          }
          .process {
            font-size: 2.3vw;
            padding: 0 1vw;
          }
        }
        .timer {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 8vh;
          font-family: $scd;
          font-weight: 900 !important;
          flex: 1 1 auto;
          @include relative-landscape {
            width: 100%;
            flex: 1 1 auto;
            font-size: 6.5vw;
            margin-bottom: 0;
          }
          @include relative-portrait {
            display: block;
            flex: 0 0 auto;
            margin-top: auto;
            justify-self: flex-end ;
          }
          .item {
            line-height: 1;
            letter-spacing: -1px;

            @include relative-landscape {
              font-size: 5.6vw;
              padding-top: map-get($vw, 8);
            }
            @include relative-portrait {
              text-align: right;
              font-size: 7.2vh;
              
              &:not(.remain_time) {
                white-space: nowrap;
                font-size: 7vh;
              }
            }
          }
          .remain_time {
            font-size: 9vh;
            position: relative;
            margin-top: 2.4vh;

            @include relative-landscape {
              font-size: 7vw;
            }
            @include relative-portrait {
              font-size: 8vh;
            }

            .lot_end_time {
              font-size: 2vh !important;
              padding-top: 0;
              position: absolute;
              top: 0;
              right: 0;
              font-family: $scd;
              transform: translate(0, -100%);
            }
          }                               
        }
      // bottom
      .bottom {
        @extend .flex_row;
        justify-content: space-between;
        flex: 0 0 auto;
        height: 4.5vh;
        color: white;
        font-size: 3.2vh;
        position: relative;
        z-index: 2;
        // background: #666666;
        background: white;
        @include relative-landscape {
          height: 4vw;
          font-size: 2vw;
          padding: 0;
          background: white;
        }
        @include relative-portrait {
          padding-top: 0;
        }
        p { font-family: $scd; }
        .progress_bar {
          height: 100% !important;
          padding: 0 1.5vh;
          @include relative-landscape {
          }
          ::v-deep .v-progress-linear__content {
            display: flex !important;
            justify-content: space-between;
            padding: 0 1.2vw;
            align-items: center;
            font-family: $scd;
            font-weight: 900;

            .text {
              color: black;
              padding-top: 0.4vw;
            }
          }
          ::v-deep .v-progress-linear__background {
            opacity: 0.45 !important;
          }
        }
          .landscape {
            display: none;
            @include relative-landscape { display: block; }
          }
          .portrait {
            
            display: none;
            width: 55%;
            overflow: hidden;
            @include relative-portrait { display: block; }
          }
          .process {
            @extend .ellipsis;
            width: 100%;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500 !important;
            padding-top: 0.15vw;
            @include relative-landscape {
              display: none;
            }
            @include relative-portrait {
              line-height: 2.5vh;
              padding-top: 0.5vh;
            }
          }
          .finish_time {
            line-height: 2.5vh;
            font-weight: 500 !important;
            padding-top: 0.5vh !important;
          }
      }
    }
    // 공지사항
    .notice_wrap {
      display: none;
      flex: 0 0 auto;
      ::v-deep .textarea_wrap {
        background: white;
      }
      @include relative-portrait {
        display: flex;
        flex-flow: row nowrap;
        justify-content: stretch;
        align-items: center;
        width: 100%;
        padding: 1vh;
        background: white;

        .notice {
          flex: 1 1 auto;
          overflow: hidden;
          font-size: 3.6vh;
          padding-top: 0.6vh;
          font-family: $scd;

          &.flow-active {
            
            .notice_inner {
              @keyframes noticeFlow {
                0% {
                  transform: translate(0%, 0);
                }
                100% {
                  transform: translate(-100%, 0);
                }
              }
              animation: {
                name: noticeFlow;
                duration: 25s;
                iteration-count: infinite;
                timing-function: linear;
              }
            }
          }

          .notice_inner {
            display: inline-block;
            height: 100%;
            white-space: nowrap;
            position: relative;

            .value {
              font-weight: 400 !important;
            }
          }
        }
        .modify_btn {
          flex: 0 0 auto;
          width: 3vh;
          height: 3vh;
          margin-left: 1vw;
          .v-icon {
            font-size: 1.4vh;
          }
        }
      }
    }
  }
  // 공지사항 dialog
  ::v-deep .dialog_notice {
    padding: 0 8px 8px;
    background: white;
    
    .notice_textarea {
      margin-bottom: 16px;
    }
    textarea{
      padding: 16px;
      border-bottom: 0;
      &:focus {
        outline: 0;
        border: 0;
      }
    }
    .v-text-field__details {
      height: 16px;
      .v-messages__wrapper {
        height: 100% !important;
      }
      .v-messages__message {
        height: 100%;
        line-height: 16px;
      }
    }
    .v-input__slot {
      
      &::before {
        display: none;
        border: 0;
      }
    }
    .notice_btn {
      display: inline-block;
      box-shadow: 0 !important;
      font-family: $scd;
      &.cancle_btn {
        margin-right: 8px;
      }
      &.complete_btn {
        font-weight: bolder;
        color: cornflowerblue !important;
      }
    }
  }
  
  // mid dialog
  ::v-deep .dialog_mid {
    background: white;
    .content {
      padding: 24px 12px;
      .v-input__slot {
        justify-content: center;
      }
      .v-input--radio-group__input {
        // width: fit-content;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
      .v-radio {
        width: 50%;
        padding: 3px 0;
        margin-bottom: 0;
      }
    }
    .actions {
      padding: 0 16px 4px;

      .complete {
        font-weight: bolder !important;;
        color: cornflowerblue !important;
      }
    }
  }
  // code에 따른 설정
  .monitor_item {
    position: relative;

    .code_layout {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);

      .code {
        @extend .flex_row;

        // display: none;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgb(124, 124, 124, 0.9); // #7c7c7c

        &.status {
          display: flex;
        }
      }
      .text {
        font-size: 8vh;
        line-height: 1;
        padding-top: 1vh;
        color: white !important;
        font-family: $scd;
        font-weight: 900;
      }
    }
    .code_return {

      .code {
        @keyframes twinkling {
          0% {
            opacity: 1;
          }
          75% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
        // animation: twinkling .4s 0s infinite linear alternate;
        background: rgba(255, 255, 255, 0.9);
      }

      .text {
        @extend .flex_row; 

        font-family: $scd;
        font-size: 2.4vh;
        font-weight: 400;
        line-height: 3.2vh;
        padding: 0 0 0 0;
        color: black !important;
        position: relative;

        .message {
          display: inline-block;
          max-width: 20ch;
          word-break: break-word;
          line-height: 1.2;
          padding-left: 2vh;
        }

        ::v-deep .v-icon {

          &__component {
            width: 6vh;
            height: auto;
          }
        }
      }
      .alert_icon {
        font-size: 7vh;
        color: $traffic_red;
      }
    }
  }
</style>