<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.08 22.08"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M11 0a11 11 0 1 0 11 11A11 11 0 0 0 11 0zm0 7.25a2.76 2.76.0 0 1 1 5.32v2.27a1 1 0 0 1-2.06.0V12.57a2.76 2.76.0 0 1 1-5.32z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'operationData',
  data() {
    return {
    };
  }
});
</script>

<style scoped>
  .cls-2,
  .cls-1 {
    fill:#839cc5;
    fill-rule:evenodd;
  }
</style>