<template>
  <div id="production_report">

    <!-- 엑셀로 저장 -->
    <div class="filter">

      <!-- date picker -->
      <dayPicker 
        class="header__date-picker"
        :preset="today" 
        :presetIcon="['append', false, false]"
        :title="'날짜 선택'"
        @picked="setDateValue"
      ></dayPicker>
      <!-- 확인 -->
      <v-btn 
        @click="filter"
        class="filter_search_btn" 
        height="32"
        tile outlined
      >확인</v-btn>
      <v-btn 
        class="save_as_excel"
        prepend-icon="mdi-printer" 
        depressed
        height="36"
        @click="excel"
      >엑셀로 저장</v-btn>
    </div>

    <!-- 데이터 리스트 -->
    <v-data-table
      :height="tableHeight"
      ref="productionReport"
      class="product_report__list"
      :items="data"
      :headers="header"
      :items-per-page="-1"
      :disable-pagination="true"
      :footer-props="{
        disablePagination: true,
        disableItemsPerPage: true,
      }"
      fixed-header dense hide-default-footer
      v-if="tableCreate"
    >
      <template slot="no-data">
        <div class="no-data">데이터가 없습니다</div>
      </template>

      <template v-slot:item="props">
        <tr>
          <td 
            class="mid column text-center" 
            :class="rowspan[props.item.mid].quantity"
            v-if="props.index == rowspan[props.item.mid].start"
            :rowspan="rowspan[props.item.mid].quantity"
          >
            {{ props.item.mid }}
          </td>
          <td class="program column">
            <span class="text-center">
              {{ props.item.program }}
            </span>
          </td>
          <td class="setting_time column">
            <span class="text-center">
              {{ props.item.setting_time }}
            </span>
          </td>
          <td class="plan_count column">
            <span class="text-center">
              {{ props.item.count }} / {{ props.item.plan }}({{props.item.plan_count}})
            </span>
          </td>
          <td class="work_time column">
            <span class="text-center">
              {{props.item.start }} ~ {{props.item.end }}
            </span>
          </td>
        </tr>
      </template>

    </v-data-table>


  </div>  
</template>

<script>

import moment from 'moment';
import dayPicker from '../components/dayPicker.vue';
import {json2excel, excel2json} from 'js2excel';

export default {
  name: 'productionReport',
  components: {
    'dayPicker': dayPicker,
  },
  data() {
    return {
      tableHeight: 500,
     
      start: '',
      end: '',
      mid:1,
      header: [
        {
          text: '기계명',
          value: 'mid',
          sortable: false,
          align: 'center',
        },
        {
          text: '품명',
          value: 'program',
          sortable: false,
          align: 'left',
        },
        {
          text: '세팅 시간',
          value: 'setting_time',
          sortable: false,
          align: 'left',
        },
        {
          text: '생산 / 목표',
          value: 'plan_count',
          sortable: false,
          align: 'left',
        },
        {
          text: '작업시간',
          value: 'start_end',
          sortable: false,
          align: 'left',
        },
      ],
      data: [
        {
          mid: 'pumaV400',
          program: '900131-00514',
          setting_time: '8:30 ~ 9:40',
          plan: '100',
          count: '101',
          start: '2021-05-12 08:30',
          end: '2021-05-12 14:30',
        },
      ],
      filter_date: '',
      today: moment().format('YYYY-MM-DD'),
      rowspan: {},
      tableCreate: false,
    };
  },
  methods: {
    resizeHeight() {
      const hei = window.innerHeight;
      const filter_el = document.querySelector('.filter');

      let except = {
        header: 0,
        filter: filter_el != undefined ? parseInt(getComputedStyle(filter_el).height) : 100,
        extra: 5,
      };
      this.tableHeight = hei - except.filter - except.extra;
    },
    animationFrame(cb) {
      let rAftimeout = null;

      return function() {
        if( rAftimeout ) { window.cancelAnimationFrame(rAftimeout); }
        rAftimeout = window.requestAnimationFrame(function() {
          cb();
        });
      }
    },
    setDateValue(value) {
      this.filter_date = value;
    },
    filter() {
      this.$http.DT.get('/report/'+this.filter_date+'/'+this.filter_date).then(r=>{
        for (let i=0;i<r.data.length;i++){
          if(r.data[i].program.indexOf('(')!=-1){
            let g=r.data[i].program;
            r.data[i].program=g.match(/\((.*?)\)/)[1];
          }
          if(r.data[i].setend !=null){
            r.data[i].setting_time= this.dateForm(r.data[i].start) +'~'+ this.dateForm(r.data[i].setend)
            r.data[i].start=r.data[i].setend;
          }
            r.data[i].plan=(r.data[i].diff/r.data[i].cycle).toFixed(0);
            r.data[i].start=this.dateForm(r.data[i].start);
            r.data[i].end=this.dateForm(r.data[i].end);
        }
        this.data=r.data;
        this.getRowspan();
        this.tableCreate = true;
      });
    },
    excel(){
      let data=[];
      for(let i=0; i<this.data.length; i++){
        data.push({기계명:this.data[i].mid,품명:this.data[i].program,세팅시간:this.data[i].setting_time,
        '생산/목표':this.data[i].count +'/'+ this.data[i].plan,
        작업시간:this.data[i].start +'~'+ this.data[i].end,
        });
      }
      json2excel({
        data,
        name:this.filter_date+'report',
      })
    },
    dateForm(val){
      val=val.replace('T',' ').substr(11,8);
      return val;
    },
    getRowspan() {
      let data = this.data;
      let rowspan = {};
      data.forEach(function(item, index) {
        let first = Object.keys(rowspan).indexOf(item.mid);
        if( first < 0 ) {
          rowspan[item.mid] = {
            start: index,
            quantity: 1,
          }
        } else {
          rowspan[item.mid].quantity = rowspan[item.mid].quantity + 1;
        }
      });
      this.rowspan = rowspan;
    },
 
  },
  mounted() {
    this.filter_date=this.today;
    this.resizeHeight();
    window.addEventListener('resize', this.animationFrame(this.resizeHeight));
    this.filter();
  },
  watch:{}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/global.scss';

* { font-family: $scd; }

  #production_report {
    height: 100%;
    background-color: white;
  }

  .filter {
    @extend .flex_row;
    
    justify-content: flex-end;
    padding: 44px 12px 12px;
    position: relative;
    
    .mid_select {
      max-width: 250px;
      margin-right: 32px;
      border:solid blue 2px;
      border-radius: 5px;
    }

    ::v-deep .header__date-picker {
      
      .day-picker__field {
        border: 1px solid $table_black;
      }
    }
    .filter_search_btn {
      margin: 0 40px 0 8px;
      background: white;
      border: 1px solid $table_black !important;
    }

    ::v-deep .v-text-field__details {
      display: none;
    }
    .save_as_excel {
      background-color: #E9E9E9;
    }
  }

  .product_report__list {
    @include dt_table--theme-black;

    .column {
      line-height: 30px;
      padding-top: 2px;

      &:first-child {
        border-right: 1px solid white;
      }
      &.mid {
        background: #f2f2f2 !important;
        border-bottom: 1px solid white !important;
      }
    }
  }

  // mac
  .mac {

    .filter {

      .filter_search_btn {
        padding-top: 3px;
      }
      .save_as_excel {
        padding-top: 3px;
      }
    }
  }
</style>