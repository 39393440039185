<template>
  <div id="sidemenu">
    <div class="header" v-if="showHeader">
      <v-btn class="header__open_menu"
        fab depressed small text dark
        @click="showSidemenu"
      ><v-icon>mdi-menu</v-icon></v-btn>
    </div>

    <div class="dim"
      :class="{
        'active': sidemenu.dim.open,
      }"
      @click="closeSidemenu"
    ></div>

    <div class="sidemenu" 
      :class="{ 
        'active': sidemenu.menu.open || $route.meta.menuActive && !$route.meta.showHeader, 
      }"
    >
      <div class="top">
        <div class="top_logo" @click='goHome'>
          <!-- <router-link :to="'/'" tag="button"> -->
          <img src="../assets/img/yhs_logo.svg" alt="연합시스템" class="yhs_logo">
          <!-- </router-link> -->
        </div>
        <div class="profile">
          <div class="profile__circle">
            <div class="loading_bar">
              <apexchart
                type="radialBar"
                height="140"
                :options="loadingOptions"
                :series="loadingData"
              >
              </apexchart>
            </div>
            <div class="profile_img"></div>
          </div>
          <div class="actions">
            <v-btn class="my_page" depressed rounded small dark>
              <v-icon x-small class="yhs_profile">$yhsProfile</v-icon>
              <span class="text">마이페이지</span>            
            </v-btn>
          </div>
        </div>
      </div>

      <div class="menu">
        <div class="inner">
          <v-list class="menu_list">
            <template v-for="m in menu">
              <!-- sub 없을 때 -->
              <v-list-item 
                v-if="m.children.length == 0" 
                class="menu_item menu-no_depth"
                :value="m.name == path.menu.menu"
                :key="m.name"
                :to="m.path"
                link
              >
                <v-list-item-icon
                  class="icon menu_icon"
                  :class="{ 'menu_icon-bigger': m.icon.bigger }"
                >
                  <v-icon>{{ m.icon.value }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title 
                  class="text menu_text"
                >
                  {{ m.name }}
                </v-list-item-title>
              </v-list-item>
              <!-- sub 있을 때 -->
              <v-list-group 
                v-else 
                class="menu_item menu-depth"
                :value="m.name == path.menu.menu"
                :key="m.name"
                link
              > 
                <template v-slot:activator>
                  <v-list-item-content class="content menu_cont" :to="m.path">
                    <v-list-item-icon 
                      class="icon menu_icon"
                      :key="m.name"
                      :class="{ 
                        'menu_icon-bigger': m.icon.bigger,
                      }"
                    >
                      <v-icon>{{ m.icon.value }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="text menu_text">{{ m.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-for="sub in m.children">
                  <v-list-item 
                    v-if="sub.child.length == 0"
                    class="sub_menu sub-no_depth" 
                    :value="path.menu.sub == sub.name"
                    :key="sub.name"
                    :to="sub.path"
                    link
                  >
                    <v-list-item-title class="text">{{ sub.name }}</v-list-item-title>
                  </v-list-item>

                  <v-list-group 
                    class="sub_menu sub-depth"
                    v-else
                    :value="path.menu.sub == sub.name"
                    :key="sub.name"
                    sub-group
                    link
                  >
                    <template v-slot:activator>
                      <v-list-item-content :to="sub.path">
                        <v-list-item-title class="text">{{ sub.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item 
                      class="below_menu" 
                      v-for="b in sub.child"
                      :key="b.name"
                      :to="b.path"
                      link
                    >
                      <v-list-item-title class="text">{{ b.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                </template>

              </v-list-group>
            </template>
          </v-list>
        </div>
      </div>

      <div class="bottom">
        <div class="login_out">
          <v-btn class="logout" 
            to="/logout"
            small depressed dark 
          >로그아웃</v-btn>
          <!-- <v-btn class="login" 
            to="/login"
            small depressed outlined 
          >로그인</v-btn> -->
        </div>
        <span class="version">UPDATE VERSION 3.0.1.</span>
      </div>
    </div>

    </div>
</template>

<script>
import apexchart from 'vue-apexcharts';

export default {
  name: 'dashboard',
  components: {
    'apexchart': apexchart,
  },
  props: {
    showHeader: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      menu: [
        // {
        //   name: '메뉴보기 전환',
        //   icon: '$changeMenu',
        //   path: '/',
        //   children: [
        //   ]
        // },
        {
          name: '생산 현황',
          icon: {
            value: '$productStatus',
            bigger: false,
          },
          path: '',
          children: [
            {
              name: '현장화면' ,
              path: '',
              child: [
                {
                  name: '생산현장용 화면',
                  path: '/monitormanage',
                },
                {
                  name: '생산기술용 화면',
                  path: '/manage',
                },
              ]
            },
            {
              name: '가공현황',
              path: '',
              child: [
                {
                  name: '실시간 현황' ,
                  path: '/live',
                },
                {
                  name: '알람기록 조회' ,
                  path: '/alarmRecord',
                },
              ]
            },
          ]
        },
        {
          name: '공정 관리',
          icon: {
            value: '$productManage',
            bigger: false,
          },
          path: '/manage',
          children: [
            {
              name: '기 공정 분석',
              path: '',
              child: [],
            },
            {
              name: '가공 프로그램 관리',
              path: '/program',
              child: [], 
            },
          ]
        },
        {
          name: '경영지원 데이터',
          icon: {
            value: '$operationData',
            bigger: false,
          },
          path: '/',
          children: [
            {
              name: '설비 종합 효율',
              path: '/oee',
              child: [],
            },
            {
              name: '생산 기록 조회',
              // icon: {
              //   value: '$processRecordCheck',
              //   bigger: true,
              // },
              path: '/productionReport',
              child: [],
            },
          ]
        },
        {
          name: '관리자 화면',
          icon: {
            value: '$staffOnly',
            bigger: false,
          },
          path: '',
          children: [
            {
              name: '모니터 관리' ,
              path: '/monitorSetting',
              child: [],
            },
            {
              name: '기계정보 관리',
              // icon: {
              //   value: '$machineInfoManage',
              //   bigger: true,
              // },
              path: '',
              child: [],
            },
          ]
        },
        {
          name: '공구 교체 화면',
          icon: {
            value: '$staffOnly',
            bigger: false,
          },
          path: '/tool',
          children: [
          ]
        },
      ],
      loadingData: [100],
      loadingOptions: {
        chart: {
          type: 'radialBar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
              hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
            },
            track: {
              background: '#CBCBCB',
              strokeWidth: '67%',
              margin: 0, // margin is in pixels
            },
        
            dataLabels: {
              show: false,
              name: {
                offsetY: 0,
                show: true,
                color: '#888',
                fontSize: '16px'
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: '#111',
                fontSize: '16px',
                show: true,
              }
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#0346DA'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: [' '],
      },

      sidemenu: {
        dim: {
          open: false,
        },
        menu: {
          open: false,
        }
      },
    }
  },
  mounted() {
    this.getScrollStatus(this.$route);
  },
  computed: {
    path() {
      const data = {};
      data.value = this.$router.history.current.path;
      if( data.value == '/' ) return { menu: '', };

      // menu
      for( let m = 0; m < this.menu.length; m++ ) {
        const tg = this.menu[m];

        if( tg.path == data.value ) {
          data.menu = { menu: tg.name, };
          return data;
        } else {
          const subs = tg.children;
          // sub
          for( let m1 = 0; m1 < subs.length; m1++ ) {
            const sub = subs[m1];
            if( sub.path == data.value ) {
              data.menu = { menu: tg.name, sub: sub.name, };
              return data;
            } else {
              const depths = sub.child;
              // depth
              for( let m2 = 0; m2 < depths.length; m2++ ) {
                const depth = sub.child[m2];
                if( depth.path == data.value ) {
                  data.menu = { menu: tg.name, sub: sub.name, depth: depth.name};
                  return data;
                }
              }
            }
          }
        }
      }
      return data;
    },
  },
  watch: {
    $route(to, from) {
      this.getScrollStatus(to);
      to.meta.showHeader ? this.closeSidemenu() : this.sidemenu.dim.open = false;
    },
  },
  methods: {
    showSidemenu() {
      this.sidemenu.dim.open = true;
      this.sidemenu.menu.open = true;
    },
    closeSidemenu() {
      this.sidemenu.dim.open = false;
      this.sidemenu.menu.open = false;
    },
    goHome(){
      this.$router.replace('/').catch(()=>{
        this.$router.go('/');
      });
    },
    getScrollStatus(route) {
      const scroll = route.meta.scroll;
      scroll ? document.documentElement.style.overflowY = 'auto' : document.documentElement.style.overflowY = 'hidden';
    }
  }
}  
</script>

<style scoped lang="scss">
@import "@/assets/css/global.scss";

  * {
    font-family: $scd !important;
    font-weight: 500;
  }

  #sidemenu {
    width: 100%;
    max-height: $header_height;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .header {
    width: 100%;
    padding: 0 18px;
    position: relative;
    z-index: 9;
    background: #606060;

    &__open_menu {
      color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .dim {
    width: 100%;
    height: 100%;
      transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);

    &.active {
      transform: translateX(0);
    }
  }

  .sidemenu {
    @extend .flex_column;

    justify-content: stretch;
    width: 295px !important;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    transform: translateX(-100%);
    transition: all 0.3s;
    background: white;

    &.active {
      transform: translateX(0);
    }
  }


  .top {
    flex: 0 0 auto;
    width: 100%;
    background: white;

    &_logo {
      width: calc(100% - 54px);
      margin: 0 auto;
      padding: 44px 20px 24px;
      border-bottom: 1px solid #5E7295;
      flex: 0 0 auto;
  
      img {
        display: block;
        width: 120px;
        height: auto;
        margin: 0 auto;
      }
    }

    .profile {
      width: 100%;
      padding: 40px 20px 56px;
      flex: 0 0 auto;

      .profile__circle {
        position: relative;
      }
      .loading_bar {
        position: relative;
        bottom: -1px;
        left: -1px;
      }
      .profile_img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $yhsblue;
      }

      .my_page {
        display: block;
        margin: 0 auto;
        background: $skyblue !important;
      }
      .yhs_profile {
        margin-right: 8px;
      }
    }
  }

  .menu {
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 110px);
    margin: 0;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    padding: 0;
    // padding-top: 335px;

    @mixin hover_border {
      content: "";
      display: block;
      width: 6px;
      height: 60px;
      background: $yhsblue !important;
      position: absolute;
      left: 0px;
      top: 0;
      z-index: 2;
    }
    .inner {
      @include scroll-preset; 

      height: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      padding-bottom: 24px;
    }

    ::v-deep .menu_item {
      padding-left: 54px;

      &:hover,
      &.v-list-group--active {
        position: relative;
        
        &::after {
          @include hover_border;
        }
        .menu_text {
          color: $yhsblue !important;
        }
        .menu_icon {
          path { fill: $yhsblue !important; }
        }
        .v-list-group__icon {
          background-color: red !important;
          i { fill: $yhsblue !important; }
        }
      }

      i {
        color: $main_blue_pale;
      }

      // menu_item group header
      .v-list-item {
        height: 60px;

        &.v-list-item--active {
          
          .text { color: $yhsblue !important; }
          &.v-list-group__header {
            background-color: #eff5fb !important;
          }
          .v-list-group__header__append-icon {
            i { color: $yhsblue !important; }
          }
        }
      }
      .menu_icon {

        &-bigger {
          transform: scale(1.1);
        }
      }
      .menu_text {
        font-family: $scd;
        font-weight: 500 !important;
      }
      .text {
        color: $grayblue !important;
      }

      // depth or no depth
      &.menu-no_depth {
        height: 60px;

        &:hover {

          &::after {
            @include hover_border;
          }
        }
        .icon {
          align-self: center !important;
          margin-right: 0 !important;
        }
        .text {
          padding-left: 20px;
        }
      }

      &.menu-depth {
        padding: 0 !important;
        
        .v-list-item {
          padding: 0 0 0 54px !important;
          justify-content: stretch;
        }
        .v-list-item__content {
          @extend .flex_row;

        }

        .content {
          flex: 1 1 auto;
        }
        .icon {
          margin-right: 0 !important;
          padding-right: 0 !important;
        }
        .text {
          padding-left: 20px !important;
          font-weight: 500;
        }

        .v-list-item__icon {
          flex: 0 0 auto;
          margin-left: 0 !important;
          padding-right: 8px;
        }
      }

      // 2 depth
      .sub_menu {

        .v-list-item {
          background-color: white !important;

          &.v-list-item--active {
            
            &.v-list-group__header {
              background-color: white !important;
            }
            .v-list-item__title {
              color: $yhsblue !important;
            }
          }
          
        }
      }
      .sub-no_depth { 

        .text {
          padding-left: 43px !important;
          font-weight: 500;
        }
      }
      .sub-depth {
        
        .v-list-group__header {
          padding-left: 100px !important;
        }
        .v-list-item {
          justify-content: flex-end !important;
        }

        .v-list-item__icon {
          padding: 0 8px;
          margin-right: 0;
          order: 2;
          transform: scale(0.7, 1.3);
        }
        .text {
          padding-left: 0 !important
        }
      }

      // 3 depth
      .below_menu {
        height: 40px !important;
        min-height: unset;

        &.v-list-item--active {
          background-color: #f2f2f2 !important;

          &::before {
            opacity: 0;
          }
        }

        .text {
          font-size: 14px !important;
          padding: 2px 0 0 46px !important;
        }
      }
    }
  }
  
  .bottom {
    flex: 0 0 auto;
    width: 100%;
    height: 110px;
    background: white;

    .login_out {
      width: 100%;
      flex: 0 0 auto;
      
      text-align: center;
      padding: 16px 0;

      .logout {
        background-color: $yhsblue;
      }
      .login {
        border-color: $yhsblue !important;
        color: $yhsblue !important;
      }
    }
    .version {
      display: inline-block;
      width: 100%; 
      flex: 0 0 auto;
      text-align: center;
      font-size: 12px;
      padding: 0 0 24px;
      line-height: 2;
      margin-bottom: 0 !important;
    }
  }

</style>