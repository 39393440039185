<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.09 25.09"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M.78.0h9.41A.79.79.0 0 1 11 .79v9.4a.79.79.0 0 1-.79.79H.78A.78.78.0 0 1 0 10.19V.79A.78.78.0 0 1 .78.0zM13.72 22.74H23.91a1.18 1.18.0 1 1 0 2.35H13.72a1.18 1.18.0 0 1 0-2.35zm0-8.63H23.91a1.18 1.18.0 1 1 0 2.36H13.72a1.18 1.18.0 0 1 0-2.36zm0 4.32H23.91a1.18 1.18.0 1 1 0 2.35H13.72a1.18 1.18.0 0 1 0-2.35zM.78 14.11h9.41a.79.79.0 0 1 .79.79v9.41a.78.78.0 0 1-.79.78H.78A.78.78.0 0 1 0 24.31V14.9a.79.79.0 0 1 .78-.79zM13.72 8.63H23.91a1.18 1.18.0 1 1 0 2.35H13.72a1.18 1.18.0 0 1 0-2.35zm0-8.63H23.91a1.18 1.18.0 0 1 1.18 1.18 1.18 1.18.0 0 1-1.18 1.17H13.72a1.17 1.17.0 0 1-1.17-1.17A1.18 1.18.0 0 1 13.72.0zm0 4.32H23.91a1.17 1.17.0 0 1 1.18 1.17 1.19 1.19.0 0 1-1.18 1.18H13.72a1.18 1.18.0 0 1 0-2.35z"/></g></g></svg>
</template>

<script>
export default {
  name: 'staffOnly',
}
</script>

<style lang="scss" scoped>
  .cls-1{
    fill:#8198be;
    fill-rule:evenodd;
  }
</style>