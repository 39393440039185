<template>
  
   <div class="product_manage">

    <!-- bottom -->
    <div class="product_manage__content" ref="bottom">
      <!-- left -->
      <div class="content__left block">
        <!-- header -->
        <div class="product_manage__header" ref="header">
          <div 
            class="header__machine"
            :class="{ 'null': mdata.execution == null}"
            :style="'background-color: '+ getStatusColor(mdata.execution)"
          >
            <p class="header__machine_execution">{{executionForm(mdata.execution)}}</p>
            <p class="header__machine_number">{{ mdata.machineNo }}</p>
          </div>
          <div class="header__info">
            <div class="header__mid">
              <p class="header__mid_text">{{ mdata.mid }}</p>
            </div>
            <p class="header__process block">{{ mdata.process }}</p>
          </div>
        </div>

        <!-- gauge -->
				<div v-if="websocket_message">{{ websocket_message }}</div>
        <div class="graph_content">
          <div class="graph_content__inner">
            <div 
              class="speed graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                Spindle<br>Speed
                <br><span class="unit">(rpm)</span>
              </p>
              <Plotly  
                id='grp1' ref="gg1" 
                :data='gdata1' 
                :layout="layout1" 
                :displayModeBar="false" 
              ></Plotly>
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_sspeed"
                  ref="gauge_sspeed"
                  height="500"
                ></Highcharts>
              </p>
            </div>

            <div 
              class="feed graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                Feed
                <br><span class="unit">(mm/s)</span>
              </p>
              <Plotly  
                id='grp2' ref="gg2" 
                :data='gdata2' 
                :layout="layout2" 
                :displayModeBar="false" 
              ></Plotly> 
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_feed"
                  ref="gauge_feed"
                ></Highcharts>
              </p>
            </div>

            <div 
              class="sload graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                Spindle<br>Load
                <br><span class="unit">(%)</span>
              </p>
              <Plotly  
                id='grp' ref="gg" 
                :data='gdata' 
                :layout="layout0"
                :displayModeBar="false" 
              ></Plotly>
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_sload" 
                  ref="gauge_sload"
                ></Highcharts>
              </p>
            </div>

            <div 
              class="x graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                X-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
              <Plotly  
                id='grp' ref="gg3" 
                :data='gdata3' 
                :layout="layout3" 
                :displayModeBar="false" 
              ></Plotly>
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_xload" 
                  ref="gauge_x"
                ></Highcharts>
              </p>
            </div>

            <div 
              class="z graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                Z-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
              <Plotly  
                id='grp' ref="gg4" 
                :data='gdata4' 
                :layout="layout4" 
                :displayModeBar="false" 
              ></Plotly>
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_zload" 
                  ref="gauge_z"
                ></Highcharts>
              </p>
            </div>

            <div 
              class="y graph"
              :style="'grid-template-rows:' + graphHeight.value + 'px;'"
            >
              <p class="graph__title">
                Y-axle<br>Load
                <br><span class="unit">(%)</span>
              </p>
              <Plotly  
                id='grp' ref="gg5" 
                :data='gdata5' 
                :layout="layout5" 
                :displayModeBar="false" 
              ></Plotly>
              <p class="graph_gauge" >
                <Highcharts 
                  :options="gaugeOpt_yload" 
                  ref="gauge_y"
                ></Highcharts>
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- right -->
      <div id="bottom_right" class="content__right block">
        <div class="content__right_inner">

          <p class="graph__title v1__title">Tool Path 그래프</p>
          <div class="v1">
            <Plotly  id='vgrp' ref="vgg" 
              :data="v1Data"  
              :layout="v1Layout" 
              :displayModeBar="false"
              :style="'min-width: 300px; width: ' + 28 + 'vw; height:' + 26 + 'vh;'"
            ></Plotly>
            
          </div>
          <div class="gcode">
            <v-data-table
              :items="mdata.modal"  
              :headers="gcodeHeaders"
              dense
              :options="{itemsPerPage:5}"
              hide-default-header
              hide-default-footer
            >
              <template slot="no-data">
                <div class="no-data">데이터가 없습니다</div>
              </template>
            </v-data-table>
          </div>

          <ul class="message">
            <li class="graph__title gcode__title">알람 <span class="amp">&amp;</span> 메시지</li>
            <li class="item" v-for="(m, index) in message" :key="m.date + 'msg' + index">
              <p class="date">
                <v-icon size="18" class="alert_icon">$alertTri</v-icon>
                {{ dateform(m.date) }}
              </p>
              <p class="text">{{ m.message.replace(/[0-9]/g, "") }}</p>
            </li>
          </ul>
          <div class="memo">
            <div class="graph__title memo__title graph__title-icon">
              <p class="text">메모</p>
              <v-btn 
                fab depressed text x-small
                class="write_btn"
                @click="memoWrite"
              >
                <v-icon size="20" v-if="!memoDisabled">$modify</v-icon>
                <v-icon size="20" v-if="memoDisabled">mdi-check</v-icon>
              </v-btn>
            </div>
            <textarea 
              class="textarea"
              v-model="memo"
              :readonly="!memoDisabled"
            ></textarea>
          </div>

        </div>
      </div>
    </div>

  </div>



</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import { Plotly } from 'vue-plotly';
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import hcMore from "highcharts/highcharts-more";
import solidGauge from 'highcharts/modules/solid-gauge';
import VueHead from 'vue-head';

hcMore(Highcharts);
solidGauge(Highcharts);

Vue.use(Plotly,VueHead);
  export default {
    name: 'productManage',
    components: {
      'Plotly': Plotly,
      Highcharts: Chart,
    },  
    computed: {
      layout0() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = 'Spindle load';
        temp.yaxis= {range: [0, 150]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      layout1() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = "Spindle Speed";
        temp.yaxis= {range: [0, 1000]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      layout2() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = "Feed";
        temp.yaxis= {range: [0, 12000]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      layout3() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = "Xload";
        temp.yaxis= {range: [0, 150]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      layout4() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = "Zload";
        temp.yaxis= {range: [0, 150]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      layout5() {
        let temp = JSON.parse(JSON.stringify(this.layoutSource));
        // temp.title = "Yload";
        temp.yaxis= {range: [0, 150]};
        temp.xaxis.tickformat= "%M:%S.%2f";
        return temp;
      },
      
      gaugeOpt_sspeed() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Spindle Speed';
        layout.yAxis.plotBands = [
					this.makeCustomPlotBand(5, 0, 400, '#55BF3B'),
					this.makeCustomPlotBand(5, 400, 800, '#DDDF0D'),
					this.makeCustomPlotBand(5, 800, 1000, '#D9484B')
        ];
        layout.yAxis.max = 1000;
        return layout;
      },
      gaugeOpt_feed() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Feedrate';
        layout.yAxis.plotBands = [
					this.makeCustomPlotBand(5, 0, 7500, '#55BF3B'),
					this.makeCustomPlotBand(5, 7500, 11250, '#DDDF0D'),
					this.makeCustomPlotBand(5, 11250, 15000, '#D9484B')
        ];
        layout.yAxis.max = 15000;
        return layout;
      },
      gaugeOpt_sload() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Spindle load';
        return layout;
      },
      gaugeOpt_xload() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Xload';
        return layout;
      },
      gaugeOpt_zload() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Zload';
        return layout;
      },
      gaugeOpt_yload() {
        let layout = JSON.parse(JSON.stringify(this.gauge_Opt));
        layout.series[0].name = 'Yload';
        return layout;
      },

    },
    
    data() {
      return {
        startTime:null,
        processList:[],
        loadCNCData: false,
        mkey:0,//this.$store.state.selectedCnc.mkey,
        mdata:{},
        memo:'',
        websocket_message: '',
        //그래프 data와 layout
        gdata:[
          {
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.5,
          }],
        gdata1:[
          {
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.7
          }
        ],
        gdata2:[{
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.7
          }],
        gdata3:[{
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.7
          }],
        gdata4:[{
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.7
          }],
        gdata5:[{
            x: [],y: [],
            name:'현재가공', type:"scatter",
            marker: { size: 1,  color:'#3768f3',},
            fill: 'tozeroy',
            fillcolor: 'rgba(55, 104, 243, 0.3)',
            line: { color:'#3768f3',},
            opacity: 1
          },
          {
            x: [],y: [],
            name:'이전가공', type:"scatter",
            marker: {size: 1, color:'#838383', }, 
            opacity: 0.7
          }],
        layoutSource: {
          autosize: true,
          margin: { l: 40,r: 20,b: 20,t: 5, pad: 4},
          xaxis: {
            type:'date',
            title: {
              text: '',
              font: {family: 'inherit',size: 8,color: '#7f7f7f'}
            },
          },
          yaxis: {
            title: {
              text: '',
              font: {family: 'inherit',size: 8,color: '#7f7f7f'}
            },
          },
          config: {
            responsive: true,
          },
          showlegend: false,
        },
        
        timer: moment().format('YYYY-MM-DD hh:mm:ss'),
        graphHeight: {
          value: 50,
          parent: 100,
          v1: 300,
        },
        message: [],
        memoDisabled: false,

        v1Data: [{
          x: [],
          y: [],
          z: [],
          text:[],
          color:[],
          type:"scatter",
          mode:"lines+markers",
          showlegend: false,
          marker: {
            size: 4,
            color:[],
            line:{
              color:'gray'
            }
          },
          opacity: 1
        }],
        v1Layout: { // 오른쪽 그래프
          //title: "xyz plot",
          paper_bgcolor:'#747980',
          plot_bgcolor:'#747980',
          autosize: true,
          margin: { l: 30, r: 30, b: 30, t: 30,},
          xaxis: {
            title: {
              text: 'Z Axis',
              font: {
                family: 'Courier New, monospace',
                size: 12,
                color: '#7f7f7f'
              }
            },
           },
          yaxis: {
            title: {
              text: 'X Axis',
              font: {
                family: 'Courier New, monospace',
                size: 12,
                color: '#7f7f7f'
              }
            }
          },
          // config: {
          //   responsive: true,
          // }
        },
        high:Highcharts,
        memoid:Number,
        gcodeData: [],
        gcodeHeaders: [
          { text: 'Gcode', value: 'block', align: 'right' },
        ],
        gauge_Opt: {
          chart: {
            type: "gauge", 
            width: 120,
            height: 120,
            plotShadow: false,
            animation: false
          },

          config: {
            responsive: true,
          },

          title: {
            text: null
          },

          pane: {
            size: '100%',
            startAngle: -150,
            endAngle: 150,
            center: ['50%', '50%'],
            background: [
              {
                backgroundColor: "#ffffff",
                borderWidth: 0,
                outerRadius: "110%",
                innerRadius: "100%"
              },
              {
                backgroundColor: "#838383",
                borderWidth: 0,
                outerRadius: "112%",
                innerRadius: "110%"
              }
            ]
          },

          // the value axis
          yAxis: {
            min: 0,
            max: 150,

            minorTickInterval: "auto",
            minorTickWidth: 1,
            minorTickPosition: "inside",
            minorTickLength: 6,
            minorTickColor: "#606060",

            tickPixelInterval: 30,
            tickWidth: 2,
            tickPosition: "inside",
            tickLength: 5,
            tickColor: "#606060",
            labels: {
              step: 1,
              rotation: 'auto',
              distance: -16,
              style: {
                'font-size': 8,
              }
            },
            plotBands: [
              {
                thickness: 5,
                from: 0,
                to: 50,
                color: "#55BF3B" // green
              },
              {
                thickness: 5,
                from: 50,
                to: 75,
                color: "#DDDF0D" // yellow
              },
              {
                thickness: 5,
                from: 75,
                to: 150,
                color: "#D9484B" // red
              }
            ]
          },

          plotOptions :{
            solidgauge: {
              dataLabels: {
                enabled: true,
              },
            },
            gauge: { // 침
              dial: {
                radius: '80%',
                backgroundColor: 'black',
                baseWidth: 3,
                topWidth: 1,
                baseLength: '3%',
                rearLength: '0%',
              },
              pivot: {
                radius: 3,
              }
            },
          },

          series: [
            {
              name: "value",
              data: [0],
              dataLabels: {
                style: {
                  fontSize: '10px',
                },
                crop: false,
                padding: 0,
              }
            }
          ],
          credits: {
            enabled: false
          },
        },
        colorchip: {
          gray: '#8c8c8c',
          traffic_red: '#F56713',
          traffic_yellow: '#F5B117',
          traffic_green: '#6BB236',
          progress_gray: '#B2B2B2',
        }
      };
    },
    head: {
      meta: [
        { 'http-equiv': 'Pragma', content: 'no-cache' },
        { 'http-equiv': 'Expires', content: '0' },
        { 'http-equiv': 'Cache-Control', content: 'no-cache' }
      ]
    },
    methods: {
      executionForm(v){
        if(v==null){
          v = `Network\
          Error`;
        }
        return v;
      },
      add:function(){
          this.gdata1[0].x.forEach(e=>{
          this.gdata1[1].x.push(e);
          });
          this.gdata1[0].y.forEach(e=>{
          this.gdata1[1].y.push(e);
          });
      },
      gauge_val(live) {
        this.$refs.gauge_sspeed.chart.series[0].points[0].update(Number(live.S1speed)); 
        this.$refs.gauge_sload.chart.series[0].points[0].update(Number(live.S1load)); 
        this.$refs.gauge_feed.chart.series[0].points[0].update(Number(live.path_feedrate)); 
        this.$refs.gauge_x.chart.series[0].points[0].update(Number(live.Xload)); 
        this.$refs.gauge_y.chart.series[0].points[0].update(Number(live.Yload)); 
        this.$refs.gauge_z.chart.series[0].points[0].update(Number(live.Zload)); 
      },
      
      sload(start,load){
        this.gdata[0].x.push(start);
        this.gdata[0].y.push(load); 
          if(this.$refs.gg){
            this.$refs['gg'].restyle('grp',{})
          } 
      },
      speed(start,speed){
        this.gdata1[0].x.push(start);
        this.gdata1[0].y.push(speed); 
          if(this.$refs.gg1){
            this.$refs['gg1'].restyle('grp1',{})
          } 
      },
      feed(start,feed){
        this.gdata2[0].x.push(start);
        this.gdata2[0].y.push(feed); 
          if(this.$refs.gg2){
            this.$refs['gg2'].restyle('grp2',{})
          } 
      },
      xload(start,xload){
        this.gdata3[0].x.push(start);
        this.gdata3[0].y.push(xload); 
          if(this.$refs.gg3){
            this.$refs['gg3'].restyle('grp3',{})
          } 
      },
      zload(start,zload){
        this.gdata4[0].x.push(start);
        this.gdata4[0].y.push(zload); 
          if(this.$refs.gg4){
            this.$refs['gg4'].restyle('grp4',{})
          } 
      },
      yload(start,yload){
        this.gdata5[0].x.push(start);
        this.gdata5[0].y.push(yload); 
          if(this.$refs.gg5){
            this.$refs['gg5'].restyle('grp5',{})
          } 
      },
      getalarm(){
        this.$http.DT.get('/manage/'+this.mkey).then(r=>{
         this.message=r.data;
        })
      },
      getGraphHeight() {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const except = {
          header: 40,
          top: this.getStyleValue('.product_manage__header', 'height'),
          pad: this.getStyleValue('.graph_content', 'paddingTop'),
        };

        if( width >= 1440 ) {
          this.graphHeight.v1 = 250;
        } else if( width >= 1280 && width < 1440 ) {
          this.graphHeight.v1 = 200;
        } else if( width < 1280) {
          this.graphHeight.v1 = 180;
        }

        this.graphHeight.parent = (height - except.header - except.top - except.pad);
        let value = ( this.graphHeight.parent / 6 ).toFixed(3);
        this.graphHeight.value = Number(value) - 8;
      },
      getStyleValue(el, type) {
        let tg = document.querySelector(el);
          if( el == '' || tg == undefined ) return;
        let style = getComputedStyle(tg)[type];
        return parseInt(style);
      },
      animationFrame(cb) {
        let rAftimeout = null;
        return function() {
          if( rAftimeout ) { window.cancelAnimationFrame(rAftimeout); }
          rAftimeout = window.requestAnimationFrame(function() {
            cb();
          })
        };
      },
      getmemo(){
        this.$http.DT.get('/memo/m/'+this.mkey).then(r=>{
          if(!r.data.length==0){
           this.memo=r.data[0].comment;
          }
        })
      },
      dateform(val){
        val= val.replace('T',' ').substr(0,19);
        // val= moment(val).format('YYYY-MM-DD HH:mm:ss');
        return val;
      },
      memoWrite() {
        if( this.memoDisabled ) {
          this.$http.DT.post('/memo/m',{memo:this.memo,mid:this.mkey}).then(r=>{
          })
          this.memoDisabled = false;
        } else {
          this.memoDisabled = true;
        }
      },
      getStatusColor(exec) {
        if( exec == 'POWER_OFF' ) {
          return this.colorchip.gray;
        } else if( exec == 'ACTIVE' ) {
          return this.colorchip.traffic_green;
        } else if( exec == 'STOPPED') {
          return this.colorchip.traffic_yellow;
        } else if ( exec == null ) {
          return this.colorchip.traffic_red;
        }
        return this.colorchip.traffic_green;
      },
      setGaugeSize() {
        const chart = this.gauge_Opt.chart;
        const hei = this.graphHeight.value;
        chart.height = hei;
        chart.width = hei * 0.8;
      },
			// 커스텀한 PlotBand 객체 (그래프 우측 계기판 수치 설정 객체) 생성하는 메소드
			makeCustomPlotBand(thickness, from, to, color) {
				return {
					thickness: thickness,
					from: from,
					to: to,
					color: color
				}
			}
    },
    mounted(){
      // 그래프 높이 구하기
       this.getGraphHeight();
       this.setGaugeSize();
       window.addEventListener('resize', this.animationFrame(this.getGraphHeight));
       window.addEventListener('resize', this.animationFrame(this.setGaugeSize));

      this.mkey=this.$store.state.selectedCnc.mkey;
      this.$cnc.setCncList(this.processList);
      this.$cnc.start();
      this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=mid:'+this.mkey);
      var intval = setInterval(()=>{
        if(this.$cnc.getProcessData(this.mkey) !==undefined){
          this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
					if(this.$route.query.yhsAdminDebug0101 === 'true') {
						this.$options.sockets.onmessage = (data) => {
							if(data.data.constructor.name === 'Blob') {
								data.data.text().then(text => {
									this.websocket_message = text;
								});
							}
						}
					}
          this.getmemo();
          this.getalarm();
          clearInterval(intval);
        }
        
      },100);
      this.$cnc.graphRefresh( (mkey,start,live,x,y,z,) =>{
        //if( this.mid === mkey){
          if(this.startTime == null){
            //this.startTime = new Date().getTime();
            this.startTime = this.mdata.activeStartTime;
          }
          var n = new Date();
          var elap = n.getTime() - this.startTime;
          n.setTime(elap);
          start = n;
          this.v1Data[0].x.push(z);
          this.v1Data[0].y.push(x);
          this.v1Data[0].z.push(y);
           if(this.$refs.vgg){
              this.$refs['vgg'].restyle('vgrp',{})
          } 
          this.sload(start,live.S1load);
          live.S1speed ? this.speed(start,live.S1speed) : this.speed(start,live.S2speed);
          this.feed(start,live.path_feedrate);
          this.xload(start,live.Xload);
          this.zload(start,live.Zload);
          if(live.Yload){
            this.yload(start,live.Yload);
          }
          if(live){
            this.gauge_val(live);
          }
        //}
      });
      this.$cnc.graphReset( (mkey) =>{
    //    if(this.mid===mkey){
        console.log(mkey);
        this.startTime = new Date().getTime();
          this.v1Data[0].x.length=0;
          this.v1Data[0].y.length=0;
          this.v1Data[0].z.length=0;
          
          
          this.gdata[1].x.length=0;
          this.gdata[1].y.length=0;
          this.gdata[0].x.forEach(e=>{
            this.gdata[1].x.push(e);
          });
          this.gdata[0].y.forEach(e=>{
            this.gdata[1].y.push(e);
          });
          this.gdata[0].x.length=0;
          this.gdata[0].y.length=0;


          this.gdata1[1].x.length=0;
          this.gdata1[1].y.length=0;
          this.gdata1[0].x.forEach(e=>{
            this.gdata1[1].x.push(e);
          });
          this.gdata1[0].y.forEach(e=>{
            this.gdata1[1].y.push(e);
          });
          this.gdata1[0].x.length=0;
          this.gdata1[0].y.length=0;


          this.gdata2[1].x.length=0;
          this.gdata2[1].y.length=0;
          this.gdata2[0].x.forEach(e=>{
            this.gdata2[1].x.push(e);
          });
          this.gdata2[0].y.forEach(e=>{
            this.gdata2[1].y.push(e);
          });
          this.gdata2[0].x.length=0;
          this.gdata2[0].y.length=0;

          this.gdata3[1].x.length=0;
          this.gdata3[1].y.length=0;
          this.gdata3[0].x.forEach(e=>{
            this.gdata3[1].x.push(e);
          });
          this.gdata3[0].y.forEach(e=>{
            this.gdata3[1].y.push(e);
          });
          this.gdata3[0].x.length=0;
          this.gdata3[0].y.length=0;

          this.gdata4[1].x.length=0;
          this.gdata4[1].y.length=0;
          this.gdata4[0].x.forEach(e=>{
            this.gdata4[1].x.push(e);
          });
          this.gdata4[0].y.forEach(e=>{
            this.gdata4[1].y.push(e);
          });
          this.gdata4[0].x.length=0;
          this.gdata4[0].y.length=0;

          this.gdata5[1].x.length=0;
          this.gdata5[1].y.length=0;
          this.gdata5[0].x.forEach(e=>{
            this.gdata5[1].x.push(e);
          });
          this.gdata5[0].y.forEach(e=>{
            this.gdata5[1].y.push(e);
          });
          this.gdata5[0].x.length=0;
          this.gdata5[0].y.length=0;
 //       }
      });

    },
    destroyed(){
			this.$disconnect();
      this.$cnc.stop();
    },
    watch:{
      processList:function(a){
        for(let i=0; i<this.processList.length; i++){
          if(this.mkey == this.processList[i].mkey){
            this.mdata=this.processList[i];
          }
        }
      },
    },

  }
</script>
<style scoped lang="scss">
@import "~@/assets/css/global.scss";

  * {
    font-family: $scd !important;
    font-weight: 300;
  }

  .product_manage {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    overflow: hidden;

    > div {
      width: 100%;
      height: 100%;
      font-size: 20px;
    }

    &__content {
      @extend .flex_row;

      overflow-y: hidden;
      justify-content: stretch;
      background-color: white;

      .block {
        height: 100%;
      }

      .content__left {
        @extend .flex_column;

        justify-content: stretch;
        flex: 1 1 auto;

        .product_manage__header {
          @extend .flex_row;

          width: 100%;
          flex: 0 0 auto;
          min-height: 80px;
          align-items: flex-start;
          padding-left: map-get($vw, 40);
          background-color: white;

          .header__machine {
            @extend .flex_column;

            justify-content: center;
            min-width: map-get($vw, 105);
            min-height: map-get($vw, 100);
            height: 100%;
            padding: map-get($vw, 16);
            margin-right: map-get($vw, 48);
            background: green;

            &.null {
              width: 85px;

              .header__machine_execution {
                width: 80%;
                line-height: 1;
              }
            }

            &_execution {
              text-align: center;
              font-size: map-get($vw, 14);
              letter-spacing: 0.2px;
              font-weight: 400;
              color: white;
            }
            &_number {
              font-size: map-get($vw, 40);
              line-height: 0.7;
              font-weight: 600;
              padding-top: 6px;
              margin-top: 6px;
              text-align: center;
              color: white;
            }
          }
          .header__info {
            @extend .flex_row;

            align-self: flex-end;
            align-items: flex-end;
            padding-bottom: map-get($vw, 12);
            background: unset !important;

            .header__mid {
              font-size: map-get($vw, 40);
              margin-right: 16px;
              padding-bottom: 2px;

              &_text { 
                font-weight: 500;
                padding-top: 3px;
                line-height: 1;
              }
            }
            .header__process {
              font-size: map-get($vw, 24);
              line-height: 1;
              padding-bottom: 5px;
            }
          }
        }
        .graph_content {
          width: 100%;
          flex: 1 1 0;
          padding: map-get($vw, 20) map-get($vw, 40);
          overflow: hidden;

          &__inner {
            @extend .flex_column;

            grid-template-rows: repeat(6, 1fr);
            width: 100%;
            height: 100%;
            justify-content: stretch;
            overflow: hidden;
          }

          .graph {
            display: grid;
            grid-template-columns: auto 1fr auto;
            width: 100%;
            // flex: 1 1 16.666%;
            flex: 1 1 0;

            .graph__title {
              width: map-get($vw, 105);
              font-size: map-get($vw, 21);

              .unit {
                font-size: 12px;
                margin-top: 6px;
              }
            }

            ::v-deep .js-plotly-plot { 
              width: 100% !important;

              svg { width: 100% !important; }
            }
            ::v-deep .svg-container {
              width: 100% !important;

              svg { width: 100%; }
            }

            .graph_gauge {
              overflow-y: hidden;

              > div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

      }

      .content__right {
        flex: 0 0 auto;

        &_inner {
          @extend .flex_column;

          width: 100%;
          height: 100%;
          padding: map-get($vw, 50) map-get($vw, 36) map-get($vw, 25);
          background-color: #ededed;

          > div {
            width: 100%;
          }
        }

        .graph__title {
          width: 100%;
          font-size: map-get($vw, 21);
          font-weight: 500;

          &.graph__title-icon {
            @extend .flex_row;

            justify-content: space-between;
          }

          .text {
            font-size: inherit;
            font-weight: inherit;
          }

          &.v1__title {
            text-align: right;
            font-size: map-get($vw, 24);
            margin-bottom: map-get($vw, 21);
          }
          &.gcode__title {
            border-bottom : 1.2px solid rgba(0, 0, 0, 0.1);
          }
        }

        .v1 {
          width: 100%;
          flex: 0 0 0;
        }

        .gcode {
          flex: 0 0 atuo;
          border-bottom: 1px solid black;
          padding: 4px 0 4px;
          margin-bottom: map-get($vw, 32);

          ::v-deep .v-data-table {
            background-color: unset !important;

            td {
              height: map-get($vw, 16);
              border: 0 !important;
              padding: 0;
            }          
          }
        }

        .message {
          width: 100%;
          flex: 0 0 atuo;
          font-size: map-get($vw, 14);
          border-bottom: 0;
          margin-bottom: map-get($vw, 40);

          .amp {
            font-family: sans-serif !important;
            font-weight: bold;
          }

          .item {
            @extend .flex_row;

            justify-content: stretch;
            padding: 4px 0;
            font-size: map-get($vw, 14);
            border-bottom: 1.2px solid rgba(0, 0, 0, 0.1);
          }
          .date {
            flex: 0 0 38%;
            padding: 0 6px 0 0;

            ::v-deep .alert_icon {
              margin-right: 3px;

              .cls-1 { fill: #949494 !important; }
            }
          }
          .text {
            flex: 1 1 auto;
            word-break: break-word;
          };
        }

        .memo {
          @extend .flex_column;

          justify-content: justify;
          flex: 1 1 auto;
          justify-self: flex-end;
          margin-top: auto;
          padding: map-get($vw, 12);
          background-color: #D5D5D5;

          .memo__title {
            @extend .flex_row;

            width: 100%;
            flex: 0 0 auto;
            margin-bottom: 8px;
          }
          ::v-deep .write_btn {
            width: 36px !important;
            height: 36px !important;
          }

          .textarea {
            flex: 1 1 auto;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid gray;
            font-size: map-get($vw, 15);

            &:focus {
              outline: 0;
              border-bottom: 2px solid cornflowerblue;
            }
          }
        }
      }
    }

    // highcharts gauge style
    ::v-deep .highcharts-data-label-box {
      stroke: unset !important;
      // fill: #f0f0f0 !important;
    }
  }
</style>