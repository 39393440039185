<template>
  <div id="oee">
    <div class="oee__top">
      <div class="oee__filter">
        <select name="mids" id="mids" class="filter__mids" ref="mids" v-model="mid" >
          <option v-for="mid in mids" :key=" mid.id" :value="mid.id" class="mid" >{{ mid.name }}</option>
        </select>
        <!-- <dateTimePicker :preset="date" @picked="setFilterDate"></dateTimePicker> -->
        <dayPicker 
          class="filter__day" 
          :preset="date" 
          :presetIcon="['append', false, false]"
          :title="'날짜 선택'"
          :clickPick="true"
          @picked="setFilterDate"
        ></dayPicker>
        <v-btn 
          class="filter__search" 
          @click.stop="search"
          tile outlined
          height="32"
        >검색</v-btn>
      </div>
    </div>

    <div class="content">
      <!-- timeline -->
      <div class="rangebar__wrap">
         <loading id="loading" :active.sync="visible" :can-cancel="true" color="#0346DA" :is-full-page="true" loader="dots"></loading>
        <apexchart 
          type="rangeBar"
          class="rangebar"
          :height="window_width < 1366 ? 180 : 250"
          ref="timeline"
          :options="timelineOptions" 
          :series="timelineSeries"
        ></apexchart>
      </div>
      <!-- pie -->
      <div class="pie__wrap" v-if ='visible == false'>
        <div class="pie__labels">
          <p class="label part_count">가공시간</p>
          <p class="label setting_time">준비교체시간</p>
        </div>

        <div class="pie__inner">
          <div class="pie__scroll">
            <div  
              v-for='(item, index) in pieSeries' 
              :key='"pie_" + index + "_" + item.program' 
              class="pie__item"
              :class="{
                'pie__item--active': index == timeline_click_target.data_point,
              }"
            >
              <div class="pie__program">
                <p class="value">{{programFormatter( item.program )}}</p>
              </div>
              <div class="pie__area">
                <apexchart 
                  type="donut" 
                  :width="pieOptions.chart.width" 
                  :options="pieOptions"
                  :series="[item.oper,item.wait]"
                  class="graph_pie"
                >
                </apexchart>
                <div class="pie__title lot">
                  <p class="value">{{ item.count }}</p>
                  <p class="pie__sub_title">생산량</p>
                </div>
              </div>
              <div class="pie__legend">
                <ul class="legend_list">
                  <li class="legend wktime">
                    <p class="tit">가공시간</p>
                    <p class="value">{{ timeForm(item.wktime) }}</p>
                  </li>
                  <li class="legend idle">
                    <p class="tit">준비교체시간</p>
                    <p class="value">{{ timeForm(item.idle) }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayPicker from '../components/dayPicker.vue'
import moment from 'moment';
import apexchart from 'vue-apexcharts';
import Loading from 'vue-loading-overlay';
import { time } from 'highcharts';
export default {
  name: 'oee',
  components: {
    apexchart,
    dayPicker,
    loading : Loading
  },
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      mid: Number,
      mids: [],
      visible:false,
      timelineSeries: [],
      timelineOptions: {
        chart: {
          type: 'rangeBar',
          animations: {
            enabled: false,
            speed: 0,
            dynamicAnimation: {
              enabled: false,
              speed: 0,
            },
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              if( config.seriesIndex != 0 ) return;

              let tg = this.timeline_click_target;
              tg.series = config.seriesIndex;
              tg.data_point = config.dataPointIndex;
            },
            dataPointMouseLeave: (event, chartContext, config) => {
              if(config.seriesIndex == 0) this.resetTimelineSelect(config);
            },
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '100%',
          },
          series: {
            pointPlacement: 'on'
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (val, opt) => {
            return this.getGraphDataName(opt);
          },
        },
        title: {
          text: '',
          align: 'center',
          offsetY: 20,
          style: {
            fontSize: 24,
            fontWeight: 500,
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            colorStops: [
              [
                {
                  offset: 0,
                  color: '#898989',
                  opacity: 0.4,
                },
                {
                  offset: 100,
                  color: '#898989',
                  opacity: 0.12,
                },
              ],
              [
                {
                  offset: 0,
                  color: '#3768f3',
                  opacity: 0.4,
                },
                {
                  offset: 100,
                  color: '#3768f3',
                  opacity: 0.12,
                },
              ],
            ]
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: true,
          },
          axisTick: {
            show: true,
          }
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 'dataPoints',
          tickPlacement: 'on',
          axisBorder: {
            show: false,
          },
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'yyyy-MM',
              day: 'yyyy-MM-dd',
              hour: 'HH:mm',
              minute: 'HH:mm',
              second: 'HH:mm:ss',
            },
          },
          format: 'yyyy-MM-DD HH:mm:ss',
        },
        grid: {
          show: true,
          position: 'back',
          borderColor: 'black',
          strokeDashArray: 1,
          yaxis: {
            lines: {
              show: false,
            }
          },
          row: {
            colors: 'undefined',
            opacity: 0,
          },
          column: {
            colors: 'undefined',
            opacity: 0,
          },
          padding: {
            left: 0,
            right: 0,
          },
        },
        // annotations: {},
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: -10, 
          offsetY: 0,
          markers: {
            fillColors: [ '#9FA0A0','#91ABC7',]
          },
        },
        tooltip: {
          custom: (value) => {
            let y1 = new Date(value.y1);
            let y2 = new Date(value.y2);
            let y1Date = moment(value.y1).format('yyyy-MM-DD HH:mm:ss');
            let y2Date = moment(value.y2).format('yyyy-MM-DD HH:mm:ss');
            let name = this.getGraphDataName(value);

            let tooltip = "<div class='tooltip'>";
            tooltip += '<div class="name">' + name + '</div>'
            tooltip += "<p class='time_box'>"
            tooltip += "<span class='start_time'>" + y1Date + "</span>"
            tooltip += " ~ "
            tooltip += "<span class='end_time'>" + y2Date + "</span>"
            tooltip += "</p>"
            tooltip += "</div>"
            return tooltip;
          }
        },
        noData: {
          text: '데이터가 없습니다',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            fontSize: '14px',
            fontFamily: 'Spoqa Han Sans Neo',
          }
        },
      },

      pieSeries: [ {id:1,oper:70,wait: 30}],
      pieOptions: {
        chart: {
          width: 270,
          type: 'donut',
          animations: {
            enabled: false,
            animationGradually: {
              enabled: false,
            },
            dynamicAnimation: {
              enabled: false,
            }
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '16px',
            colors: ['#01608c'],
          },
          dropShadow: false,
        },
        colors: ['#C8D0DB', '#9bb5cc'], // 가공시간, 준비교체시간 순
        labels: [
          '가공시간',
          '준비교체시간'
        ],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom',
            }
          }
        }],
        tooltip: {
          custom: (value) => {
            let per = value.series[value.seriesIndex];
            return per; 
          }
        },
        legend: {
          show: false,
          position: 'bottom',
        },
        grid: {
          padding: {
            right: 0,
            left: 0,
            bottom: 0,
            top: 0,
          }
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.8,
            },
          },
        },
      },
      boardValue: [{
        count: 0,
        id:1
      }],

      filterDate: String,
      program: [],
      partCount: [],
      timeline_click_target: {
        series: undefined,
        data_point: undefined,
      },
      window_width: window.innerWidth,
    };
  },
  async mounted() {
    this.mid=this.$store.state.selectedCnc.mkey;
    this.selectMid();
    await this.program1();
    window.addEventListener('resize', this.animationFrame(this.setGraphHeight));
  },
  methods: {
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame( function() { cb(); } );
      };
    },
    setGraphHeight() {
      this.window_width = window.innerWidth;
    },
    setFilterDate(value) {
      this.filterDate = value;
      this.date=value;
    },
    async search() {
      await this.program1();
    },
    selectMid(){
      this.$http.DT.get('/alarm').then(r=>{
        this.mids=r.data;
      });
    }
    ,
    async program1(){
      this.program=[];
      this.timelineSeries=[];
      this.visible=true;
      this.$http.DT.get('/day/program?startTime='+this.date+'&endTime='+this.date+'&mkey='+this.mid).then(r=>{
        for(let i=0;i<r.data.length;i++) {
          this.program.push({x:'part_count',
            y:[
              new Date(r.data[i].start.replace(/\s/, 'T')).getTime(), 
              new Date(r.data[i].end.replace(/\s/, 'T')).getTime(),
            ],
            description: {
              program:r.data[i].name
            }
          })
        }
        this.timelineSeries.push({name:"LOT",data:this.program});
        this.part();
      })
    },
    async part() {
      this.partCount=[];
      
      this.$http.DT.get('/day/part?startTime='+ this.date+'&endTime='+ this.date +'&mkey='+ this.mid).then(r=>{
        for(let i=0;i<r.data.length;i++){
          this.partCount.push({x:'part_count',
            y:[
              new Date(r.data[i].start.replace(/\s/, 'T')).getTime(), 
              // new Date(r.data[i].start).getTime(), 
              new Date(r.data[i].end.replace(/\s/, 'T')).getTime() 
            ],
            description: {
              part_count:r.data[i].count
            }
          })
        }
        this.timelineSeries.push({name:"PART",data:this.partCount});
        this.visible=false;
     })
      await this.pieGraph();
    },
    timeForm(val){
      if( isNaN(val) ) return '';
      let hour = parseInt(val/3600);
      let fix = parseInt((val%3600)/60)+'분 ' +parseInt(val%60)+'초';
      val = hour > 0 ? hour + '시간 ' + fix : fix;
      return val;
    },
    programFormatter(value) {
      if(value != undefined){
        let char
        if(value.indexOf('(') != -1){
          char = value.split('(')[1];
          char = char.replace(')', '');
        }else{
          char = value ;
        }
        return char;
      }
    },
    pieGraph(){
      this.$http.DT.get('/day/idle?startTime='+this.date+'&endTime='+this.date+'&mkey='+this.mid).then(r=>{
        this.boardValue=[];
        for(let i=0; i<r.data.length; i++){
         r.data[i].oper=(r.data[i].wktime/(r.data[i].wktime+r.data[i].idle)) *100;
         r.data[i].wait=100-r.data[i].oper;
        }
         this.pieSeries=r.data;
      })
    },
    resetTimelineSelect(config) {
      this.timeline_click_target.data_point = undefined;
    },
    getGraphDataName(data) {
      let desc = ['program', 'part_count'];
      let series = data.seriesIndex;
      desc = desc[series];
      let label = data.w.config.series[series].data[data.dataPointIndex].description[desc];
      return label;
    },
  },
  watch:{ 
    // timelineSeries:function(v){
    //   if(v.length==2){
    //     this.timelineSeries2=this.timelineSeries;
    //     this.visible=false;
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/global.scss';

  *, span, p { font-family: $scd; }

  #oee {
    @extend .flex_column;

    justify-content: stretch;
    height: 100%;
    background-color: white;

    .oee__top {
      flex: 0 0 auto;
      width: 100%;

      ::v-deep .oee__filter {
        @extend .flex_row; 
        
        justify-content: flex-end;
        padding: 90px 18px 24px;

        @include relative-web2 {
          padding: 24px 18px;
        }

        ::v-deep .mids {
          width: 160px;
          border-bottom: 1px solid cornflowerblue;
          color: cornflowerblue;
          padding: 0 8px;
          margin-right: 24px;
          
          background: url('~@/assets/img/arrow_down.svg') no-repeat right 8px center;
          background-size: 10px auto;

          &:focus {
            outline: 0;
            background-color: rgba(255, 255, 255, 0.5);
          }
        }
        .filter__mids {
          width: 140px;
          height: 32px;
          border-radius: 0 !important;
          border-bottom: 1px solid $table_black;
          color: black !important;
          background: url('~@/assets/img/box_chevron_down.svg') right 0 center no-repeat;
          background-size: 14px auto;
        }
        .filter__day {
          margin-left: 20px;
          
          .day-picker__field {
            border: 1px solid $table_black;
          }
        }
        .filter__search {
          width: 60px;
          margin-left: 8px;
          border: 1px solid $table_black;
        }
      }
    }

    .content {
      @extend .flex_column;

      flex: 1 1 auto;
      width: 100%;
      justify-content: stretch;

      .rangebar__wrap {
        flex: 0 0 auto;
        width: 100%;
        position: relative;
        padding: 0 16px 24px;

        @include relative-web2 {
          padding: 0 16px 16px;
        }

        .rangebar {
          min-width: 100% !important;
          background-color: #f4f4f4;
          padding: 21px 40px;
        }
        // grid set
        @at-root ::v-deep .apexcharts-grid {
          line:not(:last-child):not(.apexcharts-xaxis-tick) {
            stroke: black;
          }
        }
      }

      .pie__wrap {
        width: 100%;
        flex: 1 1 0;
        padding-top: 64px;
        position: relative;
        overflow-y: hidden;

        @include relative-web2 {
          padding-top: 40px;
        }

        .pie__labels {
          @extend .flex_row;

          justify-content: center;
          width: 100%;
          height: 32px;
          position: absolute;
          top: 0;
          left: 0;
          // gap: 16px;

          .label {
            margin-right: 16px;

            &:last-child {
              margin-right: 0;
            }

            &.setting_time {

              &::before { background-color: $pie_blue;}

              color: $pie_blue;
            }

            &::before {
              content: '';
              display: block;
                width: 10px;
              height: 10px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-5px);
              background-color: $pie_gray;
            }

            font-size: 20px;
            font-weight: 600;
            color: $pie_gray;
            padding: 3px 0 0 14px;
            position: relative;
          }
        }
        
        .pie__inner {
          width: 100%;
          height: 100%;
          overflow-y: scroll;

          .pie__scroll {
            @extend .flex_row;

            flex-wrap: wrap;
            justify-content: center;
            align-items: unset;
            align-content: flex-start;
            width: 100%;
          height: auto;
            padding: 0 48px 36px;
            gap: 32px;
          }
        }


        .pie__item {
          @extend .flex_column;

          height: auto;
          padding: 40px 16px;
          background-color: white;

          &.pie__item--active {
            background-color: #f4f4f4;
          }
    
          .pie__program {
            text-align: center;
            margin-bottom: 16px;

            .value {
              font-size: 18px;
              font-weight: 600;
            }
          }
          .pie__area {
            position: relative;

            .lot {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;

              .value {
                font-size: 36px;
                font-weight: 600;
              }
              .pie__sub_title {
                font-size: 14px;
                font-weight: 500;
                word-spacing: -1.5px;
              }
            }
          }
          .pie__legend {
            width: 100%;

            &::before {
              content: '';  
              display: block;
              margin: 18px auto 32px;
              width: 208px;
              height: 1px;
              background-color: $table_black;
            }

            .legend_list {
              width: 240px;
              margin: 0 auto;  

              .legend {
                margin-bottom: 4px;

                &.wktime {
                  p { color: $pie_gray; }
                }
                &.idle {
                  p { color: $main_blue_pale; }
                  }

                p {
                  display: inline-block;
                  font-weight: 500;
                }
                .tit {
                  width: 42%;
                }
                .value {
                  width: 58%;
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }

  ::v-deep .tooltip {
    font-family: $scd !important;
    padding: 8px 12px;

    .name {
      font-weight: 600;
    }
    p {
      padding: 0;
      margin: 0;
      padding-top: 2px;
      
      span {
        font-size: 14px;
        font-family: $scd;
      }
    }
  }
  .apexcharts-tooltip,
  .apexcharts-tooltip.active,
  .apexcharts-xaxistooltip,
  .apexcharts-xaxistooltip.active,
  .apexcharts-marker {
    transition: none;
  }

  // mac 
  .mac {

    .oee__top {

      ::v-deep .oee__filter {

        .filter__search {
          padding-top: 2px;
        }
      }
    }
  }
</style>