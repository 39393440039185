<template>
  <div id="tool_change_manage">

    <common-header></common-header>
    
    <div class="header table-top-header">
      <div class="mid_name">{{this.$store.state.selectedCnc.mid}}</div>
      <v-btn depressed dark large class="complete_btn">완료</v-btn>
    </div>

    <!-- 실제 데이터 리스트 -->
    <v-data-table
      ref="toolInfoList"
      :headers="header"
      :items="data"
      :items-per-page="-1"
      :disable-pagination="true"
      :footer-props="{ 
        disablePagination: true,
        disableItemsPerPage: true,
      }"
      fixed-header hide-default-footer
      class="tool-info-list-wrap"
    >
      <template v-slot:item="props">
        <tr :class="props.item.id === selectedRow ? 'selected' : ''" @click="rowSelect(props.item)">
          <td>{{ props.item.process_no }}</td>
          <td class="type">{{ props.item.type }}</td>
          <td>{{ props.item.tool_no }}</td>
          <td>{{ props.item.tool_name }}</td>
          <td class="base-correction-xval text-right">
            {{ Number(props.item.base_correction_xval).toFixed(3) }}
             / 
            {{ Number(props.item.base_correction_zval).toFixed(3) }}
          </td>
          <td class="correction-value text-right">
            {{ Number(Trans(props.item.correction_xval, props.item.base_correction_xval)).toFixed(3) }}
             / 
            {{Number(Trans(props.item.correction_zval, props.item.base_correction_zval)).toFixed(3)}}
          </td>
          <td class="set_count_val text-right">
            {{Trans(props.item.count,0) }} / {{ props.item.set_count_val}}
          </td>
          <td class="manage text-center">
            <v-btn 
              prepend-icon="mdi-reset"
              class="manage__reset manage-button"
              @click.stop="resetItem(props.item)"
              fab text
            >
              <v-icon size="77%">$miniRefresh</v-icon>
            </v-btn>
            <v-btn 
              prepend-icon="mdi-delete"
              class="manage__delete manage-button"
              @click.stop="deleteItem(props.item.id)"
               fab text
            >
              <v-icon size="74%">$miniClose</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

    </v-data-table>

    <!-- 라인 추가 폼 -->
    <v-form 
      method="post" action="url" ref="insert_form"
      class="insert_form" :class="{ 'add_line_mode' : add_row.mode }"
    >

      <v-btn ref="addLineBtn" class="add_line_btn" fab medium dark @click="turnAddLine">
        <v-icon v-if="!add_row.mode">mdi-chevron-up</v-icon>
        <v-icon v-if="add_row.mode">mdi-chevron-down</v-icon>
      </v-btn>

      <template v-if="add_row.mode">

        <div class="field" @keyup.enter="add_row.type == 'add' ? addLine() : modifyLine();">
          <v-text-field
            class="field_text insert_process_no"
            v-model="insert_data.process_no"
            placeholder="공정명"
            hide-details
          ></v-text-field>

          <v-select 
            :items="type_list" 
            v-model="insert_data.type" 
            class="field_text insert_type"
            placeholder="용도"
            hide-details
          >
            <template v-slot:append>
              <v-icon >mdi-chevron-down</v-icon>
            </template>
          </v-select>

          <v-text-field
            class="field_text insert_tool_no"
            v-model="insert_data.tool_no"
            placeholder="번호"
            hide-details
          ></v-text-field>

          <v-text-field
            class="field_text insert_tool_name"
            v-model="insert_data.tool_name"
            placeholder="인서트 종류"
            hide-details
          ></v-text-field>

          <v-text-field
            class="field_text insert_set_count_val"
            placeholder="교체주기(수율)"
            type="number"
            v-model="insert_data.set_count_val"
            hide-details
          ></v-text-field>

        </div>

        <div class="fn_buttons">
          <v-btn v-if="add_row.type == 'add'" 
            class="btn add_btn" 
            dark large depressed
            @click="addLine"
          >
            추가
          </v-btn >
          <v-btn v-if="add_row.type == 'modify'" 
            class="btn add_modify" 
            dark large depressed
            @click="modifyLine"
          >
            수정
          </v-btn>
          <v-btn v-if="add_row.type == 'modify'"
            class="btn add_modify-cancle"
            dark large depressed
            @click="modifyLineCancle"
          >
            취소
          </v-btn> 
        </div>

      </template>

    </v-form>

    <v-dialog
      v-model="reach_count.open"
      persistent
      content-class="dialog_reach_count"
    >
      <div class="reach_count">
        <div class="content"><span class="bold">{{this.overtool}}</span>번 공구를 교체해주세요.</div>
        <div class="btns">
          <v-btn 
            class="reach_count_okay_btn" 
            x-large
            @click="closeReachCount"
          >
            확인
          </v-btn>
        </div>

      </div>
    </v-dialog>
  </div>
</template>

<script>
import commonHeader from "../components/commonHeader.vue";

export default {
  components: {
    commonHeader
  },
	name: 'tool',
	data( ) {
    return {
      header: [
        { 
          text: '공정명', 
          value: 'process_no', 
          sortable: false, 
          align: 'left',
          width: '12%',
        },
        { 
          text: '용도', 
          value: 'type', 
          sortable: false, 
          align: 'left',
          width: '12%',
        },
        { 
          text: '번호', 
          value: 'tool_no', 
          sortable: false, 
          align: 'left',
          width: '10%',
        },
        { 
          text: '인서트 종류', 
          value: 'tool_name', 
          sortable: false, 
          align: 'left',
        },
        { 
          text: '초기값(X/Z)', 
          value: 'base_correction_xval', 
          sortable: false, 
          align: 'right',
          width: '4%',
        },
      
        { 
          text: '보정값(X/Z)', 
          value: 'correction_val', 
          sortable: false, 
          align: 'right',
          width: '4%',
        },
        { 
          text: '수율', 
          value: 'set_count_val', 
          sortable: false, 
          align: 'right',
          width: '10%',
        },
     
        { 
          text: '관리', 
          value: 'actions', 
          sortable: false, 
          align: 'center',
          width: '8vw',
        },
      ],
      data: [],
      processList:[],                               // 실질적으로 보여지는 데이터
      machine_list:[],
      selected_machine:{},
      insert_data: {  
        ent:null,
        mid:null, 
        lot_no:null ,       
        process_no: 'N',
        type: null,
        tool_no: 'T',
        tool_name: null,
        set_count_val: null,
      },
      template: {                       // 하단 추가 줄 리셋을 위한 템플릿
        process_no: 'N',
        type: null,
        tool_no: 'T',
        tool_name: null,
        count: 0,
        set_count_val: null,
      },
      type_list : [
        '외경 황삭', '외경 중삭', '외경 정삭',
        '내경 황삭', '내경 중삭', '내경 정삭',
        '날개 황삭', '날개 정삭', 
        '볼자리 황삭', '볼자리 정삭',
        '홈',
        '후가공',
        '드릴'
      ],
      reach_count: {                   // 알람 dialog 컨트롤
        open: false,
      },
      
      add_row: {       
        mode: true,                   // 하단 추가 줄 열고 닫기
        type: 'add',                  // 하단 추가 줄 유형 (추가 or 수정)
      },
      lot:Number,
      row:Number,
      mid:Number,
      array:[],
      seq:0,
      count:Number,
      status:String,
      toolmap:{
        correction_xval:0,
        base_correction_xval:0,
        correction_zval:0,
        base_correction_zval:0
      },
      overtool:'',
      index:Number,
      selectedRow:null,
    }
  },
  mounted() {
    this.mid = this.$store.state.selectedCnc.mkey;
    this.$cnc.setCncList(this.processList);
    this.selectLot();
    this.loadCNC();
    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti');
    this.$cnc.state=2;
    this.$cnc.start(this.mid);
    this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse(this.mid);
    this.$cnc.toolCount((val)=>{
      this.dataInsert();
    });
    this.$cnc.toolReset((index,xval,zval,set_count_val)=>{
      if(this.index){
        this.data[index].count=0;
        this.data[index].correction_xval = xval;
        this.data[index].correction_zval = zval;
        this.data[index].set_count_val = set_count_val;
      }
    });
    this.$cnc.requestHandler((resp)=>{
      this.requestHandler(resp);
    });

    document.documentElement.style.overflow = "auto";
  },
  watch:{ 
    lot :function(val){
      this.insert_data.lot=val;
      this.toolData();
    },
    selected_machine:function(v){
      this.callfunc(v,"rdtofsr");
    },
  },
  destroyed:function(){
		this.$disconnect();
    this.$cnc.stop();
    
  },
  methods: {
    loadCNC:function(){
      this.$store.state.overlay=true;
      this.$http.APIS.get('/api/cloud/active_selectMachine/'+this.mid).then(r =>{
        this.selectedMachine =r.data.data[0] 
      });
      this.$store.state.overlay=false;
    },
    reset(){
      this.add_row.type='add';
      this.insert_data=this.template;
    },
    Trans(val,base){
      if(val==undefined){
        return base;
      }else{
        return val;
      }
    },
    async dataInsert(){
      await this.$http.DT.get('/tool/'+this.mid).then(r=>{
        this.seq = r.data[0].seq;
      });
      this.status = 'dataInsert'
      this.callfunc(this.selectedMachine,"rdtofsr");
    },
    checkCount(){
      for(let i=0; i<this.data.length; i++){
        if(this.data[i].count >= this.data[i].set_count_val){
          let toolno = 'T'+this.data[i].tool_no.slice(-2);
          this.overtool = this.overtool.indexOf(toolno) == -1 ? this.overtool.length == 0 ? toolno : this.overtool.concat(',',toolno) : this.overtool;
        }
      }
      if(this.overtool.length != 0){
        this.reach_count.open = true;
      }
    },
    requestHandler:async function(resp){
      if("CALL_FUNC_RESULT" == resp.response){
        if(resp.request.func == "rdtofsr"){
          var line = resp.data.split("^");
          var toolval=line[1].split("#");
          this.toolmap.correction_xval=(parseInt(toolval[1])/1000).toFixed(3);
          this.toolmap.correction_zval=(parseInt(toolval[3])/1000).toFixed(3);
          if(this.status == 'dataInsert'){
            for(let i=0; i<this.data.length; i++){
              let toolno= this.data[i].tool_no.slice(-2).substring(0,1) == 0 ? this.data[i].tool_no.slice(-1): this.data[i].tool_no.slice(-2);
              toolval = line[toolno].split("#");
              this.data[i].count += 1;
              this.data[i].hist_seq = this.seq;
              this.data[i].correction_xval = (parseInt(toolval[1])/1000).toFixed(3);
              this.data[i].correction_zval = (parseInt(toolval[3])/1000).toFixed(3);
            }
            await this.$http.DT.post('/tool/data',this.data)
            this.checkCount();
          }else if(this.status=='Baseinsert'){
            this.insert_data.base_correction_xval = (parseInt(toolval[1])/1000).toFixed(3);
            this.insert_data.base_correction_zval = (parseInt(toolval[3])/1000).toFixed(3);
            this.insertTool();
          }else if(this.status == 'reset'){
            let datamap = [];
            datamap[0] = this.data[this.index];
            // this.data[this.index].count=0;
            this.data[this.index].correction_xval = this.toolmap.correction_xval;
            this.data[this.index].correction_zval = this.toolmap.correction_zval;
            let reset_data = {};
            reset_data.index = this.index;
            reset_data.xval = this.toolmap.correction_xval;
            reset_data.zval = this.toolmap.correction_zval;
            reset_data.set_count_val = this.count;
            this.$http.DT.post('/tool/data',datamap);
            this.$http.EDGE.post('/api/edge/broadcast',{enterprise:'YHS',data:{type:'toolChange',reset_data}});
          }
        }
          this.status=String;
      }
    },
    callfunc:function(item,func,arg){
      this.$store.state.overlay = true;
      item.func = func;
      item.no = arg;
      this.$http.EDGE.post('/api/edge/callfunc',item);
    },
    async toolData(){
      await this.$http.DT.get('/tool/'+this.$store.state.enterprise_id+'/'+this.mid+'/'+this.lot).then(r=>{
        r.data.forEach((item) => {
          if(item.correction_xval){
            item.correction_xval = item.correction_xval.toFixed(3);
          }
          if(item.correction_zval){
            item.correction_zval = item.correction_zval.toFixed(3);
          }
        });
        this.data = r.data;
      })
      this.checkCount();
    },
    async insertTool(){
      this.insert_data.lot_no = this.lot;
      this.insert_data.ent = this.$store.state.enterprise_id;
      this.insert_data.mid = this.mid;
      await this.$http.DT.post('/tool',this.insert_data).then(r=>{
        this.resetAddLine();
      })
      this.$http.EDGE.post('/api/edge/broadcast',{enterprise:'YHS',data:{type:'reload',mkey:this.mid}});
    },
    selectLot(){
      this.$http.DT.get('/tool/'+this.$store.state.enterprise+'/'+this.mid).then(r=>{
        this.lot=r.data[0].lot;
      });
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) { window.cancelAnimationFrame(rAftimeout); }
        rAftimeout = window.requestAnimationFrame(function() {
          cb();
        });
      };
    },
    turnAddLine() { // 하단 추가 줄 열고 닫기
      this.add_row.mode = !this.add_row.mode;
      this.modifyLineCancle();
    },
    addLine() { // 하단 추가 줄에서 한 줄 추가
      this.add_row.type = 'add';
      this.status = 'Baseinsert';
      this.selectLot();
      this.callfunc(this.selectedMachine,"rdtofsr",this.insert_data.tool_no.slice(-2));
    },
    async modifyLine() { // 하단 추가 줄에서 선택한 줄을 수정
      this.add_row.type = 'add';
      await this.$http.DT.patch('/tool/',this.insert_data).then(r=>{
        this.resetAddLine();
      });
      this.$http.EDGE.post('/api/edge/broadcast',{enterprise:'YHS',data:{type:'reload',mkey:this.mid}});
    },
    modifyLineCancle() { // 선택한 줄 수정 취소
      this.selectedRow = null;
      this.resetAddLine();
    },
    resetAddLine() { // 하단 줄 추가 모드, 값 처음 값으로 리셋
      this.insert_data = this.template;
      this.add_row.type = 'add';
    },
    async deleteItem(item) { // 목록에서 한 줄 삭제
      let pardon = confirm('정말 삭제하시겠습니까?');
      if(pardon){
        await this.$http.DT.delete('/tool/'+item);
        this.$http.EDGE.post('/api/edge/broadcast',{enterprise:'YHS',data:{type:'reload',mkey:this.mid}});
      }
    },
    async resetItem(item) { // 목록에서 한 줄 리셋 (초기값, 보정값, 가공갯수만 해당)
      let index = this.data.indexOf(item);
      this.status='reset';
      this.index = index;
      this.count = this.data[index].count;
      this.data[index].set_count_val = this.data[index].count;
      await this.$http.DT.patch('/tool/re/',{set_count_val:this.data[index].count,id:this.data[index].id})
      this.callfunc(this.selectedMachine,"rdtofsr",this.data[index].tool_no.slice(-2));
      this.data[index].count = 0;
      await this.resetAddLine();
    },
    closeReachCount() { // 공구 교체 팝업 닫기
      this.reach_count.open = false;
      this.overtool = '';
    },
    rowSelect(item) {
      this.selectedRow = item.id;
      this.add_row.mode = true;
      this.add_row.type = 'modify';
      this.insert_data = Object.assign({}, item);
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/global.scss';

  ::v-deep html {
    overflow: auto;
  }
  
  #tool_change_manage {
    @include flex(column, nowrap, stretch, center);

    height: 100% !important;
    padding: vh(65) 0 vh(95);
  }
  .header {
    @include flex(row, nowrap, space-between, flex-end);

    width: 100%;
    height: vh(120);
    flex: 0 0 auto;
    // font-family: $ptd;
    padding: 0 vw(40) vh(24);
    background-color: white;

    .mid_name {
      font-size: vw(50);
      line-height: 0.8;
      font-weight: 700;
    }
    .complete_btn {
      justify-self: flex-end;
      margin-left: auto;
      font-size: vw(24);
      background: #323232;
    }
  }
  // 실제 보여지는 목록
  .tool-info-list-wrap {
    width: 100%;
    max-height: calc( 100% - #{vh(120)} );
    overflow: hidden;
    flex: 1 1 0;
      
    ::v-deep .v-data-table__wrapper {
      max-height: 100% !important;
      overflow-y: auto;
    }

    table {
      height: 100%;
      margin-bottom: 80px;
      border-bottom: 1px solid #eeeeee;
    }
    ::v-deep .v-data-table-header {
      
      th {
        height: vh(45);
        font-size: vw(20);
        // font-family: $ptd;
        font-weight: 500;
        background: #c4c4c4!important;

        &:first-child {
          padding-left: vw(45);
        }
        &:nth-child(5) {
          padding-right: vw(48);
        }
        &:last-child {
          padding-right: vw(45);
        }
      }
      span {
        display: inline-block;
        color: black;
      }
    }
      
    tbody {
      
      tr {
        cursor: pointer;
      }

      td {
        min-height: vh(64) !important;
        text-align: left;
        font-size: vw(32) !important;
        vertical-align: middle;
        padding: 0 12px !important;
        border-bottom: thin solid rgba(#718188, 0.5) !important;

        &:first-child {
          padding-left: vw(45) !important;
        }
        &:last-child {
          padding-right: vw(45) !important;
        }

        &.selected {
          background-color: $pale !important;
        }

        &.text-center { text-align: center; }
        &.text-right { text-align: right; }

        &.set_count_val {
          font-weight: bolder;
        }
        &.base-correction-xval,
        &.correction-value {
          min-width: fit-content;
          white-space: nowrap;
        }
        &.base-correction-xval {
          padding-right: vw(48) !important;
        }
      }
    }

    ::v-deep .manage {
      height: 100% !important;
      min-height: vh(64) !important;
      padding-right: vw(45) !important;
      border-bottom: thin solid rgba(#718188, 0.5) !important;

      .manage-button {
        display: inline-block;
        width: vh(30);
        height: vh(30);
        background-color: #323232;
      }
    }
    .manage__reset {
      margin-right: vw(4);
      font-size: 1.2vw;
      color: white;
      transition: all 0.3s;
    }
    .manage__delete {
      color: white;
    }
    &.tool_info_list_wrap { // table
      width: 100%;
      height: 100%;
    }
  }

  // 추가하기 위한 폼
  .insert_form {
    @include flex(row, nowrap, stretch, center);

    width: 100%;
    padding: 0 vw(40) 0 vw(120);
    height: vh(90);
    position: fixed;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    background: #c4c4c4;

    .add_line_btn {
      width: vw(52);
      height: vw(52);
      position: absolute;
      top: 0;
      left: vw(40);
      transform: translate(0, -50%);
      transition: all 0.5s;
      margin-left: 8px;
      background-color: #323232 !important;
    }
    ::v-deep .field {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: vh(60);
      gap: vw(14);
      flex: 1 1 auto;
      
      .v-text-field { 
        padding-top: 0;
        font-size: vw(24) !important;
        background-color: white;

        &.v-input--is-readonly {
          opacity: 0.3;
        }
        .v-select__selection--comma {
          overflow: visible !important;
        }
      }
      .v-input__slot {

        &::after,
        &::before {
          display: none !important;
        }
      }
      .v-select__slot,
      .v-text-field__slot {
        
        input {
          height: vh(60);
          max-height: unset;
          padding: vh(8) vw(16);
        }
        label {
          font-size: vw(24);
          top: 50%;
          left: vw(16) !important;
          padding-top: vh(1);
          transform: translate(0, -50%);
        }
        .v-input__append-inner {
          margin: vh(18) vw(8) 0 0;
        }
      }

      .insert_type {
        
        .v-select__selections {
          padding-left: vw(16);
        }
      }
    }
    .fn_buttons {
      padding-left: vw(14);
      flex: 0 0 auto;

      .btn {
        width: vw(105);
        height: vh(56);;
        font-size: vw(24)!important;
        // font-family: $ptd;
        font-weight: 600;
      }
      .add_btn {
        background-color: #323232 !important;
      }
      // .add_modify {
      // }
      .add_modify-cancle {
        margin-left: vw(14);
      }
    }
    &.add_line_mode {
      .add_line_btn {
        top: 50%;
        transition: all 0.5s;
      }
    }
  }
  // 공구 번호 교체 경고창
  ::v-deep .dialog_reach_count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    background: linear-gradient(-45deg, lightcoral, coral, tomato, red);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    .reach_count {
      padding: 16px;
      .content {
        text-align: center;
        padding: 32px 0;
        font-size: 64px;
        color: white;
        
      }
      .btns {
        text-align: center;
 
        .reach_count_okay_btn {
          font-size: 32px;
          padding-top: 4px;
          // background-color: cornflowerblue !important;
        }
      }
    }
  }
</style>