<template>
  <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.77 26H1V1H18.77V6.97H15.96V3.8H3.8V23.2H15.96V20.03H18.77V26Z" fill="white" stroke="#323232" stroke-width="0.5" stroke-miterlimit="10"/>
  <path d="M22.28 7.66998L20.41 9.75998L23.03 12.1H11.32V14.9H23.03L20.41 17.24L22.28 19.33L28.8 13.5L22.28 7.66998Z" fill="white" stroke="#323232" stroke-width="0.5" stroke-miterlimit="10"/>
  </svg>
</template>

<script>
export default {
  name: 'logout',
}
</script>

<style scoped lang="scss">
  .cls-1 {
    fill:#040000;
  }
  .cls-2 {
    fill:#231815;
  }
</style>