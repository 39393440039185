<template>
  <div id="monitor-setting" class="monitor-setting">
    <div class="monitor-setting__header">
      <h2 class="header__title">모니터 관리</h2>
    </div>

    <div class="monitor-setting__content">

      <ul class="monitor-list">
        <!-- monitor -->
        <li 
          class="monitor"
          v-for="monitor in monitor_data"
          :key="monitor.name"
        >
          <div class="monitor__header">
            <p class="monitor-name">{{ monitor.name }}</p>
            <v-btn 
              class="monitor-modify header__btn"
              fab tile x-small text
              @click="modifyMonitor(monitor.name,monitor.id)"
            >
              <v-icon size="1.3vw">$modify</v-icon>
            </v-btn>
            <v-btn 
              class="monitor-delete header__btn"
              fab x-small text
              @click="deleteMonitor(monitor.id,monitor.name)"
            >
              <v-icon size="1.5vw">$monitorRemove</v-icon>
            </v-btn>
          </div>
          <div class="machine-list">
            <draggable 
              v-model="monitor.machine"
              v-bind="drag_options" 
              class="machine-draggable"
              draggable=".drag-item"
            >

              <!-- machine -->
              <div 
                class="machine drag-item"
                v-for="machine in monitor.machine"
                :key="machine.item_id"
              >
                <div class="machine__content">
                  <p class="machine__no">{{ machine.machine_no }}</p>
                  <p class="machine__mid">{{ machine.mid }}</p>
                </div>
                <div class="machine__actions">
                  <v-btn 
                    class="machine-modify btn"
                    @click="modifyMachine(machine,monitor.id)"
                    fab text
                  >
                    <v-icon size="1vw">$modify</v-icon>
                  </v-btn>
                  <v-btn 
                    class="machine-delete btn"
                    @click="deleteMachine(machine.item_id,machine.mid)"
                    fab text
                  >
                    <v-icon size="0.833vw">$deleteDefault</v-icon>
                  </v-btn>
                </div>
              </div>
              <!-- machine -->
              <div 
                v-if="monitor.machine.length < 4"
                class="machine machine-add"
                @click="addMachine(monitor.id,monitor.machine.length)"
              >
                <v-btn 
                  class="machine-add-btn"
                  block text
                >
                </v-btn>
              </div>

            </draggable>

          </div>

        </li>
        <!-- monitor -->
        <li class="monitor monitor-add">
          <v-btn 
            class="monitor-add-btn"
            block tile depressed
            @click="addMonitor"
          >
            <v-icon size="1.5vw">$monitorAdd</v-icon>
          </v-btn>
          <div class="monitor__header">
            <p class="monitor-name">MONITOR ADD</p>
          </div>
          <div class="machine-list">
            <div class="machine-draggable">
            </div>
          </div>
        </li>
      </ul>

    </div>

    <!-- 기계 추가 -->
    <v-dialog
      content-class="dialog-basic add-machine-dialog"
      v-model="add_machine.dialog"
      persistent
    >
      <v-card class="elevation-0">
        <h4 
          class="dialog-title" 
          v-text=" add_machine.mode == 'add' ? '기계 추가' : add_machine.mid + ' 수정'"
        ></h4>
        <div class="dialog-content">
          <select name="machine-list" class="machine-list-select" v-model="select_mid">
            <option value="" default selected disabled>기계명</option>
            <option 
              v-for="machine in add_machine_data"
              :key="machine.id"
              :value="machine.id"
            >{{ machine.name }}</option>
          </select>
        </div>
        <v-card-actions class="dialog-actions">
          <v-spacer></v-spacer>
          <div v-if=" add_machine.mode == 'modify'">
          <span >순 서</span>
          <v-text-field v-model='add_machine.ord'></v-text-field>
          </div>
          <v-btn 
            class="complete-add-machine btn"
            @click="completeAddMachine"
            depressed large
          >확인</v-btn>
          <v-btn 
            class="cancle-add-machine btn"
            @click="cancleAddMachine"
            depressed large
          >취소</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 모니터 추가 -->
    <v-dialog
      content-class="dialog-basic add-monitor-dialog"
      v-model="add_monitor.dialog"
      persistent
    >
      <v-card class="elevation-0">
        <h4 
          class="dialog-title" 
          v-text=" add_monitor.mode == 'add' ? '모니터 추가' :  add_monitor.name + ' 수정'"
        >
        </h4>
        <div class="dialog-content">
          <v-text-field
            v-model="add_monitor.name" 
            class="elevation-0"
            label="모니터 이름"
            @keyup.enter="completeAddMonitor"
            hide-details outlined
          ></v-text-field>
        </div>
        <v-card-actions class="dialog-actions">
          <v-spacer></v-spacer>
          <v-btn 
            class="complete-add-machine btn"
            @click="completeAddMonitor"
            depressed large
          >확인</v-btn>
          <v-btn 
            class="cancle-add-machine btn"
            @click="cancleAddMonitor"
            depressed large
          >취소</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'monitorSetting',
  components: {
    draggable,
  },
  data() {
    return {
      select_mid: Number,
      monitor_sub:[],
      monitor_data : [
        {
          name: 'monitor1',
          machine: [
            {
              mid: 'Puma280',
              mkey: 1,
              machine_no:1,
            },
            {
              mid: 'Lynx220',
              mkey: 6,
              machine_no:2,
            },
          ],
        },
        {
          name: 'monitor2',
          machine: [
            {
              mid: 'Puma240',
              mkey: 3,
              machine_no:3,
            },
            {
              mid: 'Lynx220LC',
              mkey: 5,
               machine_no:4,
            },
            {
              mid: 'Lynx520LC',
              mkey: 7,
               machine_no:5,
            },
            {
              mid: 'Lynx520LX',
              mkey: 11,
               machine_no:6,
            },
          ],
        }
      ],
      add_machine: {
        dialog: false,
        mid: undefined,
        mon_id:undefined,
        item_id:undefined,
        mode: 'add',
        ord:undefined,
      },
      add_machine_data: [
        { name: 'here', mkey: 1, },
      ],
      add_monitor: {
        dialog: false,
        mode: 'add',
        name: undefined,
        id:undefined,
      },
      setting: {
        edit: true,
      },
      mon_list:[],
    };
  },
  computed: {
    drag_options() {
      return {
        disabled: this.setting.edit,
      }
    }
  },
  mounted:function(){
    this.monList();
    this.getSelect();
    // this.getMonitor();

  },
  watch:{
    // 'select_mid':function(v){
    //   console.log(v);
    // }
  },
  methods: {
    getSelect(){  
      this.$http.DT.get('/alarm/').then(r=>{
        this.add_machine_data=r.data;
      })
    },
    getMonitor(){
      this.$http.DT.get('/admin/').then(r=>{
        this.monitor_data=[];
        // var mon ={name:'',id:"",machine:[]};
        for(let i = 0; i < this.mon_list.length; i++){
          this.mon_list[i].machine=[];
          for(let j=0; j<r.data.length; j++){
              if(this.mon_list[i].name==r.data[j].monitor){
                this.mon_list[i].machine.push({mid:r.data[j].mid,mkey:r.data[j].mkey,machine_no:r.data[j].machine_no,ord:r.data[j].ord,item_id:r.data[j].item_id});
              }
          }
        }
        this.monitor_data=this.mon_list;
       // if(r.data){
        //   for(let i = 0; i < r.data.length ; i++){
        //     if(i==0){
        //       mon.name=r.data[i].monitor;
        //       mon.id = r.data[i].id ;
        //       mon.machine.push({mid:r.data[i].mid,mkey:r.data[i].mkey,machine_no:r.data[i].machine_no,ord:r.data[i].ord});
        //     }
        //     else if(i == r.data.length-1){
        //       if(mon.name == r.data[i].monitor)
        //       mon.machine.push({mid:r.data[i].mid,mkey:r.data[i].mkey,machine_no:r.data[i].machine_no,ord:r.data[i].ord});
        //       this.monitor_data.push(mon);
        //     }
        //     else{
        //       if(mon.name == r.data[i].monitor){
        //         mon.machine.push({mid:r.data[i].mid,mkey:r.data[i].mkey,machine_no:r.data[i].machine_no,ord:r.data[i].ord});
        //       }else{
        //         this.monitor_data.push(mon);
        //         mon={name:'',id:"",machine:[]};
        //         mon.name = r.data[i].monitor;
        //         mon.id = r.data[i].id ;
        //         mon.machine.push({mid:r.data[i].mid,mkey:r.data[i].mkey,machine_no:r.data[i].machine_no,ord:r.data[i].ord});
        //       }
        //     }
        //   }
        // }
      })
    },
    monList(){
      this.$http.DT.get('/admin/mon').then(r=>{
        if(r.data) this.mon_list=r.data;
        this.getMonitor();
      })
    },
    addMachine(v,ord) {
      this.add_machine.mode = 'add';
      this.add_machine.mon_id = v ;
      this.add_machine.ord = ord + 1;
      this.add_machine.dialog = true;
    },
    modifyMachine(machine,monitor_id) {
      this.select_mid = machine.mkey;
      this.add_machine.mode = 'modify';
      this.add_machine.mid = machine.mid;
      this.add_machine.item_id = machine.item_id;
      this.add_machine.ord = machine.ord;
      this.add_machine.dialog = true;
    },
    async completeAddMachine() {
      this.add_machine.mkey=this.select_mid;
      if(this.add_machine.mode=='add'){
        await this.$http.DT.post('/admin/insert_item',this.add_machine);
      }else{
        await this.$http.DT.patch('/admin/update_item',this.add_machine);
      }  
      await this.monList();
      this.add_machine.dialog = false;
    },
    cancleAddMachine() {
      this.add_machine.dialog = false;
    },
    addMonitor() {
      this.add_monitor.mode = 'add';
      this.add_monitor.dialog = true;
    },
    modifyMonitor(name,id) {
      this.add_monitor.mode = 'modify';
      this.add_monitor.name = name;
      this.add_monitor.id = id;
      this.add_monitor.dialog = true;
    },
    async completeAddMonitor() {
      if(this.add_monitor.mode=='add'){
        await this.$http.DT.post('/admin/',this.add_monitor);
      }else{
        await this.$http.DT.patch('/admin/',this.add_monitor);
      }
      this.cancleAddMonitor();
      await this.monList();
    },
    cancleAddMonitor() {
      this.add_monitor.name = undefined;
      this.add_monitor.dialog = false;
    },
    async deleteMonitor(id,name){
      if(confirm(name+'을 삭제하시겠습니까?')){
        await this.$http.DT.delete('/admin/'+id);
        await this.monList();
      } 
    },
    async deleteMachine(id,mid){
      if(confirm(mid+'를 삭제하시겠습니까?')){
        await this.$http.DT.delete('/admin/delete_item/'+id);
        await this.monList();
      }

    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/global.scss";

  $bg-color: #f7f8f8;

  *, span, p { font-family: $scd; }

  .monitor-setting {
    @extend .flex_column;

    justify-content: stretch;
    height: 100%;
    background-color: $bg-color !important;

    &__header {
      width: 100%;
      flex: 0 0 auto;
      padding: 0 map-get($vw, 64);

      .header__title {
        text-align: center;
        font-family: $scd;
        font-size: 26px;
        font-weight: 600;
        padding: 42px 0 18px;
        margin-bottom: map-get($vw, 50);
        border-bottom: 1px solid $table_black;
      }
    }

    &__content {
      @include scroll-preset($bg-color: $bg-color);

      width: 100%;
      flex: 1 1 0;
      padding: 0 map-get($vw, 64) map-get($vw, 50);
      overflow-y: scroll;
    }
    .monitor-list {
      @extend .flex_row;
      
      justify-content: center;
      flex-wrap: wrap;
      gap: map-get($vw, 18);

      .monitor {
        width: vconv(1920, 370);
        position: relative;
        background-color: #eeeeef;

        .monitor__header {
          @extend .flex_row;

          height: map-get($vw, 78);
          padding: map-get($vw, 24);
          border-bottom: 1px solid $table_black;

          .header__btn {
            width: 1.5vw;
            height: 1.5vw;
          }

          .monitor-name {
            font-size: map-get($vw, 26);
            line-height: 0.8;
            padding-top: map-get($vw, 4);
            font-weight: 600;
          }
          .monitor-modify {
            margin-left: map-get($vw, 8);
          }
          .monitor-delete {
            justify-self: flex-end;
            margin-left: auto;
          }
        }

        .machine-list {

          .machine-draggable {
            display: grid;
            grid-template-rows: repeat(4, map-get($vw, 130));
            padding: map-get($vw, 12);
            gap: map-get($vw, 12);

            .machine {
              @extend .flex_row;

              justify-content: stretch;
              cursor: move;
              background-color: white;

              &.machine-add {
                cursor: default;

                .machine-add-btn {
                  height: 100%;
                  background: url(../assets/img/monitor_add.svg) no-repeat center;
                  background-size: 1.25vw auto;

                  &:focus {

                    &::before {
                      opacity: 0.7;
                    }
                  }
                  &::before {
                    background-color: #777777;
                  }
                }
              }
            }
            .machine__content {
              @extend .flex_row;

              flex: 1 1 auto;
              padding-left: map-get($vw, 32);

              .machine__no {
                width: map-get($vw, 52);
                font-weight: 300;
                font-size: map-get($vw, 32);
              }
              .machine__mid {
                font-weight: 600;
                font-size: map-get($vw, 26);
              }
            }
            .machine__actions {
              flex: 0 0 auto;
              height: 100%;
              padding: map-get($vw, 8);

              .btn {
                width: map-get($vw, 32);
                height: map-get($vw, 32);
              }
            }
          }
        }

        .monitor-add-btn {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #eeeeef;
        }
      }
    }
  }

  ::v-deep .dialog-basic {
    width: 350px !important;
    font-family: $scd !important;

    .v-card {
      padding: map-get($vw, 32) map-get($vw, 24);
      background-color: #d3d3d4;
    }

    .dialog-title {
      font-size: map-get($vw, 26);
      font-weight: 600;
      text-align: center;
      margin-bottom: map-get($vw, 32);
    }
    .dialog-content {
      margin-bottom: map-get($vw, 32);

    }
    .dialog-actions {
      padding: 0 !important;

      .btn {
        padding-top: map-get($vw, 4);
        font-size: map-get($vw, 21);
        font-weight: 500;
        background-color: #eeeeef;

        & + .v-btn {
          margin-left: map-get($vw, 16) !important;
        }
      }
    }
  }
  ::v-deep .add-machine-dialog {
    
    .machine-list-select {
      width: 100%;
      font-size: map-get($vw, 21);
      padding: map-get($vw, 15) map-get($vw, 16) map-get($vw, 12); 
      border-radius: map-get($vw, 5);
      background: white url(../assets/img/tri_down.svg) no-repeat right map-get($vw, 12) center !important;  
      background-size: map-get($vw, 16) auto !important;

      option {
        font-size: map-get($vw, 21);
      }
    }
  }
  ::v-deep .add-monitor-dialog {
    
    .v-card {
      background-color: white;
    }

    .v-input__slot {
      box-shadow: 0 !important;
    }
  }
</style>