<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.81 21.39"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M20.67 2.14H18.53V1.07a1.07 1.07.0 1 0-2.13.0V2.14h-10V1.07a1.07 1.07.0 1 0-2.13.0V2.14H2.14A2.14 2.14.0 0 0 0 4.28v15a2.15 2.15.0 0 0 2.14 2.14H20.67a2.15 2.15.0 0 0 2.14-2.14v-15A2.14 2.14.0 0 0 20.67 2.14zm0 17.11H2.14V7.13H20.67z"/><path class="cls-1" d="M19.25 17.82V15.68H16.4 15.33V17.82H16.4 19.25z"/><path class="cls-1" d="M9.44 15.68h3.92v2.14H9.44z"/><path class="cls-1" d="M3.56 15.68h3.92v2.14H3.56z"/><path class="cls-1" d="M19.25 14.26V12.12H16.4 15.33V14.26H16.4 19.25z"/><path class="cls-1" d="M9.44 12.12h3.92v2.14H9.44z"/><path class="cls-1" d="M3.56 12.12h3.92v2.14H3.56z"/><path class="cls-1" d="M19.25 10.69V8.55H16.4 15.33V10.69H16.4 19.25z"/><path class="cls-1" d="M9.44 8.55h3.92v2.14H9.44z"/><path class="cls-1" d="M3.56 8.55h3.92v2.14H3.56z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'productCalendar',
  data() {
    return {
      width: 24,
      height: 24,
    };
  }
});
</script>

<style scoped>
  .cls-1{
    fill:#839cc5;
  }
</style>