<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.63 22.93"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M9.32.0C6.75.0 4.66 1.59 4.66 5.1s2.15 6.37 4.66 6.37S14 8.6 14 5.1 11.89.0 9.32.0zM3.9 13.77a16.48 16.48.0 0 1 5.42-.87 16.42 16.42.0 0 1 5.41.87 7.31 7.31.0 0 1 3.65 2.61 1.44 1.44.0 0 1 .25.82v4.3a1.43 1.43.0 0 1-1.43 1.43H1.43A1.43 1.43.0 0 1 0 21.5V17.21a1.5 1.5.0 0 1 .24-.81A7.31 7.31.0 0 1 3.9 13.77z"/></g></g></svg>
</template>

<script>
export default {
  name: 'loginId',
}
</script>

<style scoped lang="scss">
  .cls-1 {
    fill:#0346da;
    fill-rule:evenodd;
  }
</style>