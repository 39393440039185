<template>
  <div id="dashboard">
    <div class="board all-machine-list">
      <h2 class="board__title">전체 기계 현황</h2>
      <ul class="machine-list">
        <li 
          class="machine-item"
          v-for="item in processList"
          :key="item.mid + item.index"
        >
          <dashboardMachine :data="item"></dashboardMachine> 
        </li>
      </ul>
    </div>

    <div class="board today_production_quantity">
      <h2 class="board__title">금일 기계별 생산량</h2>
      <div class="production_quantity__wrap" :style="{ 'height': timeline.height + 'px', }">
        <loading 
          id="loading" 
          :active.sync="prod_quantity.loading" 
          :can-cancel="true" 
          color="#0346DA" 
          loader="dots" 
        ></loading>
        <apexchart
          ref="timeline"
          class="production_quantity"
          height="100%"
          :options="prod_quantity_options"
          :series="prod_quantity_data"
          >
        </apexchart>
      </div>
    </div>

    <div class="board recent_alarm">
      <h2 class="board__title">최근 알람</h2>
      <v-data-table
        :items="alarm_data"  
        :headers="gcodeHeaders"
        :options="{itemsPerPage:5}"
        class="recent_alarm_list rounded-0"
        hide-default-header
        hide-default-footer
      >
      </v-data-table>
    </div>

    <div class="board oee">
      <h2 class="board__title">설비 종합 효율</h2>
      <apexchart 
        type="area" 
        ref='areachart'
        height="270" 
        :options="chartOptions" 
        :series="series"
        class="oee_chart"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import moment from 'moment';
import dashboardMachine from '../components/dashboardMachine.vue';
import loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'dashboard',
  components:{
    apexchart, 
    // moment, 
    dashboardMachine,
    loading,
  },
  data() {
    return {
      series: [{
        name: 'XYZ MOTORS',
        data: [ 
          // [1327359600000,30.95],
          // [1327446000000,31.34],
          // [1327532400000,31.18],
          // [1327618800000,31.05],
          // [1327878000000,31.00],
          // [1327964400000,30.95],
        ]
      }],
      alarm_data:[],
      gcodeHeaders: [
        { 
          text: 'mid', 
          value: 'mid', 
          align: 'left',
        },
        { 
          text: 'date', 
          value: 'date', 
          width: 60,
        },
        { 
          text: 'message', 
          value: 'message', 
          align: 'left',
        },
      ],
      processList:[],
      timeline: {
        height: 300,
        type: 0,
      },
      prod_quantity_options: {
        chart: {
          type: 'rangeBar',
        },
        noData: {
          text: '데이터가 없습니다',
          align: 'center',
          verticalAlign: 'middle',
          offsetY: -12,
          style: {
            fontSize: '16px',
          }
        },
        colors: [ 
          ({seriesIndex, dataPointIndex, value, w}) => {
            const colors = this.prod_quantity.color;
            const colorchip = this.prod_quantity.colorchip;

            const data = w.config.series[seriesIndex];
            if(data.name == undefined) return colorchip[1];
            const mid = data.data[0].x;
            let idx = colors[mid].indexOf(data.name) + 1;
            const result = idx % 3;
            return colorchip[result];
          }
        ],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
            rangeBarGroupRows: true,
            dataLabels: {
              position: 'bottom',
            },
            // distributed: true, 
          }
        },
        fill: {
          type: 'solid',
        },
        xaxis: {
          type: 'datetime',
          labels:{
            style:{
              fontSize:'0.8vw',
            }
          }
        },
        yaxis: {
          labels: {
            align: 'left',
            style: {
              fontSize: '0.8vw',
            }
          }
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          formatter: function(val, opts) {
            let label = opts.w.config.series[opts.seriesIndex].name +': '+ (val[1]-val[0]);
            return label;
          },
        },
        grid: {
          show: true,
          borderColor: '#5e7295',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: true,
            }
          },
          row: {
            colors: ['white'],
            opacity: 1,
          },
          padding: {
            top: 0,
            right: 15,
            bottom: 0,
            left: 0,
          }
        },
        tooltip:{
          custom({series, seriesIndex, dataPointIndex, w}) {
            var data=w.config.series[seriesIndex].data;
            var count = data[0].y[1]-data[0].y[0];
            return '<div class="inner bold">' + w.config.series[seriesIndex].name + ':  '+ count+'개</div>';
          },
        }
      },
      prod_quantity: {
        color: {},
        colorchip: ['#D8E4EC', '#A5BBCB', '#758C9D'],
        loading: false,
      },
      prod_quantity_data: [],
      chartOptions:{ 
        chart: {
          id: 'area-datetime',
          type: 'area',
          height: 350,
          width: 350,
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            offsetY: -18,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
              customIcons: [],
            }
          }
        },
        annotations: {
        },
        dataLabels: {
          enabled: true,
          offsetY: -6,
          style: {
            fontSize: 16,
            colors: ['#898989'],
          },
          background: {
            enabled: false,
          }
        },
        markers: {
          size: 5,
          colors: ['#898989'],
          shape: 'circle',
          strokeWidth: 0,
          hover: {
            size: undefined,
            sizeOffset: 3,
          },
        },
        xaxis: {
          type: 'datetime',
          tickAmount:4,
          align: 'right',
          labels:{
            show:true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'yyyy-MM',
              day: 'yyyy-MM-dd',
              hour: 'HH:mm',
            },
          },
          
        },
        yaxis:{
          max:100,
          min:0
        },
        stroke: {
          show: true,
          surve: 'smooth',
          lineCap: 'butt',
          colors: ['#898989'],
          width: 2,
          dashArray: 0,
        },
        colors: ['#a5bbc6'],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 0.3,
            stops: [0, 100]
          }
        },
        grid: {
          show: true,
          borderColor: '#5e7295',
          strokeDashArray: 0,
          position: 'back',
          xaxis: {
            lines: {
              show: false,
            }
          },
          yaxis: {
            lines: {
              show: true,
            }
          },
          row: {
            colors: ['white'],
            opacity: 1,
          },
          padding: {
            top: 0,
            right: 10,
            bottom: 5,
            left: 20,
          }
        },
        tooltip: {
          custom({series, seriesIndex, dataPointIndex, w}) {
            const value = series[seriesIndex][dataPointIndex];
            const data = w.config.series[seriesIndex];
            const name = data.name;
            const date = moment(data.data[dataPointIndex][0]).format('YYYY-MM-DD');
            let tooltip = '<div class="oee_tooltip inner">';
            tooltip += '<p class="oee_tooltip__date">' + date + '</p>'
            tooltip += '<p class="oee_tooltip__value">' +  ' : ' + value +'%'+'</p>';
            tooltip += '</div>';
            return tooltip;
          },
        },
        noData: {
          text: '데이터가 없습니다',
          align: 'center',
          verticalAlign: 'middle',
          offsetY: -12,
          style: {
            fontSize: '16px',
          }
        },
      },
    };
  },
    mounted:function(){
    this.$store.state.overlay=false;
    this.getOperationRate();
    this.getAlarm();
    this.$cnc.setCncList(this.processList);
    this.$store.state.overlay=false;
    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti');
    this.$cnc.start();
    this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
    this.dayProduct();
  },
  destroyed:function(){
		this.$disconnect();
    this.$cnc.stop()
	},
  watch:{
  },
  computed:{},
  methods:{
    dayProduct(){
      this.prod_quantity.loading = true;
      this.$http.DT.get('/alarm/product').then(async r=>{
        const leng = r.data.length;
        const colors = this.prod_quantity.color;
        var stack_count;
        this.prod_quantity_data=[];
        
        if( leng == 0 ) {
          this.timeline.height = 200;
          document.querySelector('.production_quantity').classList.add('no-data');
        } else {
          document.querySelector('.production_quantity').classList.remove('no-data');
          this.timeline.height = 38 * leng + 70;
        }

        for( let i = 0; i < leng; i++ ){
          const item = r.data[i];
          const mid = item.mid;
          let program = item.program;
          

          if(program.indexOf('(') != -1){
            program = program.split('(')[1].replace(')','');
          }
          if( i!=0 && mid == r.data[i-1].mid){
            this.prod_quantity_data.push({name:program, data:[{x:mid,y:[ stack_count,stack_count + item.count]}]});
            stack_count += item.count;
          }else{
            stack_count=0;
            this.prod_quantity_data.push({name:program, data:[{x:mid ,y:[ 0,item.count]}]});
            stack_count=item.count;
          }

          // 그래프 색을 위한 변경
          if( colors[mid] == undefined ) {
            colors[mid] = [];
          }
          colors[mid].push(program);
        }
        this.prod_quantity.loading = false;
      });
    },
    getAlarm(){
      this.$http.DT.get('/alarm/recent').then(r=>{
        for( let i = 0; i<r.data.length; i++ ){
          r.data[i].date = r.data[i].date.replace('T',' ').substr(0,19);
        }
        this.alarm_data = r.data;  
      });
    },
    getOperationRate(){
      this.$http.DT.get('/alarm/period').then(r=>{
        var period;
        var diff;
        this.series[0].data=[];
        for(let i=0; i < r.data.length; i++){
          if(i==0){
            period=r.data[i].period;
            diff=r.data[i].diff;
          }else if(i == r.data.length-1){
            this.series[0].data.push([new Date(r.data[i].date).getTime(),((period/diff)*100).toFixed(0)])
          }else{
            if(r.data[i].date==r.data[i-1].date){
              period+=r.data[i].period;
              diff+=r.data[i].diff;
            }else{
              //그래프에 인서트 명령후 리셋
              this.series[0].data.push([new Date(r.data[i-1].date).getTime(),((period/diff)*100).toFixed(0)])
              period=0;
              diff=0;
              //처음부터 다시 설정
              period=r.data[i].period;
              diff=r.data[i].diff;
            }  
          }
        }
         this.$refs.areachart.updateSeries([{data:this.series[0].data}]) ;
      })
       
    },
  }

}
</script>

<style scoped lang="scss">

  @import '@/assets/css/global.scss';

  #dashboard {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 
      " all all"
      " prod prod"
      " alarm oee";
    gap: map-get($vw, 40);
    padding: map-get($vw, 72) map-get($vw, 56);

    @include relative-web2 {
      grid-template-areas:
        "all all"
        "prod prod"
        "alarm alarm"
        "oee oee";
    }

    * { font-family: $scd; }

    .board__title {
      width: 100%;
      font-size: 27px;
      font-weight: 600;
      text-align: center;
      margin-bottom: map-get($vw, 20);

      @include relative-web1 {
        font-size: 24px;
      }
      @include relative-web2 {
        font-size: 21px;
      }
      @include relative-tablet {
        font-size: 18px;
      }
    }

    .all-machine-list {
      grid-area: all;
      
      .machine-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: map-get($vw, 18);

        @include relative-web2-1 {
          grid-template-columns: repeat(3, 1fr);
        }
        @include relative-web2 {
          grid-template-columns: repeat(4, 1fr);

          @include relative-portrait {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        @include relative-tablet {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    .today_production_quantity {
      grid-area: prod;

      .board__title {
        margin-bottom: 0;
      }

      .production_quantity__wrap {
        min-width: 300px;
        margin-top: -8px;
      }
      ::v-deep .production_quantity {
        min-height: unset !important;

        &.no-data {

          .apexcharts-yaxis,
          .apexcharts-xaxis {
            display: none !important;
          }
        }
        .apexcharts-yaxis {
          
          line { stroke: #5e7295 !important; }
        }
        .apexcharts-grid {

          .apexcharts-gridlines-horizontal {

            line {
              border: #5e7295;
            }
          }
        }
      }
    }

    .recent_alarm {
      grid-area: alarm;

      ::v-deep .recent_alarm_list {
        border-top: 1px solid #aaabab;

        table {
          table-layout: fixed;
        }

        tbody {
  
          tr {
            vertical-align: middle;
  
            td {
              // width: fit-content !important;
              line-height: 1.2;
              font-size: map-get($vw, 16);
              font-size: 16px;
              font-weight: 500;
              border-bottom: 1px solid #aaabab;
              padding: 0 map-get($vw, 8);
              word-break: break-word;

              @include relative-web2 {
                font-size: 14px;
                padding: 6px 6px 4px;
              }

              &:first-child {
                min-width: max-content !important;
                font-weight: 600;
                padding-left: map-get($vw, 16);
                word-break: keep-all;
              }
              &:last-child {
                @extend .ellipsis;

                width: 40% !important;
                padding-right: map-get($vw, 16);
              }
            }
          }
        }
      }
    }

    .oee {
      grid-area: oee;

      .board__title {
        margin-bottom: 0;
      }
      .oee_chart {
        position: relative;
        top: -10px;
      }
    }

    ::v-deep .apexcharts-tooltip {
      border: 0 !important;
      font-family: $scd !important;
      padding: map-get($vw, 2) 0 0;
      background: #231815 !important;

      span {
        color: white;
        font-weight: 500;
      }
      .inner {
        padding: map-get($vw, 4) map-get($vw, 6);
        color: white;
      }
      .bold {
        font-weight: 600;
      }
    }
    ::v-deep .oee_tooltip {
      border: 0 !important;

      p {
        padding: 0 !important;
        margin: 0 !important;
        color: white !important;
        font-weight: 500;
        line-height: map-get($vw, 16);
        font-size: map-get($vw, 14);
      }

      &__date {
        margin-bottom: map-get($vw, 2);
      }
    }
  }

  ::v-deep #loading {
    background-color: transparent !important;

    &::before {
      content: '';
      display: block;
      width: 60%;
      height: 60%;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      background-color: white;
    }
    .vld-icon {
      z-index: 2;
    }
  }
</style>