<template>
  <div id="machine-live">
    <div class="live__inner">
      <ul class="live-machine-list">
        <li 
          class="live-machine"
          v-for="item in processList"
          :key="item.mid + item.index"
        >
          <dashboardMachine :data="item"></dashboardMachine>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dashboardMachine from '../components/dashboardMachine.vue';

export default {
  name: 'live',
  components:{
    dashboardMachine,
  },
  data(){
    return {
      dialog:false,
      data:[],
      height:0,
      processList:[],
      MIDS:{},
    }
  },
  mounted:function(){
    this.$store.state.overlay=false;
    this.$cnc.setCncList(this.processList);
    this.$store.state.overlay=false;

    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti');
    this.$cnc.start();
    this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
  },
  destroyed:function(){
		this.$disconnect();
    this.$cnc.stop()
	},
  methods:{
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/global.scss';

  #machine-live {
    width: 100%;
    height: 100%;

    .live__inner {
      padding: map-get($vw, 24) map-get($vw, 32);
    }

    .live-machine-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: map-get($vw, 16);
    }
  }
</style>