<template>

  <div id="product_status">

    <div class="content_left">
      <!-- 하나 시작 -->
      <div 
        class="machine_card" 
        v-for="(m, index) in mdata" :key='index + "_" + m.mid'
        :class="m.execution"
      >

        <div class="machine_card__info">
          <div class="sub">
            <p class="machine_no">{{ m.machineNo }}</p>
            <p class="execution">{{ m.execution }}</p>
          </div>
          <div class="main">
            <p class="mid">{{ m.mid }}</p>
            <p class="process">{{ m.process }}</p>
          </div>
        </div>

        <div class="machine_card__inner_card">
          <v-progress-linear
            rounded
            :value="m.part_count / m.plan_count  * 100"
            class="lot_process"
            :class="getLotProgressColor(m)"
            height="32"
          >
            <template v-if="m.plan_count > 0">
              {{ m.part_count }} / {{ m.plan_count }}
            </template>
          </v-progress-linear>
          
          <div class="process_full_time">
            <span class="text">
              {{ printHour(m.estLotFinishTime, 'korean') }}
            </span>
          </div>

          <div class="machine_card__count">
            <p class="now_time">
              {{ printHour(parseInt(m.processTime - m.remainTime)) }}
            </p>
            <v-progress-linear
              rounded
              :value=" m.execution=='ACTIVE' ?  ((m.processTime - m.remainTime) / m.processTime)  * 100 : 0" 
              height="20"
              class="machine_card__progress"
            >
              {{ printHour(parseInt(m.remainTime))}}
            </v-progress-linear>
            <p class="process_time">{{ printHour(parseInt(m.processTime)) }}</p>
          </div>
        </div>
      </div>

      <div class="lot_information">
        <Plotly 
          id='grp' ref="gg" 
          :data='gdata1' 
          :layout="layout" 
          :displayModeBar="false" 
          :responsive="true" 
        ></Plotly>
      </div>

    </div>

    <div class="content_right">

      <div class="content_right__top">
        <div class="viewer__upload">
          <v-file-input
            id="file-picker"
            accept=".pdf"
            label="파일 선택"
            ref='my' 
            @change='go'
          >
          </v-file-input>
        </div>
        <div class="viewer">
          <pdf class='pdf_style' :src="url" :page="current_page" @page-loaded="current_page = $event" @num-pages="page_count = $event"></pdf>
        </div>
        <div class="viewer__pagination">
          <v-btn 
            fab small depressed dark
            @click="pre"
            class="pagination__page_btn pre"
            :disabled="pre_disable"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <p class="pagination_page">{{current_page}} / {{page_count == null ? page_count = 0 : page_count }}</p>
          <v-btn 
            fab small depressed dark
            @click="next"
            class="pagination__page_btn next"
            :disabled="next_disable"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="content_right__bottom">
        <div class="memo">
          <div class="memo__title">
            <p class="memo__title_text">메모</p>
            <v-btn
              fab depressed x-small dark
              class="memo__title__write_btn"
              @click="memoWrite"              
            >
              <v-icon v-if="!memo_disabled">mdi-pencil</v-icon>
              <v-icon v-if="memo_disabled">mdi-check</v-icon>
            </v-btn>
          </div>
          <textarea 
            class="memo__textarea"
            v-model="memo" 
            :readonly="!memo_disabled"
          >
          </textarea>
        </div>
      </div>

    </div>

  </div>
  
</template>
<script>
import moment from 'moment';
import { Plotly } from 'vue-plotly';
import pdf from 'vue-pdf'

  export default {
    name: 'productStatus',
    components:{
      Plotly, pdf
    },  
    beforeRouteLeave (to, from, next) {
        this.$disconnect();
       next();
       
    },
    data: () => ({
      process_list:[],
      mkey:-1,
      mdata:[],
      form:{start:null,idle:0},
      clock:null,
      duration:null,
      no:0,
      page:1,
      page_count: 0,
      current_page:1,
      pre_disable:false,
      next_disable:false,
      url:'',
      timer:0,
      pdfFile: '',
      gdata1:[{
        x: [],y: [],name:'Idle',type:"bar",
        marker: {size: 4,color:'rgb(158,202,225)',line:{color:'blue',}},opacity: 1
      }],
      layout:{
        title: "idle",paper_bgcolor:'',plot_bgcolor:'', autosize: true,
        height: 300,
        margin: { l: 50,r: 50,b: 40,t: 30, pad: 4},
        xaxis: { title: {text: 'start',font: {family: 'Courier New, monospace',size: 20,color: '#7f7f7f'}},},
        yaxis: {title: {text: 'idle',font: {family: 'Courier New, monospace',size: 20,color: '#7f7f7f'}}}
      },
      colorchip: {
        yellow: '#FFC56D',
        orange: 'FF8c38',
        orange_base: '#ffcfa7',
        red: '#ed4646',
        navy: '#3c3f52',
        navy_base: '#a9abb4',
        gray: '#a9abb4',
      },

      memo: '',
      memo_disabled: false,
    }),

    computed: {
    },
 
    methods: {
      printHour:function(seconds, type){
        seconds = seconds / 1000;
        var hour = parseInt(seconds/3600);
        var min = parseInt((seconds%3600)/60);
        var sec = parseInt(seconds%60);

        if( type == 'colon' || type == undefined ) {

          if(seconds<0){
            return "00:00:00"
          }
          if(Number.isNaN(seconds)){
            return "00:00:00";
          }
          this.dur();
          if(hour > 0){
            return  ( hour < 10 ? '0'+hour : hour) +":"+ ( min < 10 ? '0'+min : min ) +":" + (sec < 10 ? '0'+sec : sec )
          }else{
            return   ( min < 10 ? '0'+min : min ) +":" + (sec < 10 ? '0'+sec : sec )
          }

        } else if ( type == "korean" ) {
          if( seconds < 0 || Number.isNaN(seconds) ) {
            return '0시간 0분';
          }
          return hour + '시간 ' + min +'분';
        }
      }, 
      go(value){
        let reader = new FileReader();
        this.url = URL.createObjectURL(value);
        // console.log(reader.readAsDataURL(value));
      },
      getMemo(){
        this.$http.DT.get('/memo/s/'+this.mkey).then(r=>{
          if(r.data.length!=0){
          this.memo=r.data[0].comment;
          }
        })
      },
      calcFinish(seconds, mode,plan_count,part_count){
        seconds =(this.form.idle * (plan_count-part_count)) + seconds / 1000;
        var hour = parseInt(seconds/3600);
        var min = parseInt((seconds%3600)/60);
        // var sec = parseInt(seconds%60);
        // return hour+"시간 "+min+"분 " + sec + '초' ;
        this.clock=(moment().add(seconds,'seconds').format('YYYY-MM-DD HH:mm:ss.SSS'));
        // this.duration=(moment().diff(this.form.start,'seconds'));
        // console.log(this.duration);
        if ( mode == 'time' ) return hour+"시간 "+min+"분";
        else if ( mode == 'under30' ) return hour < 1 && min <= 30;
      
      },
      increment(){
        setInterval(()=>{this.timer = parseInt(this.timer) + 1},1000);
      },
      dur(){
        var diff=moment().diff(this.form.start,'seconds');
        var hour = parseInt(diff/3600);
        var min = parseInt((diff%3600)/60);
        var sec = parseInt(diff%60);
        this.duration= hour+"시간 "+min+"분" +sec+"초";
      },
      format(val){
        val=moment(val).format('YYYY-MM-DD HH:mm:ss.SSS');
        return val
      },
      pre(){
        this.current_page=this.current_page-1;
      },
      next(){
        this.current_page=this.current_page+1;
      },
      getData(){
        this.$http.DT.get('/status/'+this.mkey).then(r=>{
          // console.log(r.data);
          this.form.start=moment(r.data[0].start).format('YYYY-MM-DD HH:mm:ss');
          this.form.idle=(r.data[0].idle/1000);
        })
      },
      getIdle(){
        this.$http.DT.get('/status/idle/'+this.mkey).then(r=>{
          for(let i=0; i<r.data.length;i++){
            this.gdata1[0].x.unshift(moment(r.data[i].start).format('YYYY-MM-DD HH:mm:ss.SSS'));
            this.gdata1[0].y.unshift(r.data[i].idle);
          }
        })
      },
      getNo(){
        this.$http.DT.get('/status/no/'+this.mkey).then(r=>{
          this.no=r.data[0].machine_no;
        })
      },
      getLotProgressColor(m) {
        let full_time = this.printHour(m).split(':');
        let is_under_30 = full_time[0] < 1 && full_time[1] < 30;

        if( m.part_count > m.plan_count ) {
          return 'over_count';
        } if ( is_under_30 ) {
          return 'under_30';
        }
        return '';
      },
      memoWrite() {
        if( this.memo_disabled ) {
          // 동작 여기에
          this.memo_disabled = false;
           this.$http.DT.post('/memo/s',{memo:this.memo,mid:this.mkey}).then(r=>{
          })
        } else {
          this.memo_disabled = true;
        }
      },
    },
    mounted(){
      this.mkey=this.$store.state.selectedCnc.mkey;
      this.mdata=[this.$store.state.selectedCnc];
      this.$cnc.state=1;
      this.getData();
      this.$cnc.setCncList(this.process_list);
      this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=midext:'+this.mkey);
      this.$cnc.start();
      this.$options.sockets.onmessage = this.$cnc.listenWebSocketResponse();
      this.getNo();
      this.interval = setInterval(() => {
        this.timer = new Date();
        this.$cnc.setCurrentTime(this.timer,this);
      }, 1000);
      this.getIdle();
      this.getMemo();
       this.$cnc.graphRef( (mkey,start,wait) =>{
       if( this.mkey === mkey){
          if(this.gdata1[0].x.length>=10){
            this.gdata1[0].x.splice(0,1);
            this.gdata1[0].y.splice(0,1);
          }         
          this.gdata1[0].x.push(moment(start).format('YYYY-MM-DD HH:mm:ss.SSS'));
          this.gdata1[0].y.push(wait/1000);
          if(this.$refs.gg){
            this.$refs['gg'].restyle('grp',{})
          }   
        }
      });
    },
    destroyed(){
      this.$disconnect();
      this.$cnc.stop();
      clearInterval(this.interval)
    }
    ,
    watch: {
      process_list :function(v){
        for(let i=0; i<this.process_list.length; i++){
          if(this.mkey==this.process_list[i].mkey){
            this.mdata=[this.process_list[i]];
          }
        }
      },
      current_page(val){
        if(val<=1){
          this.pre_disable=true;
        }else{
          this.pre_disable=false;
        } 
        if(val==this.page_count){
          this.next_disable=true;
        }else{
          this.next_disable=false;
      }
      }
    }

  }
</script>
<style scoped lang="scss">
@import "~@/assets/css/global.scss";

  ::v-deep .v-main {
    height: 100vh !important;
  }

  #product_status {
    @extend .flex_row;

    justify-content: justify;
    width: 100%;
    height: 100%;
  }

  .content_left {
    @extend .flex_column;

    height: 100%;
    flex: 1 1 50%;
    padding: 16px;
  }
  .machine_card {
    @extend .flex_column;

    justify-content: justify;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    padding: 12px;
    margin-bottom: 24px;
    background: #749D6D;

    &__info {
      width: 100%;
      flex: 0 0 auto;  
      background: unset !important;

      .sub {
        @extend .flex_row;

        padding: 0 12px;
        justify-content: space-between;

        p { font-size: 24px; }
        .execution {
          color: rgba(0, 0, 0, 0.3);
        }
        .machine_no {
          font-weight: bold;
          font-size: 28px;
        }
      }

      .main {
        margin: 16px 0 60px;

        .mid {
          font-size: 60px;
          line-height: 1;
          font-weight: bold;
          margin-bottom: 8px;
          text-align: center;
        }
        .process {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
      }
    }

    &__inner_card {
      @extend .flex_column;

      justify-content: justify;
      flex: 1 1 auto;
      width: 100%;
      border-radius: 14px;
      position: relative;
      padding-bottom: 36px;
      background: white;

      .lot_process {
        flex: 0 0 auto;
        width: 80%;
        margin: 0 auto;
        border-radius: 20px;
        transform: translateY(-50%);
        background-color: $navy_base;

        ::v-deep .v-progress-linear__content {
          padding-top: 2px;
          color: white;
        }
        ::v-deep .v-progress-linear__background { 
          background: unset !important;
        }
        ::v-deep .v-progress-linear__determinate {
          border-radius: 0 20px 20px 0 !important;
          background-color: $navy !important;
        }
      }
      .process_full_time {
        @extend .flex_row; 

        flex: 1 1 auto;
        padding: 24px 0;

        .text {
          text-align: center;
          font-size: 64px;
          font-weight: lighter;
        }
      }

      .machine_card__count {
        @extend .flex_row;
        width: 100%;
        position: absolute;
        bottom: 8px;

        justify-self: justify;
        padding: 0 16px;

        p { 
          height: 20px;
          padding-top: 2px;
          line-height: 20px;
        }
        .now_time {
          flex: 0 0 auto;
          margin-right: 8px;
        }
        .machine_card__progress {
          flex: 1 1 auto;
          border-radius: 10px;
          background: $tomato_base;

          ::v-deep .v-progress-linear__content {
            padding-top: 2px;
          }
          ::v-deep .v-progress-linear__background {
            background: unset !important;
          }
          ::v-deep .v-progress-linear__determinate {
            border-radius: 0 10px 10px 0 !important;
            background-color: $tomato !important;
          }
        }
        .process_time {
          flex: 0 0 auto;
          margin-left: 8px;
        }
      }
    }
  }
  .lot_information {
    width: 100%;
    flex: 0 0 300px;
  }

  .content_right {
    @extend .flex_column;

    justify-content: justify;
    height: 100%;
    flex: 1 1 50%;

    &__top {
      @extend .flex_column;

      justify-content: justify;
      width: 100%;
      flex: 0 0 auto;
      overflow-y: hidden;
      padding: 24px;
      padding-left: 12px;

      .viewer__upload {
        width: 100%;
        flex: 0 0 auto;

        #file-picker {
          padding: 4px 6px 2px;
          background-color: cornflowerblue;
          color: white;
          border: 0 !important;
        }
      }
      .viewer{
        width: 100%;
        height: 500px;
        flex: 1 1 auto;
        overflow:scroll;
        background: $pale;

        .pdf{
          width:100%;
          height:100%;
          position:absolute;
        }
        &__pagination {
          @extend .flex_row;

          justify-content: center;
          width: 100%;
          flex: 0 0 auto;
          margin-top: 16px;

          .pagination_page {
            width: 80px;
            margin: 0 8px;
            font-size: 20px;
            text-align: center;
          }
          .pagination__page_btn {
            background-color: cornflowerblue !important; 
          }
        }
      }
    }

    &__bottom {
      width: 100%;
      flex: 1 1 auto;

      .memo {
        @extend .flex_column;

        justify-content: justify;
        height: 100%;
        padding: 24px;
        padding-left: 12px;

        &__title {
          @extend .flex_row;

          width: 100%;
          justify-content: space-between;
          flex: 0 0 auto;
          font-weight: bold;

          &_text {
            font-size: 20px;
            font-weight: bold;
          }
          &__write_btn {
            background-color: cornflowerblue !important;
          }
        }
        &__textarea {
          width: 100%;
          flex: 1 1 auto;
          border-bottom: 1px solid gray;

          &:focus {
            outline: 0;
            border-bottom: 2px solid cornflowerblue;
          }
        }
      }
    }
  }

</style>