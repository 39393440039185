<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.29 16.59"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M25.76 8.55H16.68a.52.52.0 0 0-.52.53v7a.52.52.0 0 0 .52.53h9.08a.53.53.0 0 0 .53-.53v-7a.53.53.0 0 0-.53-.53"/><path class="cls-1" d="M9.61 8.55H.52A.52.52.0 0 0 0 9.08v7a.52.52.0 0 0 .52.53H9.61a.53.53.0 0 0 .52-.53v-7a.53.53.0 0 0-.52-.53"/><path class="cls-1" d="M19.9 2.62V6.35a.65.65.0 0 0 .65.65h1.31a.66.66.0 0 0 .66-.65V.85s0 0 0 0V.66A.65.65.0 0 0 21.86.0H4.43a.65.65.0 0 0-.66.66V.89s0 0 0 .08V6.22a.67.67.0 0 0 .66.66H5.72a.66.66.0 0 0 .65-.66V2.62z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'corporation',
  data() {
    return {
      width: 24,
      height: 24,
    };
  }
});
</script>

<style scoped>
  .cls-1 {
    fill:#839cc5;
  }
</style>