<template>
  <div id="login" class="login">
    <v-dialog 
      v-model="select_cnc" 
      max-width="290" 
      content-class="select_cnc"
      persistent 
    >
      <v-card>
        <v-card-title>
          <span class="title text-h5">장비선택</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="update_cnc_row">
              
              <v-col
                cols="12"
                sm="6"
                class="col"
              >
                <v-autocomplete
                  v-model="$store.state.selectedCnc"
                  :items="$store.state.cncs"
                  item-text="mid"
                  label="장비목록"
                  @change="(value) => updateCnc(value)"
                  class="update_cnc"
                  return-object
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text dark
            @click="check()"
            class="complete"
          >
          확인
          </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>  
      
      <div class="login__inner">
        <div class="login__top">
          <p class="login__top_content content">
            <img src="../assets/img/login_title.svg" alt="login" class="login_title_img">
          </p>
          <div class="line"></div>
        </div>
        <div class="login__bottom">
          <div class="login__bottom__title">
            <p class="login__bottom__title_content content">
              <span class="title_main">환영합니다.</span><br>
              <span class="title_sub">계속하려면 로그인 하세요.</span>
            </p>
          </div>
          <div class="login__form">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="login__form_content content">
                <p class="login__form__id_field">
                  <v-text-field class="textfield username" 
                    v-model="username" 
                    label="U S E R N A M E" 
                    append-icon="$loginId"
                    :rules="name_rule"
                    hide-details required outlined
                    :dense="window.width <= 320"
                    @keyup.enter="submit"
                  ></v-text-field>
                </p>
                <p class="login__form__pw_field">
                  <v-text-field class="textfield password" 
                    v-model="password" 
                    label="P A S S W O R D" 
                    append-icon="$loginPassword"
                    type="password"
                    hide-details outlined required
                    :dense="window.width <= 320"
                    @keyup.enter="submit"
                  ></v-text-field>
                </p>
                <div class="login__form__actions">
                  <v-btn class="login__form__login_btn"
                    depressed dark tile
                    :disabled="!valid"
                    @click="submit"
                  >
                    로그인하기
                    <p class="icon">
                      <v-icon class="login_check" x-small>$loginCheck</v-icon>
                    </p>
                  </v-btn>
                </div>
                <v-btn class="login__form__forgot_pw"
                  tile depressed
                >
                  암호를 잊으셨습니까?
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import sha256 from 'sha256';

export default {
  name: 'login',
  data() {
    return {
      name: null,
      select_cnc:false,
      password: null,
      username: null,
      valid: true,
      name_rule:[
        v => !!v || '이름이 필요합니다.'
      ],
      window: {
        width: window.innerWidth,
      }
    };
  },
  beforeMount() {
    if(this.$router.currentRoute.path == '/logout'){
      this.$disconnect();
      this.$store.commit('enterprise', null);
      this.$store.commit('accessToken',null);
    }
    if (this.$store.state.accessToken !=null && this.$store.state.accessToken !='null' ){
      this.$router.push('/')
    } else{
      // this.userform=true
    }
  },
  methods:{
    updateCnc(value) {
       
       this.$store.commit('selectedCnc',value);
       
        // how can I have here the index value?
    },
    check(){
      if(this.$store.state.selectedCnc){
        this.$router.replace('/')
      }else{
        return;
      }
    },
    submit(){
      if(this.$refs.form.validate()){
        // this.userform=false;
        var data ={username:this.username,password:sha256(this.password)};
        this.$http.LOGIN.login('/api/login/login',data).then(result =>{
          if(result.data.success){
            this.$store.state.username = result.data.username;
            this.$store.commit('enterprise', result.data.enterprise);
            this.$store.commit('enterprise_id', result.data.enterprise_id);
            this.$store.commit('accessToken',result.data);
            this.$http.APIS.get('/api/process/list').then(result =>{
              this.$store.state.cncs=result.data;
            });
            this.select_cnc=true;
            /*this.$http.post('/api/cloud/installedTransmitters',{}).then(result =>{
                  this.$store.state.edges=result.data.data;
                  result.data.data.forEach(item=>{
                     this.$http.edge('/api/edge/edge_machine_stat',{transmitter:item.id}).then(result =>{
                       this.$store.state.cncs=result.data.data;
                    })
                  })
                  
              })*/
            //this.$router.replace('/')
          }else{
            alert(result.data.msg);
          }
        });
      }
    },
    clear(){
      this.$refs.testform.reset();
    },
    getWindowWidth() {
      this.window.width = window.innerWidth;
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() { cb(); });
      }
    }
  },
  mounted:function(){
    window.addEventListener('resize', this.animationFrame(this.getWindowWidth));
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/global.scss';

$purple_gradient: linear-gradient(to right, #6900F2, #0758E8);

  ::v-deep header.header { display: none !important; }

  .login {
    width: 100%;
    height: 100%;
    
    * { font-family: $scd !important; }

    background: white url(../assets/img/login_bg.svg) no-repeat right bottom;
    background-size: auto 80%;

    @include relative-web2 {
      background-size: auto 70%;

      @include relative-portrait {
        background-size: auto 50%;
      }
    }
    @include relative-tablet {
      background-size: 90% auto;
    }

    &__inner {
      width: 60%;
      height: 100%;

      @include relative-web2 {

        @include relative-portrait {
          width: 80%;
        }
      }
      @include relative-tablet {
        width: 100%;
      }

      .content { 
        width: vconv(1920, 420);
        margin: 0 auto;

        @include relative-web2 {
          width: 300px;

          @include relative-portrait {
            width: 340px;
            padding-right: 40px;
          }
        }
        @include relative-tablet {
          width: 80%;
          padding-right: 0;
          margin: 0 auto;
        }
      }
    }

    &__top {

      &_content {
        padding: map-get($vw, 180) 0 map-get($vw, 24);

        @include relative-web2 {
          padding: 100px 0 16px;
        }
        @include relative-tablet {
          padding: 80px 0 16px;
        }
      }
      .login_title_img {
        width: auto;
        height: map-get($vw, 48);

        @include relative-web2 {
          height: 32px;
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-image: $purple_gradient;
      }
    }

    &__bottom {

      &__title {
        padding: map-get($vw, 64) 0 0;
        margin-bottom: map-get($vw, 100);

        @include relative-web2 {
          padding: 32px 0 0;
          margin-bottom: 40px;
        }
        @include relative-mobile {
          margin-bottom: 24px;
        }

        &_content {
          font-size: map-get($vw, 32);
          line-height: 1;

          @include relative-web2 {
            font-size: 21px;
          }
          @include relative-mobile {
            font-size: 18px;
          }

          span { 
            display: inline-block; 
            font-weight: 300;
          }
        }
        .title_main {
          margin-bottom: map-get($vw, 21);

          @include relative-mobile {
            margin-bottom: 6px;;
          }
        }
        .title_sub {
          color: #727171 !important;
        }
      }

      .login__form {

        ::v-deep .textfield {
          background: white;

          // .v-input__control {}
          .v-input__slot {
            border-radius: 0 !important;
            padding: 0 map-get($vw, 20) !important;

            @include relative-web2 {
              padding: 0 12px !important;
            }
            
            fieldset {
              border-color: #898989 !important;
            }
            legend {
              margin-left: map-get($vw, 7);
            }
            .v-label {
              color: #C1CEE2;
              font-weight: 500;

              &.error--text {
                color: #C1CEE2 !important;
              }
              &.v-label--active {
                color: $yhsblue !important;
              }
            }
          }
        }

        &__id_field {
          margin-bottom: map-get($vw, 14);

          @include relative-web2 {
            margin-bottom: 10px;
          }
        }

        &__actions {
          @extend .cf;

          margin-top: map-get($vw, 12);

          @include relative-web2 {
            margin-top: 12px;
          }
        }
        &__login_btn {
          float: right;
          position: relative;
          padding-right: 0 !important;
          background-image: $purple_gradient !important;

          ::v-deep .v-btn__content {
            display: block !important;
            height: 100%;
            font-weight: 500 !important;
            line-height: 1;
            padding: 12px map-get($vw, 64) 0 0;
            letter-spacing: 0.5px;

            @include relative-web2 {
              padding-right: 52px;
            }
            @include relative-mobile {
              font-size: 12px;
            }
          }
          .icon {
            @extend .flex_row; 

            justify-content: center;
            width: map-get($vw, 50);
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: $skyblue;

            @include relative-web2 {
              width: 36px;
            }
          }
        }
        &__forgot_pw {
          width: 100%;
          margin-top: map-get($vw, 90);
          position: relative;
          // background: linear-gradient(to right, white 70%, transparent) !important;
          background: linear-gradient(to right, white 70%, rgba(255,255,255,0.001));

          @include relative-web2 {
            margin-top: 54px;
          }

          ::v-deep .v-btn__content {
            font-family: $scd !important;
            font-weight: 300;
            justify-content: flex-start !important;
            font-size: map-get($vw, 16);
            color: $yhsblue;

            @include relative-web2 {
              font-size: 16px;
            }
            @include relative-mobile {
              font-size: 12px;
            }
          }
          
          &::after {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: vconv(1920, -8);
            left: 0;
            background: $yhsblue;
          }
        }
      }
    }
  }



  // 장비 선택
  ::v-deep .select_cnc {

    * {
      font-family: $scd !important;
    }
    .v-card__title {

      .title {
        width: 100%;
        color: $yhsblue;
        text-align: center;
        padding: map-get($vw, 12) 0;
        margin-bottom: map-get($vw, 20);

        @include relative-mobile {
          margin-bottom: 16px;
        }
      }
    }
    .update_cnc {
      margin: 0 auto 6px;
    }
    .update_cnc_row {
      width: 100% !important;

      .col {
        min-width: 100% !important;
      }
    }
    .complete {
      color: $yhsblue !important;
    }

  }

  ::v-deep .v-list-item {

    .v-list-item__title {
      font-family: $scd !important;
    }
  }
</style>