<template>
  <div id="alarm_record" class="alarm_record">
    <loading id="loading" :active.sync="visible" :can-cancel="true" color="#0346DA" loader="dots" ></loading>
    <div class="alarm_record__header">
      <select 
        name="mid" 
        id="mid_select" 
        class="header__mid_select" 
        v-model='select_mid'
      >
        <option  
          class="mid" 
          v-for="m in mid"
          :key="m.id"
          :value="m.id"
        >{{ m.name }}</option>
      </select>
      <datePicker 
        :preset="date" 
        :periodActive="true" 
        class="time_picker" 
        @picked="datePick" 
        @dateRange="changeButton"
        :presetIcon="['append', false, false]"
        :title="'날짜 선택'"
      ></datePicker>
      <v-text-field class="header__search"
        v-model="search"
        append-icon="mdi-magnify"
        label="검색어 입력"
        hide-details
      ></v-text-field>
      <v-btn 
        class="header__filter_btn" 
        @click="getAlarm(filter.start,filter.end)"
        depressed
      >검색</v-btn>
    </div>
    <div class="alarm_record__content">
      <v-data-table class="alarm_list"
        :headers="alarm_header"
        :items="alarm_data"
        :height="height"
        :search="search"
        :items-per-page="-1"
        fixed-header
        :sort-by.sync="sortBy"
        :sort-desc="true"
        dense hide-default-footer
      > 
      <template slot="no-data">
        <div class="no-data">데이터가 없습니다</div>
      </template>
      <template v-slot:item.date="{item}">
        {{dateForm(item.date)}}
      </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import datePicker from "../components/datePicker.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
export default {
  name: 'alarmRecord',
  components: {
    datePicker,
    loading : Loading
  },
  data() {
    return {
      sortBy:'date',
      height: 500,
      search: '',
      code:null,
      alarm_header: [
        {
          text: '기계명',
          align: 'left',
          sortable: true,
          width: '24%',
          value: 'mid'
        },
        {
          text: '알람 일자',
          align: 'left',
          sortable: true,
          width: '20%',
          value: 'date'
        },
        {
          text: '알람 내용',
          align: 'left',
          sortable: false,
          value: 'message'
        },
        {
          text: '비고',
          align: 'left',
          width: '20%',
          sortable: false,
          value: 'note'
        },
      ],
      alarm_data: [],
      date: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      mid: [],
      select_mid:'%',
      filter:{},
      visible: false,
      today: moment().format('YYYY-MM-DD'),
    };
  },
  watch:{
    select_mid:function(v){
      this.getAlarm(this.filter.start,this.filter.end);
    }
  },
  methods: {
    listHeight() {
      const hei = window.innerHeight;
      const header = document.querySelector('.alarm_record__header');

      let except = {
        header: header != undefined ? parseInt(getComputedStyle(header).height) : 100,
      }
      this.height = hei - except.header - 5; 
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if(rAftimeout) { window.cancelAnimationFrame(rAftimeout);}
        rAftimeout = window.requestAnimationFrame(function() { cb(); })
      }
    },
    datePick(value) {
      this.filter=value;
      this.getAlarm(this.filter.start, this.filter.end);
    },
    getMid(){
      this.$http.DT.get('/alarm/').then(r=>{
        this.mid=r.data;
        this.mid.unshift({id:'%',name:'전체'});
      })
    },
    changeButton(start,end,code){
      this.code=code;
      this.getAlarm(start,end);
    },
    getAlarm(start,end){
      if(this.code=='all' && start==this.today && end==this.today){
        start='0';
        end='9';
      }
      let map = {mkey:this.select_mid, start:start, end:end};
      this.visible = true
      this.$http.DT.post('/alarm/',map).then(r=>{
        this.alarm_data=r.data;
        this.visible = false
      })
     
    },
    dateForm(val){
      val=val.replace('T',' ').substr(0,19);
      return val;
    }
  },
  mounted() {
    this.getMid();
    this.getAlarm(this.filter.start,this.filter.end);

    this.listHeight();
    window.addEventListener('resize', this.animationFrame(this.listHeight));
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/global.scss";

* { font-family: $scd !important; }

@mixin relative-web-1600 {
  @media all and (max-width: 1599px) { @content }
}


.alarm_record {
  position: relative;

  #loading { z-index: 30; }

  &__header {
    @extend .flex_row;

    flex-wrap: wrap;
    padding: 34px 14px 16px 0;
    background: white;

    @include relative-web1 {
      padding: 34px 16px 16px;
    }
    @include relative-web-1600 {
      justify-content: center;
      padding-bottom: 24px;
    }

    .header__mid_select {
      width: 140px;
      padding: 9px 18px 7px 4px;
      margin-left: vconv(1920, 145);
      border-radius: 0;
      border-bottom: 1px solid $table_black;
      background: url('~@/assets/img/box_chevron_down.svg') no-repeat right 0 center;
      background-size: 14px auto;

      @include relative-web1 {
        margin-left: 0;
      }
      @include relative-web-1600 {
        order: 2;
      }

      &:foucs {
        border-bottom-width: 2px;
        outline: none !important;
      }
    }
    ::v-deep .time_picker  {
      margin-left: 24px;

      @include relative-web-1600 {
        width: 100%;
        justify-content: center;
        order: 1;
        margin-left: 0 !important;
        margin-bottom: 18px;
      }
      @include relative-web2-1 {
        flex-wrap: wrap;
      }

      .period_select {
        order: 1;
        margin-left: 12px;

        @include relative-web2-1 {
          width: 100%;
          margin-top: 12px;
          text-align: center;

          .period_btns {
            display: inline-block;
          }
        }
      }
      .picker {
        border: 1px solid $table_black;
      }
    }
    ::v-deep .header__search {
      justify-self: flex-end;
      max-width: 300px;
      margin-left: auto;
      margin-right: 24px;
      font-size: 14px;

      @include relative-web-1600 {
        margin-left: 16px;
        order: 3;
      }

      .v-label {
        font-size: 14px;
      }
    }
    .header__filter_btn {
      justify-self: flex-end;
      width: 60px;
      margin-left: 0;
      background-color: #E6E6E6 !important;

      @include relative-web-1600 {
        order: 3;
      }
    }
  }
  &__content {
    
    .alarm_list {
      @include dt_table--theme-black(145);

      @include relative-web2-1 {
        @include dt_table--theme-black(16);
      }
    }
  }
}


// mac
.mac {

  .alarm_record {

    &__header {

      .header__filter_btn {
        padding-top: 3px;
      }
    }
  }
}
</style>