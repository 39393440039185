<template>
  <div class='monitor_manage'>
    <div class="monitor__inner">
      <div 
        v-for="(mon, index) in mon_data" 
        class="monitor"
        :class='["monitor"+index]' 
        :key="index"
      >
        <iframe 
          v-show='loaded'
          :ref="'monitor' + index"
          :src = mon.name 
          @load="onLoad" 
          :name='"my-frame"+index'  
          width="100%" 
          height="100%"
          style="font-family='SCDream' !important" 
        ></iframe>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'monitorManage',
  components: {
  },
  data()  {
    return {
      mon_data:[],
      url:'https://dt.yhs.dev/monitor/',
      // url:'http://localhost:8080/monitor/',
      loaded:false,
    }
  },
  beforeMount() {

  },
  methods:{
    onLoad(frame){
      this.loaded=true;
    }
  
  },
  mounted:function(){
   this.$http.DT.get('/mon/').then(r=>{
      for(let i=0; i<r.data.length;i++ ){
        r.data[i].name = this.url + r.data[i].name+'/'+r.data[i].id;
      }
      this.mon_data=r.data;  
    })
  },
  method:{
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/global.scss";
  
  .monitor_manage {
    
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    .monitor__inner {
      @extend .flex_row;

      width: 100%;
      flex-wrap: wrap;
      align-items: unset;
      align-content: flex-start;

      .monitor {
        height: 50vh;
        flex: 0 0 20%;
  
        @include relative_portrait {
          flex: 0 0 50%;
        }
      }
    }

  }
</style>