<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.58 78.14"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M37.59 76.6C12.66 73.23 1.5 55.32 1.5 39.07S12.66 4.91 37.59 1.54h.2.2C62.92 4.91 74.08 22.82 74.08 39.07S62.92 73.23 38 76.6h-.2z"/><path d="M37.79 3c46.39 6.27 46.39 65.81.0 72.08C-8.6 68.84-8.6 9.3 37.79 3m0-3-.4.05C11.56 3.55.0 22.17.0 39.07s11.56 35.52 37.39 39l.4.06.4-.06C64 74.59 75.58 56 75.58 39.07S64 3.55 38.19.05h-.4z"/><path d="M33.87 43h-17c-3.93-3.46-1.53-7.84 1.89-7.84H58.67c2.69 2.36 2.42 5.16.89 6.69A3.91 3.91.0 0 1 56.78 43H33.87z"/></g></g></svg>
</template>

<script>
export default {
  name: 'monitorRemove',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#fff;}
</style>