<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.61 76.21"><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path d="M84.56 64.52 49.55 3.9a7.78 7.78.0 0 0-13.49.0l-35 60.62A7.79 7.79.0 0 0 7.8 76.21h70A7.8 7.8.0 0 0 84.56 64.52zm-41.75.58a4.5 4.5.0 1 1 4.5-4.5 4.49 4.49.0 0 1-4.5 4.5zm4.31-17a4.33 4.33.0 0 1-8.63.0l-1.41-17.6a3.17 3.17.0 0 1 3.15-3.42h5.15a3.16 3.16.0 0 1 3.15 3.42z"/></g></g></svg>
</template>

<script>
export default {
  name: 'alertTri',
  props: {
    color: {
      default: '#e8661c',
      type: String,
    },
  }
}
</script>

<style>

</style>