<template>
    <div>
       <v-toolbar flat >
      <v-toolbar-title>모니터 관리</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      
      <!-- 업체 수정 팝업 START-->
      <v-dialog v-model="Ddialog" fullscreen hide-overlay  >
        <v-card>
          <v-toolbar dark color="indigo">
          <v-btn icon dark @click="Ddialog=!Ddialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{selectedItem.comment}}</v-toolbar-title>
          <v-subheader>{{selectedItem.name}}</v-subheader>

          <v-spacer></v-spacer>
        </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="selectedItem.name" label="모니터 코드명"  ></v-text-field>
                </v-flex>
                          <v-flex xs8 sm8 md8>
      <v-data-table
            :headers="header_sub"
            :items="itemSubList"
            class="elevation-1"
            :loading="loading"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            
        >
        <template v-slot:body="props">
        <Draggable
          :list="props.items"
           @change="changeOrder"
          tag="tbody"
        >
          <tr class="handle"
            v-for="(cnc, index) in props.items"
            :key="index"
          >
            <td width="90%"> {{ cnc.name }}</td>
            <td>
              <v-icon
                small
                @click="delCnc(cnc.cnc_id)"
              >
                mdi-pencil
              </v-icon>
            </td>
          </tr>
        </Draggable>
      </template>
        </v-data-table>
                          </v-flex>


                          <v-flex xs4 sm4 md4>
      <v-data-table
            :headers="header_sub"
            :items="allCNCList"
            class="elevation-1"
            :loading="loading"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            
        >
        <template v-slot:item.name="{ item }">
          {{item.name}}
        </template>
         <template v-slot:item.action="{ item }">
          <v-btn @click="addCnc(item)">추가</v-btn>
        </template>
        </v-data-table>
                          </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>

          

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"  @click="deletepop=true">삭제</v-btn>
            <v-btn color="blue darken-1"  @click="updateEnt">업데이트</v-btn>
            <v-btn color="blue darken-1"  @click="Ddialog=!Ddialog">취소</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- 업체 수정 팝업 END-->
<!-- 업체 등록 팝업 START-->
      <v-dialog v-model="dialog"   v-if="selectedEnter.id!==undefined" max-width="500px">
        <template  v-slot:activator="{ on }">
          <v-btn  color="primary" dark class="mb-2" v-on="on">신규 모니터 등록</v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">모니터 등록</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field v-model="newItem.name" label="모니터 코드"  ></v-text-field>
                </v-flex>
                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="dialog=!dialog">취소</v-btn>
            <v-btn color="blue darken-1"  @click="insertEnt">등록</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 업체 등록 팝업 END-->

      <!-- 삭제 POPUP START-->
      <v-dialog v-model="deletepop" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">삭제합니까?</span>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"  @click="deletepop=!deletepop">취소</v-btn>
            <v-btn color="blue darken-1"  @click="deleteEnt">삭제</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 삭제 POPUP 팝업 END-->

    </v-toolbar>
    <v-flex xs12 sm12 md12>
                  <v-combobox
                  v-model="selectedEnter"
                  :items="enterList"
                  item-text="comment"
                  label="운영 업체"
                  return-object
                  ></v-combobox>
                </v-flex>
        <v-data-table
            :headers="headers"
            :items="itemList"
            class="elevation-1"
            :loading="loading"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            @click:row="openDetail"
        >
        </v-data-table>
    </div>
</template>
<script>
import Draggable from 'vuedraggable';

export default {
    name: 'Enterprise',
    components: {
    Draggable
  },
    data () {
    return {
        itemList:[],
        itemSubList:[],
        itemSubList2:[],
        allCNCList:[],
        selectedItem:{enterprise_id:null,name:null},
        enterList:[],
        selectedEnter:{comment:"-"},
        newItem:{name:null,enterprise_id:null},
        loading:true,
        dialog:false,
        Ddialog:false,
        deletepop:false,
        header_sub: [
          { text: '장비명',sortable: false, value: 'name' ,width: '10px'},{ text: '삭제',value:'action' }
        ],
        headers: [
          { text: 'ID', value: 'id' ,width: '10px'},
          {
            text: '모니터 코드',
            align: 'left',
            value: 'name'
          },{
            text: '상태',
            align: 'left',
            sortable: false,
            value: 'status'
          },{
            text: '등록시간',
            align: 'left',
            sortable: false,
            value: 'regdate'
          },{
            text: '로그인계정',
            align: 'left',
            sortable: false,
            value: 'loginid'
          }
        ],
    
      }
    },
    watch:{
      selectedEnter:function(v){
        //console.log(v)
        this.loadPhy();
        this.allCnc();
        this.newItem.enterprise_id=v.id;
      }
    },
    methods:{
      getMonitor(){
        this.$http.DT.get('/admin/').then(result =>{ 
           console.log(result.data);
        });
      },
      move:function(input, from, to) {
        let numberOfDeletedElm = 1;
        const elm = input.splice(from, numberOfDeletedElm)[0];
        numberOfDeletedElm = 0;
        input.splice(to, numberOfDeletedElm, elm);
      },
      changeOrder:function({moved}){
        
        var oidx = moved.oldIndex;
        var nidx = moved.newIndex;
        this.move(this.itemSubList2,oidx,nidx)
        console.log(this.itemSubList2)
      },
      openDetail:function(value){
          this.selectedItem=value;
          console.log(value)
          this.$http.APIS.post('/api/master/monitor/list_item',{monitor_id:this.selectedItem.id}).then(result =>{ 
            
            
            this.itemSubList= result.data.data;
            this.itemSubList2=result.data.data;
            this.loading=false;
            this.Ddialog=true;
          });
          
      },
      loadEnter:function(){
        this.$http.APIS.post('/api/master/enterprise/list',{}).then(result =>{ 
            this.enterList= result.data.data;
            this.loading=false;
        });
      },
      loadPhy:function(){
        this.$http.APIS.post('/api/master/monitor/list',{enterprise_id:this.selectedEnter.id}).then(result =>{ 
            this.itemList= result.data.data;
            this.loading=false;
        });
      },
      updateEnt:function(){
        
        this.selectedItem.items=this.itemSubList2;
        
        this.$http.APIS.post('/api/master/monitor/update',this.selectedItem).then(result =>{ 
            this.loading=false;
            this.loadPhy();
            this.Ddialog=false;
        });
      },
      insertEnt:function(){
        this.$http.APIS.post('/api/master/monitor/insert',this.newItem).then(result =>{             
          this.loadPhy();
          this.dialog=false;
        });

      },
      deleteEnt:function(){
        this.$http.APIS.post('/api/master/monitor/delete',this.selectedItem).then(result =>{             
           this.loadPhy();
           this.deletepop=false;
           this.Ddialog=false;
        });   
      },
      allCnc:function(){
          this.$http.APIS.post('/api/master/monitor/all_cnc_list',this.selectedEnter).then(result =>{ 
              this.allCNCList  = result.data.data;
              this.loading=false;
          });
      },
      delCnc(id){
        
        this.itemSubList = this.itemSubList.filter(function( obj ) {
        
          return obj.cnc_id !== id;
        });
        this.itemSubList2 = this.itemSubList;/*this._itemSubList.filter(function( obj ) {
          
          return obj.cnc_id !== id;
        });*/
      },
      addCnc(cnc){
        var index = this.itemSubList.find(x => x.cnc_id === cnc.cnc_id)
        console.log('idx',index)
        if(index === undefined){
          this.itemSubList.push(cnc)
        }
      },
    
      
    },
    mounted:function(){
        this.getMonitor();
        //this.loadPhy();
        this.loadEnter();
        let monitor = this.$http.APIS.post('/api/cloud/monitorList',{monitor:"mon1"}).then(r=>{

        });
        
    }
  
}
</script>
<style scoped>
.handle {
  cursor: move;
}
</style>