<template>
  <header class="white-header component-wrap">
    <h1 class="barofactory-main-info">
      <img src="@/assets/img/barofactory_mini.svg" alt="BAROFACTORY 바로팩토리" class="barofactory-logo">
    </h1>

    <p class="header-timer">
      <span class="header-date">{{timer.date}}</span>
      <span class="realtime-clock">{{timer.time}}</span>
    </p>

    <h2 class="header__title">{{$route.meta.title}}</h2>

    <img src="@/assets/img/yhs_logo_white.svg" alt="연합시스템" class="yhsbearing company-logo">

    <v-btn class="logout" 
      to="/logout"
      small fab text
    >
      <v-icon size="2.4vh">$logout</v-icon>
    </v-btn>
  </header>
</template>

<script>
import moment from "moment";

export default {
  name: 'whiteHeader',
  data() {
    return {
      timer: {
        date: moment().format('YYYY.MM.DD'),
        time: moment().format('HH:mm:ss'),
      }
    };
  },
  methods: {
    // 헤더 시계 시간 구하기
    calculateTimer() {
      this.timer.time = moment().format("HH:mm:ss");
    },
  },
  mounted() {
    setTimeout(() => { location.reload(); }, 3600000);
    setInterval(this.calculateTimer, 1000);
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/global.scss";

  .white-header {
    @include flex(row, nowrap, flex-start, center);

    width: 100%;
    height: vh(65);
    border-bottom: 1px solid black;
    padding: 0 vw(40) 0 vw(18);
    position: fixed;
    // font-family: $ptd;
    color: white;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #323232;

    .barofactory-main-info {
      @include flex(row, nowrap, flex-start, center);

      .barofactory-logo {
        display: block;
        width: auto;
        height: vh(34);
        margin-right: vw(32);
      }
    }
    .header-timer {
      @include flex(row, nowrap, flex-end, center);

      font-size: vh(32);

      .header-date {
        font-weight: 300;
      }
      .realtime-clock {
        // @extend .mono-number;

        font-weight: 500;
        margin-left: vw(16);
      }
    }
    .yhsbearing {
      display: block;
      width: auto;
      height: vh(20);
      margin-left: auto;
      justify-self: flex-end;
    }
    .header__title {
      justify-self: center;
      margin: 0 auto;
      text-align: center;
      font-size: vw(30);
      font-weight: 600;
    }
    .logout {
      width: vw(32);
      height: vw(32);
      justify-self: flex-end;
      margin-left: vw(32);
    }
  }
</style>