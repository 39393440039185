<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.58 18.36"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M15.83,0a.47.47,0,0,0-.33.13L6.91,8.71,6.3,12.28l3.48-.57,8.66-8.63a.45.45,0,0,0,0-.66L16.16.13A.47.47,0,0,0,15.83,0Z"/><path class="cls-1" d="M1.14.68A1.15,1.15,0,0,0,0,1.83V17.22a1.14,1.14,0,0,0,1.14,1.14H14.38a1.14,1.14,0,0,0,1.14-1.14V11.28H13.24v4.8H2.29V3h7.88V.68Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'modify',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#999;}
</style>