<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.07 16.52"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M8 0C5.82.0 4 1.19 4 3.82S5.87 8.6 8 8.6s4-2.15 4-4.78S10.26.0 8 0zM3.49 10.67a6.38 6.38.0 0 0-3.2 2 1.34 1.34.0 0 0-.29.81v1.6a1.53 1.53.0 0 0 1.61 1.44H14.47a1.53 1.53.0 0 0 1.6-1.44v-1.6a1.34 1.34.0 0 0-.38-.93 6.51 6.51.0 0 0-3.11-1.88A15.79 15.79.0 0 0 8 10 15.91 15.91.0 0 0 3.49 10.67z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'yhsProfile',
  data() {
    return {
      width: 24,
      height: 24,
    };
  }
});
</script>

<style scoped>
  .cls-1{
    fill:#ffffff;
    fill-rule:evenodd;
  }
</style>