<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.53 133.56"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M66.75 132.06A65.28 65.28.0 0 1 20.59 20.62 64.85 64.85.0 0 1 66.75 1.5h0a65.28 65.28.0 0 1 46.16 111.44A64.86 64.86.0 0 1 66.75 132.06z"/><path d="M66.75 3a63.78 63.78.0 1 1-45.1 18.68A63.61 63.61.0 0 1 66.75 3m0-3A66.78 66.78.0 0 0 19.53 114 66.78 66.78.0 1 0 114 19.56 66.36 66.36.0 0 0 66.75.0z"/><path d="M73.94 59.59V32a7.19 7.19.0 0 0-14.38.0V59.59H31.93a7.19 7.19.0 0 0 0 14.38H59.56v27.62a7.19 7.19.0 1 0 14.38.0V74h27.62a7.19 7.19.0 0 0 0-14.38z"/></g></g></svg>
</template>

<script>
export default {
  name: 'monitorAdd',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#fff;}
</style>