<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.12 25.42"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M25.42 5.08H6.78a1.71 1.71.0 0 0-1.7 1.7v17a1.7 1.7.0 0 0 1.7 1.69H25.42a1.7 1.7.0 0 0 1.7-1.69v-17A1.71 1.71.0 0 0 25.42 5.08zm-.85 17.8H7.63V7.63H24.57z"/><path class="cls-1" d="M7.27 3.39H22V1.7A1.7 1.7.0 0 0 20.34.0H1.69A1.7 1.7.0 0 0 0 1.7V18.64a1.7 1.7.0 0 0 1.69 1.7h1.7V6.51h0V5.09a1.7 1.7.0 0 1 1.69-1.7z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'changeMenu',
  data() {
    return {
    };
  }
});
</script>

<style scoped>

  .cls-1 {
    fill:#839cc5;
  }

</style>