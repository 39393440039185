<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.09 17.25"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M0 8.63a.87.87.0 0 1 .05-.28 13.52 13.52.0 0 1 25 0 .87.87.0 0 1 .05.28A.86.86.0 0 1 25 8.9a13.52 13.52.0 0 1-25 0 .86.86.0 0 1 0-.27zM12.55 3.92a4.71 4.71.0 1 0 4.7 4.71 4.71 4.71.0 0 0-4.7-4.71zm0 2.35A2.36 2.36.0 1 0 14.9 8.63a2.36 2.36.0 0 0-2.35-2.36zm0-3.92a6.28 6.28.0 1 0 6.27 6.28A6.28 6.28.0 0 0 12.55 2.35z"/></g></g></svg>
</template>

<script>
export default {
  name: 'processRecordCheck',
}
</script>

<style lang="scss" scoped>
  .cls-1{
    fill:#8198be;
    fill-rule:evenodd;
  }
</style>