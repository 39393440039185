<template>
  <div id="program">
    <!-- 탭 부분 -->
    <v-tabs class="program__tab" 
      v-model="tab" ref="tabHeader"
      show-arrows
    >
      <v-tabs-slider class="tabs"></v-tabs-slider>
      <v-tab class="tab"
        :href="'#' + machine.name"
        v-for="machine in machine_list"
        :key="machine.name"
        @change="changeTab(machine)"
      >
        <p class="tab__text">
          <span class="light" :class="machine.status"></span>
          {{ machine.name }}
        </p>
      </v-tab>
    </v-tabs>
    <!-- 본문 부분 -->
    <v-tabs-items 
      class="program__content" 
      v-model="tab" 
      :style="{ 'height': content_height + 'px'}"
    >
      <v-tab-item
        v-for="machine in machine_list"
        :key="machine.name+'Tab'"
        :value="machine.name"
      >
       <div class="program__content_left">
          <div class="cotent_inner">
            <v-list class="dir_list">
              <v-list-item 
                class="item"
                v-for="(dir, index) in dirs" 
                :key="dir.name + '_' + dir.comment" 
                :class="{ 'dir_item--active': index == selected_dir}"
                @click="loadFile(dir, index)"
              >
                O{{ dir.name }} {{ dir.comment }}
              </v-list-item>
            </v-list>
          </div>
        </div>

        <div class="program__content_right">
          <div class="program__content_actions">
            <v-btn 
              class="save_file"
              depressed
              v-show="text_name != null"
              @click='saveFile'
            >파일로 저장</v-btn>
          </div>
          <div class="content">
            <pre>
              {{ file_content }}
            </pre>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <loading id="loading" :active.sync="visible" :can-cancel="true" color="#0346DA" loader="dots"></loading>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import Loading from 'vue-loading-overlay';
export default {
  name: 'program',
   components: {
    loading : Loading
  },
  data() {
    return {
      tab: null,
      machine_list:[],
      selected_machine:{},
      dirs: [],
      file_content:null,
      content_height: 500,
      text_name:null,
      visible: false,
      selected_dir: undefined,
    }
  },
  methods: {
    saveFile(){
      if(this.file_content){
        var blob = new Blob([this.file_content], {type: "text/plain;charset=utf-8"});
        saveAs.saveAs(blob, this.text_name+'.txt');
      }
    },
    requestHandler:function(resp){
      if("CALL_FUNC_RESULT"==resp.response){
        if(resp.request.func==="prgdir"){
          this.printDir(resp.data);
          this.visible=false;
        }else if(resp.request.func==="loadfile"){
          this.printFile(resp.data);
        }
      }
    },
    printDir:function(data){
      this.dirs= data;
    },
    printFile:function(data){
      this.file_content= data;
    },
    changeTab:function(num){
      this.visible=true;
      this.selected_machine = num;
    },
    loadFile:function(num, idx){
      this.text_name='O'+num.name+num.comment;
      this.callfunc(this.selected_machine,"loadfile",num.name);
      this.selected_dir = idx;
    },
    callfunc:function(item,func,arg){
      this.$store.state.overlay=true;
      item.func=func;
      item.no=arg;
      if(item.status=='on'){
        this.$http.EDGE.post('/api/edge/callfunc',item).then(r =>{ 
        });
      }else{
        this.$store.state.overlay=false;
        this.visible=false;
      }
    },
    loadCNC:function(){
      this.$store.state.overlay=true;
        this.$http.APIS.post('/api/cloud/installedTransmitters',{}).then(result =>{ 
          this.transmitter = result.data.data;
          this.selectedTransmitter = this.transmitter[0]
          var item = {};
          item.enterprise=this.$store.state.enterprise        
          item.transmitter=this.selectedTransmitter.id
          this.$http.APIS.post('/api/cloud/active_machine',item).then(result =>{ 
            result.data.data.forEach( t=>{
              t.nameORG = t.name
              t.portORG = t.port
              t.ipORG = t.ip
              t.autostartORG = t.autostart
            });
            this.machine_list  = result.data.data;
            this.selected_machine = this.machine_list[0]
            this.$store.state.overlay=false;
          });
        });
      },
    getContentHeight() {
      const hei = window.innerHeight;
      const tab = document.querySelector('.program__tab');
      const except = {
        header: 64,
        tab: tab != undefined ? parseInt(getComputedStyle(tab).height) : 100,
      }
      this.content_height = hei - except.header - except.tab - 3;
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() {
          cb();
        });
      };
    },
  },
  mounted:function(){
    this.$cnc.start();
    this.$connect(this.$store.state.websocket_url+this.$store.state.sender+'?ent='+this.$store.state.enterprise+'&view=noti')
      this.$options.sockets.onmessage = (data) => {
      if(data.data.constructor.name !== 'Blob'){
        var resp = JSON.parse(data.data);
        this.requestHandler(resp);
        this.$store.state.overlay=false;
      }
    }
    this.loadCNC();
    this.$store.state.overlay=false;
    
    // content 높이 설정
    this.getContentHeight();
    window.addEventListener('resize', this.animationFrame(this.getContentHeight));
  },
  destroyed(){
    this.$disconnect();
    this.$cnc.stop();
    clearInterval(this.interval);
	},
  watch: {
    selected_machine(n) {
      this.visible=true;
      this.file_content='';
      this.dirs.length=0;
      this.callfunc(n,"prgdir");
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/global.scss";

  $gray: #e8E9ea;
  $white_gray: #f2f2f2;
  $blue: #5e7295;

  @mixin scroll($bg_border: white) {

    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-track {
      background-color: $bg_border;
    }
    &::-webkit-scrollbar-thumb {
      width: 4.5px;
      border: 3px solid $bg_border;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }


#program {
  @extend .flex_column;

  height: 100%;
  padding-top: 36px;
  position: relative;
  background: white;

  .program__tab {
    flex: 0 0 auto;

    ::v-deep .v-tabs-bar {
      height: 56px !important;
    }

    ::v-deep .v-slide-group__content {
      background-color: white !important;
      
      .tab {
        border: 1px solid $blue;
        border-radius: 10px 10px 0 0;
        border-bottom: 0;
        margin-right: 3px;
        font-size: 16px;
        font-weight: 500 !important;
        padding: 0 30px;
        background: white;

        &::before {
          border-radius: 10px 10px 0 0;
        }

        &:hover,
        &.v-tab--active {
          border-color: $gray;
          color: black !important;
          background: $gray !important;
        }

        &__text {
          padding-top: 2px;
          color: black !important;
          letter-spacing: -0.2px;
          font-weight: 500 !important;
          font-family: $scd !important;
        }
      }
      .v-tabs-slider-wrapper {
        display: none !important;
      }
    }

    .light {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 12px;
      border-radius: 50%;
      position: relative;
      bottom: -2px;
      background-color: #2F983E;

      &.stopped {
        background: $norm_yellow !important;
      }
      &.power_off {
        background: #d5d7da !important;
      }
    }
  }

  .program__content {
    width: 100%;
    flex: 1 1 auto;

    ::v-deep .v-window__container {
      height: 100%;
    }
    .v-window-item {
      @extend .flex_row; 

      width: 100%;
      height: 100%;
      overflow-y: hidden;
    }
    &_left {
      @include scroll;
      
      width: 35%;
      height: 100%;
      overflow-y: scroll;
      background: $gray;

      .content_inner {
        height: 100%;
        overflow-y: scroll;
      }
      .dir_list {
        
        .item {
          height: 64px;
          padding: 5px 54px 0;
          font-family: $scd !important;
          font-size: 17px;
          transition: all 0.4s ease;
          background: $gray;
          
          &.dir_item--active {
            background-color: $white_gray !important;
          }
          &::before {
            display: none;
          }
          &:hover {
            background: $white_gray;
          }
        }
      }
    }
    &_right {
      @extend .flex_column;

      width: 65%;
      height: 100%;
      padding: 12px 0 12px 24px!important;
      overflow: hidden;
      position: relative;
      background: $white_gray;

      .program__content_actions {
        @extend .flex_row;

        justify-content: flex-end;
        flex: 0 0 auto;
        width: 100%;
        padding: 0 16px 12px;

        .save_file {
          letter-spacing: -0.25px;
          font-family: $scd !important;
          background-color: #DCDCDC !important;
        }
      }
      .content {
        @include scroll;

        flex: 1 1 auto;
        width: 100%;
        overflow-y: scroll;
        font-size: 15px;
        font-family: $scd !important;

        pre {
          font-weight: 300 !important;
        }
      }
    }
  }
}

// mac 

.mac {

  .program__content {

    &_right {

      .program__content_actions {

        .save_file {
          padding-top: 2px !important;
        }
      }
    }
  }
}

</style>