<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.43 15.4"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M7.71,9.89,12.78,15A1.59,1.59,0,0,0,15,15a1.56,1.56,0,0,0,0-2.19L9.91,7.7,15,2.63A1.56,1.56,0,0,0,15,.44a1.59,1.59,0,0,0-2.2,0L7.71,5.51,2.65.44a1.59,1.59,0,0,0-2.2,0,1.56,1.56,0,0,0,0,2.19L5.52,7.7.45,12.77A1.56,1.56,0,0,0,.45,15a1.59,1.59,0,0,0,2.2,0Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'deleteDefault',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#999;}
</style>