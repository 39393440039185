<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.3 17.16"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M18.13 6.69a.56.56.0 0 0 0-.8L15.85 3.6a.58.58.0 0 0-.81.0l-1 1 3.1 3.1 1-1zM13.2 5.43l3.1 3.1L9.67 15.15 6.58 12.06 13.2 5.43zM5.79 12.88a1.47 1.47.0 0 0-.06.14L4.62 16.35a.59.59.0 0 0 0 .23.57.57.0 0 0 .57.58.59.59.0 0 0 .23-.05L8.76 16a.2.2.0 0 0 .08.0l-3-3.06zm-.21-1.44L7.3 9.72H1.72v-8h8V7.3l1.72-1.72V1.14A1.15 1.15.0 0 0 10.29.0H1.14A1.14 1.14.0 0 0 0 1.14v9.15a1.15 1.15.0 0 0 1.14 1.15z"/></g></g></svg>
</template>

<script>
export default {
  name: "memoWrite",
}
</script>

<style scoped lang="scss">
  .cls-1{
    fill-rule:evenodd;
    opacity:0.6;
  }
</style>