<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.2 22.93"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M4.3 9.32h8.6V6.45a4.3 4.3.0 0 0-8.6.0V9.32zm4.3 4.3a1.79 1.79.0 0 0-.72 3.43v1.22A.73.73.0 0 0 8.6 19a.71.71.0 0 0 .71-.72V17.05a1.79 1.79.0 0 0-.71-3.43zm6.45-4.3h0a2.15 2.15.0 0 1 2.15 2.15v9.31a2.16 2.16.0 0 1-2.15 2.15H2.15A2.16 2.16.0 0 1 0 20.78V11.47A2.15 2.15.0 0 1 2.15 9.32V6.45a6.45 6.45.0 0 1 12.9.0z"/></g></g></svg>
</template>

<script>
export default {
  name: 'loginPassword',
}
</script>

<style lang="scss" scoped>
  .cls-1 {
    fill:#0346da;
    fill-rule:evenodd;
  }
</style>