<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.34 12.89"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M7.25 12.59.46 6.36A1.16 1.16.0 0 1 0 5.44a1.14 1.14.0 0 1 .34-.81L1.48 3.49a1.16 1.16.0 0 1 1.58-.05L8 7.87 15.17.41A1.16 1.16.0 0 1 16.86.33L18 1.48a1.15 1.15.0 0 1 .33.81 1.13 1.13.0 0 1-.32.8L8.85 12.55A1.16 1.16.0 0 1 7.25 12.59z"/></g></g></svg>
</template>

<script>
export default {
  name: 'loginCheck',
  data() {
    return {
      width: 24,
      height: 24,
    };
  }
}
</script>

<style scoped lang="scss">
  .cls-1{
    fill:#fff;
    fill-rule:evenodd;
  }
</style>