<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.09 21.64"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M0 1.12A1.15 1.15.0 0 1 1.18.0 1.14 1.14.0 0 1 2.35 1.12V19.4H23.91a1.12 1.12.0 1 1 0 2.24H1.18A1.15 1.15.0 0 1 0 20.52V1.12zM22.74 17.91a.77.77.0 0 0 .78-.75V.75a.66.66.0 0 0-.14-.42A.83.83.0 0 0 22.74.0a.75.75.0 0 0-.55.22L11 10.88 9.18 9.17a.82.82.0 0 0-1.11.0L4.15 12.9a.72.72.0 0 0-.23.53v3.73a.77.77.0 0 0 .79.75z"/></g></g></svg>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'productStatus',
  data() {
    return {
      width: 24,
      height: 24,
    }
  }
});
</script>

<style scoped>
  .cls-1{
    fill:#839cc5;
  }
</style>