<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.08 17.08"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M0 0V17.08H17.08V0zM16.22 16.22H.85V4.37H16.22z"/><path class="cls-2" d="M4.18 7.43h1.85v1.85H4.18z"/><path class="cls-2" d="M7.61 7.43h1.85v1.85H7.61z"/><path class="cls-2" d="M11.04 7.43h1.85v1.85H11.04z"/><path class="cls-2" d="M4.18 10.97h1.85v1.85H4.18z"/><path class="cls-2" d="M7.61 10.97h1.85v1.85H7.61z"/><path class="cls-2" d="M11.04 10.97h1.85v1.85H11.04z"/></g></g></svg>
</template>

<script>
export default {
  name: 'pickerCalendar',
}
</script>

<style lang="scss" scoped>
  .cls-1,.cls-2{fill:#231815;}
  .cls-1{fill-rule:evenodd;}
</style>