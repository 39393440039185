import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

import yhsProfile from '@/assets/img/vicon/yhs_profile.vue';
import changeMenu from '@/assets/img/vicon/change_menu.vue';
import productStatus from '@/assets/img/vicon/product_status.vue';
import productManage from '@/assets/img/vicon/product_manage.vue';
import productCalendar from '@/assets/img/vicon/product_calendar.vue';
import optimalProcess from '@/assets/img/vicon/optimal_process.vue';
import operationData from '@/assets/img/vicon/operation_data.vue';
import corporation from '@/assets/img/vicon/corporation.vue';
import loginCheck from '@/assets/img/vicon/login_check.vue';
import loginId from '@/assets/img/vicon/login_id.vue';
import loginPassword from '@/assets/img/vicon/login_password.vue';
import arrowDown from '@/assets/img/vicon/arrow_down.vue';
import memoWrite from '@/assets/img/vicon/memo_write.vue';
import alertTri from '@/assets/img/vicon/alert_tri.vue';
import machineInfoManage from '@/assets/img/vicon/machine_info_manage.vue';
import processRecordCheck from '@/assets/img/vicon/process_record_check.vue';
import staffOnly from '@/assets/img/vicon/staff_only.vue';
import pickerCalendar from '@/assets/img/vicon/picker_calendar.vue';
import monitorAdd from '@/assets/img/vicon/monitor_add.vue';
import monitorRemove from '@/assets/img/vicon/monitor_remove.vue';
import removeMachine from '@/assets/img/vicon/remove_machine.vue';
import addMachine from '@/assets/img/vicon/add_machine.vue';
import modify from '@/assets/img/vicon/modify.vue';
import deleteDefault from '@/assets/img/vicon/delete.vue';
import miniClose from '@/assets/img/vicon/mini_close.vue';
import miniRefresh from '@/assets/img/vicon/mini_refresh.vue';
import logout from '@/assets/img/vicon/logout.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      yhsProfile: { component: yhsProfile, },
      changeMenu: { component: changeMenu, },
      productStatus: { component: productStatus, },
      productManage: { component: productManage, },
      productCalendar: { component: productCalendar, },
      optimalProcess: { component: optimalProcess, },
      operationData: { component: operationData, },
      corporation: { component: corporation, },
      loginCheck: { component: loginCheck, },
      loginId: { component: loginId, },
      loginPassword: { component: loginPassword, },
      arrowDown: { component: arrowDown, },
      memoWrite: { component: memoWrite },
      alertTri: { component: alertTri },
      staffOnly: { component: staffOnly },
      machineInfoManage: { component: machineInfoManage },
      processRecordCheck: { component: processRecordCheck },
      pickerCalendar: { component: pickerCalendar },
      monitorAdd: { component: monitorAdd },
      monitorRemove: { component: monitorRemove },
      removeMachine: { component: removeMachine },
      addMachine: { component: addMachine },
      modify: { component: modify },
      deleteDefault: { component: deleteDefault },
      miniClose: { component: miniClose },
      miniRefresh: { component: miniRefresh },
      logout: { component: logout },
    }
  }
});
